import React from 'react'

const WhiteArrow = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.156 42.1295L15.8861 36.0734L27.3424 25.9543H0V17.0619H27.3424L15.8861 6.94281L21.156 0.886719L42.3884 21.3548V21.6614L21.156 42.1295Z"
        fill="white"
      />
    </svg>
  )
}

export default WhiteArrow
