import React from 'react'
import SubServiceBase from '../components/services/sub-service'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image, Link, RichText } from '@superrb/gatsby-addons/types'

export interface Item {
  title: string
  text: RichText
  link: Link
  link_label: string
  category: string
  icon: Image
}

export const SubService = ({
  slice,
}: SliceComponentProps<any, any> & { title: string; items: Item[] }) => {
  const data = {
    title: slice.primary.title,
    items: slice.items,
  }

  return <SubServiceBase data={data} />
}

export const query = graphql`
  fragment ContentPageDataBodySubService on PrismicContentPageDataBodySubService {
    primary {
      title
    }
    items {
      title
      text {
        ...RichText
      }
      link {
        ...Link
      }
      link_label
      category
      icon {
        ...Image
      }
    }
  }
`
