import { Image } from '@superrb/gatsby-addons/components'
import React from 'react'
import { A11y, Pagination } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'

const ImageSlider = ({
  images,
  background,
  paginationSide,
  paginationTheme,
}) => {
  if (!images) {
    return
  }

  if (images.length > 5) {
    images = images.slice(0, 5)
  }

  return (
    <section
      className={`image__slider ${background ? background : ''} ${
        paginationSide ? paginationSide : ''
      }`}
    >
      <div className="image__slider-container container--flex">
        <Swiper
          modules={[Pagination, A11y]}
          pagination={{ clickable: true, el: '.image__slider-pagination' }}
          slidesPerView={1}
          speed={1000}
        >
          {images.map((image, index) => {
            return (
              <SwiperSlide key={index} className={`image__slider-item`}>
                <Image
                  image={image?.image}
                  className="image__slider-item-image objFit"
                  alt={image?.image?.alt}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div
          className={`image__slider-pagination ${
            paginationTheme ? paginationTheme : ''
          }`}
        ></div>
      </div>
    </section>
  )
}

export default ImageSlider
