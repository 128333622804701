import { Map } from '@superrb/gatsby-addons/components'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { RichText } from './rich-text'
import React, { useEffect, useState } from 'react'
import Office from '../types/office'
import { linkResolver } from '../utils/linkResolver'
import RectangleButton from './buttons/rectangle-button'
import MapPin from './icons/map-pin.svg'
import mapOptions from './utils/map-options'

const GlobalMap = ({ data }) => {
  const staticData = useStaticQuery(graphql`
    query OfficesMapQuery {
      offices: allPrismicOffice(sort: { data: { sort_order: ASC } }) {
        nodes {
          ...Office
        }
      }
    }
  `)

  const officesData = useMergePrismicPreviewData(staticData)
  const [splitText, setSplitText] = useState([])

  useEffect(() => {
    setSplitText(data?.title.split(' '))
  }, [data?.title])

  /**
   * @type {Office[]} offices
   */
  const offices =
    officesData?.offices?.nodes || officesData?.allPrismicOffice?.nodes

  if (!offices) {
    return null
  }

  return (
    <section className="global-map">
      <div className="global-map__container container container--flex">
        <div className="global-map__column global-map__column--map">
          <Map
            className="global-map__map"
            center={{
              lat: offices[0]?.data?.office_latitude,
              lng: offices[0]?.data?.office_longitude,
            }}
            useClustering={true}
            initialZoom={1}
            markers={offices.map(
              (
                { data: { office_title, office_latitude, office_longitude } },
                index,
              ) => {
                return {
                  label: office_title,
                  center: {
                    lat: office_latitude,
                    lng: office_longitude,
                  },
                }
              },
            )}
            markerIcon={
              <MapPin
                style={{
                  position: 'absolute',
                  bottom: '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              />
            }
            options={mapOptions}
          />
        </div>

        <div className="global-map__column global-map__column--content">
          <h2 className="global-map__title">
            {splitText.map((word, index) => (
              <span key={index}>
                {word}
                {index !== splitText.length - 1 && ' '}
              </span>
            ))}
          </h2>

          <div className="global-map__content">
            {data?.text && <RichText field={data?.text?.richText} />}

            {data?.button_text && data?.button_link && (
              <RectangleButton
                text={data?.button_text}
                styles={['transparent-white-faded', 'small']}
                link={linkResolver(data?.button_link)}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default GlobalMap
