import { Link } from 'gatsby'
import React from 'react'

const SquareButton = ({ text, link, theme }) => {
  return link ? (
    <Link to={link} className={`button button__square ${theme ? theme : ''}`}>
      <span className="button__square-inner">
        <span className="screenreader-text">{text}</span>
      </span>
    </Link>
  ) : (
    <button className={`button button__square ${theme ? theme : ''}`}>
      <span className="button__square-inner">
        <span className="screenreader-text">{text}</span>
      </span>
    </button>
  )
}

export default SquareButton
