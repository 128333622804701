import React from 'react'
import TestimonialsBase from '../components/testimonials'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import Testimonial from '../types/testimonial'

export const Testimonials = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    testimonials_title: slice.primary.title,
    testimonials_promo_icons: [
      slice.primary.promo_icon_1,
      slice.primary.promo_icon_2,
    ],
    testimonials_graphic: slice.primary.graphic,
    testimonials: slice.items.map(
      ({ testimonial }: { testimonial: { document: Testimonial } }) =>
        testimonial.document,
    ),
  }

  return <TestimonialsBase data={data} />
}

export const query = graphql`
  fragment ContentPageDataBodyTestimonials on PrismicContentPageDataBodyTestimonials {
    primary {
      title
      promo_icon_1 {
        document {
          ... on PrismicPromoIcon {
            ...PromoIcon
          }
        }
      }
      promo_icon_2 {
        document {
          ... on PrismicPromoIcon {
            ...PromoIcon
          }
        }
      }
      graphic {
        document {
          ... on PrismicPromoIcon {
            ...PromoIcon
          }
        }
      }
    }
    items {
      testimonial {
        document {
          ... on PrismicTestimonial {
            ...Testimonial
          }
        }
      }
    }
  }
`
