import React from 'react'
import ResourceDetail from '../../types/pages/resource-detail'

const Tag = ({ label }) => (
  <button
    className="tag"
    onClick={() => {
      if (window.location.pathname.includes('/blog')) {
        window.location.href = `/blog?tags[]=${label}`
      }
      if (window.location.pathname.includes('/resource')) {
        window.location.href = `/resource?tags[]=${label}`
      }
      if (window.location.pathname.includes('/events')) {
        window.location.href = `/event?tags[]=${label}`
      }
    }}
  >
    {label}
  </button>
)

/**
 *
 * @param {ResourceDetail} page
 * @returns
 */
const ResourceDetailHeader = ({ page }) => {
  return (
    <section className="blog-detail-header">
      <ul className="blog-detail-header__tags">
        {page.tags.map((tag, index) => (
          <Tag label={tag} key={index} />
        ))}
      </ul>
      <ul className="blog-detail-header__meta">
        {page?.data?.author && (
          <li className="blog-detail-header__meta-item">
            <span className="blog-detail-header__meta-item-title">
              Written by
            </span>
            <span className="blog-detail-header__meta-item-value">
              {page?.data?.author}
            </span>
          </li>
        )}
        {page?.data?.reading_time && (
          <li className="blog-detail-header__meta-item">
            <span className="blog-detail-header__meta-item-title">
              Average read time
            </span>
            <span className="blog-detail-header__meta-item-value">
              {page?.data?.reading_time} minutes
            </span>
          </li>
        )}
      </ul>
    </section>
  )
}

export default ResourceDetailHeader
