import React, { useContext } from 'react'
import { NewsletterContext } from '../context/newsletter-context-provider'
import AnimatedTitle from './animated-title'
import RectangleButton from './buttons/rectangle-button'
import ButtonArrow from './icons/button-arrow'

const BlogNewsletter = () => {
  const { openNewsletterPopup } = useContext(NewsletterContext)

  return (
    <div className="blog__articles-newsletter">
      <div className="blog__articles-newsletter__inner">
        <ButtonArrow className="blog__articles-newsletter-arrow" />
        <AnimatedTitle
          level={4}
          className="blog__articles-newsletter-title"
          text={'Get your latest news and articles straight to your inbox!'}
        />
        <RectangleButton
          styles={['transparent-white-faded', 'small']}
          text="Subscribe"
          onClick={openNewsletterPopup}
        />
      </div>
    </div>
  )
}

export default BlogNewsletter
