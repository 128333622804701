import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { Link } from 'gatsby'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import React from 'react'
import { linkResolver } from '../../utils/linkResolver'
import SquareButton from '../buttons/square-button'
import { ImageLayout } from '@superrb/gatsby-addons/types'

const BlogResource = ({ resource, showNewsletter, index }) => {
  const isMobile = useIsMobile()

  const colors = {
    dark_blue: 'white',
    pastel_pink: 'navy',
    off_white: 'navy',
    hot_pink: 'navy',
    purple: 'white',
    navy: 'white',
    white: 'navy',
  }

  return (
    <article
      className={`article ${resource?.data?.featured ? ' featured' : ''} ${
        showNewsletter == true && index == 0 ? 'padding-bottom' : ''
      }`}
    >
      <Link to={linkResolver(resource)}>
        <div className="article__inner">
          {resource?.data?.featured_image && (
            <Image
              image={resource?.data?.featured_image}
              className={`article__image ${
                resource?.data?.featured ? ' featured' : ''
              }`}
              alt={resource?.data?.featured_image?.alt}
              layout={ImageLayout.cover}
            />
          )}
          {resource?.data?.description_homepage?.richText?.length > 0 &&
            !isMobile && (
              <div
                className={`article__description ${
                  resource?.data?.featured ? ' featured' : ''
                } ${resource?.data?.background_color
                  .replace(' ', '_')
                  .toLowerCase()}`}
              >
                <RichText
                  field={resource?.data?.description_homepage?.richText}
                />
              </div>
            )}
          <div
            className={`article__details ${
              resource?.data?.featured ? ' featured' : ''
            } ${resource?.data?.background_color
              ?.replace(' ', '_')
              .toLowerCase()}`}
          >
            <div className="article-top">
              <div className="article__services">
                {resource?.data?.date && <span>{resource?.data?.date}</span>}
              </div>
              {resource?.data?.page_title && (
                <h6>{resource.data.page_title}</h6>
              )}
            </div>
            <div className="article__footer">
              <div className="article__author">
                {resource?.data?.author && (
                  <address rel="author">
                    <p className="article__author-title">Author</p>
                    <p className="article__author-author">
                      {resource?.data?.author}
                    </p>
                  </address>
                )}
              </div>
              <SquareButton
                link={linkResolver(resource)}
                text={'Read More'}
                theme={
                  colors[
                    resource?.data?.background_color
                      ?.replace(' ', '_')
                      .toLowerCase()
                  ]
                }
              />
            </div>
          </div>
        </div>
      </Link>
    </article>
  )
}

export default BlogResource
