import React from 'react'

const VideoClose = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30ZM23.5292 30.5001L14.964 22.057L21.0361 15.9592L29.6582 24.4584L38.2885 15.9511L44.3607 22.0489L35.7873 30.5001L44.3605 38.9512L38.2884 45.049L29.6582 36.5418L21.0363 45.0409L14.9642 38.9431L23.5292 30.5001Z"
      fill="url(#paint0_linear_1630_343)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1630_343"
        x1="60"
        y1="13"
        x2="1.10586e-07"
        y2="46"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7633F4" />
        <stop offset="1" stopColor="#FDBEDD" />
      </linearGradient>
    </defs>
  </svg>
)

export default VideoClose
