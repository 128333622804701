import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import React, { useEffect, useState } from 'react'
import BlackArrowTitle from './black-arrow-title'
import ClientLogo from './client-logo'

const ClientLogos = ({ data }) => {
  const logos = data.client_logos
  // const [logos, setLogos] = useState(null)
  const { isInViewport, setRef } = useIsInViewport(false)

  // useEffect(() => {
  //   if (data.client_logos.length <= 8) {
  //     setLogos([
  //       ...data.client_logos,
  //       ...data.client_logos,
  //       ...data.client_logos,
  //       ...data.client_logos,
  //       ...data.client_logos,
  //       ...data.client_logos,
  //       ...data.client_logos,
  //     ])
  //   } else {
  //     setLogos([...data.client_logos, ...data.client_logos])
  //   }
  // }, [data])

  return (
    <section className="client-logos" ref={setRef}>
      {data?.title && <BlackArrowTitle level={2} text={data?.title} />}
      <div className="client-logos__inner">
        <div
          className={`client-logos__logos ${
            isInViewport ? 'client-logos__logos--animated' : ''
          }`}
        >
          {logos &&
            logos.map((logo, index) => (
              <ClientLogo
                logo={logo}
                key={`1.${index}`}
                ariaHidden={index > data.client_logos.length}
              />
            ))}
        </div>
        <div
          className={`client-logos__logos ${
            isInViewport ? 'client-logos__logos--animated' : ''
          }`}
        >
          {logos &&
            logos.map((logo, index) => (
              <ClientLogo
                logo={logo}
                key={`2.${index}`}
                ariaHidden={index > data.client_logos.length}
              />
            ))}
        </div>
      </div>
    </section>
  )
}

export default ClientLogos
