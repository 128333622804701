import { graphql } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import { LandingPage as LandingPageType } from '../types/pages/landing-page'
import IframeResizer from 'react-iframe-resizer-super'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Seo } from '@superrb/gatsby-addons/components'
import { Layout } from '../components/layout'

const LandingPage = ({ pageContext }) => {
  const { setHeaderVariant } = useContext(HeaderVariantContext)
  setHeaderVariant('dark')

  useEffect(() => {
    if (pageContext) {
      const page = pageContext
      window.dataLayer = window.dataLayer || []

      switch (page.type) {
        case 'blog_detail':
          window.dataLayer.push({
            event: 'view_blog_post',
            article_name: page.data.page_title,
            article_author: page.data.author,
            article_publish_date: page.publication_date,
            article_tag: page.tags.join(','),
          })
          break

        case 'resource':
          window.dataLayer.push({
            event: 'view_resource',
            resource_name: page.data.page_title,
            resource_category: page.tags.join(','),
          })
          break
      }
    }
  }, [])

  /** @type {LandingPageType} page */
  const page = pageContext
  if (!page) {
    return null
  }

  if (!page.data.embed_url) {
    return null
  }

  console.log('Height: ', page)

  const Content = () => (
    <>
      <Seo data={page.data} />

      <div
        className={`landing-page ${
          page.data?.hide_layout ? 'landing-page--full-width' : ''
        }`}
      >
        <div className="landing-page__container container">
          {page.data?.embed_url && (
            <>
              {page.data?.embed_height ? (
                <iframe title={page?.data?.title} src={page.data.embed_url} style={{
                  height: `${page?.data?.embed_height}px`,
                  overflowY: 'auto',
                  width: '100%'
                }} />
              ) : (
                <IframeResizer src={page.data.embed_url} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  )

  if (page.data.hide_layout) {
    return <Content />
  }

  return (
    <Layout>
      <Content />
    </Layout>
  )
}

export const query = graphql`
  query LandingPageQuery($id: String) {
    page: prismicLandingPage(id: { eq: $id }) {
      ...LandingPage
    }
  }
`

export default withPrismicPreview(LandingPage)
