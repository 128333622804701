import { Seo } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { graphql } from 'gatsby'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import Awards from '../components/awards'
import Content from '../components/blog-detail/content'
import Cta from '../components/careers/cta'
import ClientLogos from '../components/client-logos'
import ContactForm from '../components/contact-form'
import Hero from '../components/hero/hero'
import PromoBlocks from '../components/promo-blocks'
import TickerTape from '../components/ticker-tape'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import Akero from '../types/pages/akero'
import StatisticsSlider from './../components/statistics-slider'
import Video from './../components/video'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'

const AkeroPage = ({ data }) => {
  const { setHeaderVariant } = useContext(HeaderVariantContext)
  setHeaderVariant('light')

  const isMobile = useIsMobile()

  /** @type {Akero} page */
  const page = data.page
  if (!page) {
    return null
  }

  const {
    data: { page_title },
  } = page

  const hero = {
    hero_title: page.data.hero_title,
    hero_subtitle: page.data.hero_subtitle,
    hero_button_text: page.data.hero_button_text,
  }

  const tickerTape = page.data.ticker_tape

  const video = {
    video: page.data.video_url,
    background: page.data.video_poster_image,
  }

  const cta = {
    cta_title: page.data.cta_title,
    cta_description: page.data.cta_description,
    cta_link_text: page.data.cta_link_text,
    cta_link: page.data.cta_link,
  }

  const showForm = page.data.show_contact_form || false

  const whoWeWorkWith = {
    title: page.data.who_we_work_with_title,
    client_logos: page.data.client_logos.map(
      item => item?.client_logo?.document,
    ),
  }

  const stats = page.data.stats

  const awards = {
    title: page.data.awards_title,
    awards: page.data.awards,
  }

  const promoBlock = {
    promo_block_promo_icons: [
      page.data.promo_blocks[0].promo_icon_1,
      page.data.promo_blocks[0].promo_icon_2,
      page.data.promo_blocks[0].promo_icon_3,
    ],
    image_1: page.data.promo_blocks[0].promo_image_1,
    image_2: page.data.promo_blocks[0].promo_image_2,
    service_title_1: page.data.promo_blocks[0].promo_title_1,
    service_title_2: page.data.promo_blocks[0].promo_title_2,
    service_description_1: page.data.promo_blocks[0].promo_description_1,
    service_description_2: page.data.promo_blocks[0].promo_description_2,
    service_button_text_1: page.data.promo_blocks[0].promo_button_text_1,
    service_button_text_2: page.data.promo_blocks[0].promo_button_text_2,
    service_button_link_1: page.data.promo_blocks[0].promo_button_link_1,
    service_button_link_2: page.data.promo_blocks[0].promo_button_link_2,
  }

  const promoBlockTwo = {
    promo_block_promo_icons: [
      page.data.promo_blocks2[0].promo_icon_1,
      page.data.promo_blocks2[0].promo_icon_2,
      page.data.promo_blocks2[0].promo_icon_3,
    ],
    image_1: page.data.promo_blocks2[0].promo_image_1,
    image_2: page.data.promo_blocks2[0].promo_image_2,
    service_title_1: page.data.promo_blocks2[0].promo_title_1,
    service_title_2: page.data.promo_blocks2[0].promo_title_2,
    service_description_1: page.data.promo_blocks2[0].promo_description_1,
    service_description_2: page.data.promo_blocks2[0].promo_description_2,
    service_button_text_1: page.data.promo_blocks2[0].promo_button_text_1,
    service_button_text_2: page.data.promo_blocks2[0].promo_button_text_2,
    service_button_link_1: page.data.promo_blocks2[0].promo_button_link_1,
    service_button_link_2: page.data.promo_blocks2[0].promo_button_link_2,
  }

  return (
    <Layout>
      <Seo data={page.data} />
      {hero && (
        <Hero
          data={{
            title: hero.hero_title,
            subtitle: hero.hero_subtitle,
            buttonLabel: hero.hero_button_text,
          }}
          backgroundStyle="purple"
          colors={['#FF80B1', '#FF80B1']}
        />
      )}
      {tickerTape && <TickerTape text={tickerTape} background="black" />}

      {whoWeWorkWith && <ClientLogos data={whoWeWorkWith} />}

      {video && <Video url={video.video} backgroundImage={video.background} />}

      {showForm && <ContactForm />}

      {stats && <StatisticsSlider data={stats} />}

      {promoBlock && <PromoBlocks data={promoBlock} />}

      {page.data.raw_html && !isMobile && <Content html={page.data.raw_html} />}

      {promoBlockTwo && (
        <PromoBlocks
          data={promoBlockTwo}
          reversedLayout={true}
          classes="second"
        />
      )}

      {awards && <Awards data={awards} />}

      {cta && <Cta data={cta} theme="light" />}
    </Layout>
  )
}

export const query = graphql`
  query AkeroQuery {
    page: prismicAkero {
      ...Akero
    }
  }
`

export default withPrismicPreview(AkeroPage)
