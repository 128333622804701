import React from 'react'
import VideoBase from '../components/video'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'

export const Video = ({ slice }: SliceComponentProps<any, any>) => {
  return (
    <VideoBase
      url={slice.primary.url.url}
      backgroundImage={slice.primary.image}
    />
  )
}

export const query = graphql`
  fragment ContentPageDataBodyVideo on PrismicContentPageDataBodyVideo {
    primary {
      url {
        ...Link
      }
      image {
        ...Image
      }
    }
  }
`
