import React, { useEffect, useState } from 'react'
import TickerTape from '../ticker-tape'

const Instagram = ({ tickerTape }) => {
  const [posts, setPosts] = useState(null)
  useEffect(() => {
    const FEED_ID = 'netnatives'
    const FEED_LIMIT = '20'
    const FEED_URL =
      'https://www.juicer.io/api/feeds/' + FEED_ID + '?page=1&per=' + FEED_LIMIT

    fetch(FEED_URL)
      .then(results => results.json())
      .then(data => {
        setPosts(data.posts.items)
      })
  }, [])

  return (
    <section className="instagram">
      <TickerTape text={tickerTape} background="pale-pink" />
      <div className="instagram__container container container--flex">
        {posts &&
          posts.map((post, index) => {
            if (post.image) {
              return (
                <div
                  className="instagram__item objFit"
                  key={index}
                  aria-hidden={index > 4 ? true : false}
                >
                  <a
                    href={post.full_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="objFit"
                  >
                    <picture>
                      <img
                        src={post.image}
                        alt={post.title}
                        className="objFit"
                      />
                    </picture>
                  </a>
                </div>
              )
            }
          })}
      </div>
    </section>
  )
}

export default Instagram
