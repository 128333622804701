import { Image } from '@superrb/gatsby-addons/components'
import { ImageLayout } from '@superrb/gatsby-addons/types'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import React from 'react'
import { linkResolver } from '../utils/linkResolver'
import AnimatedTitle from './animated-title'
import BlackArrowTitle from './black-arrow-title'
import RectangleButton from './buttons/rectangle-button'
import PromoSlider from './homepage/promo-block/promo-slider'
import IconAkero from './icons/icon-akero'
import PromoIcon from './promo-icon'

const PromoBlocks = ({
  data,
  reversedLayout,
  classes,
  title,
  animatedTitleSize,
  noPromoIcons,
}) => {
  const isMobile = useIsMobile()
  let promoDataBlocks

  if (!data) {
    return
  }

  if (isMobile) {
    promoDataBlocks = data?.promo_block_promo_icons?.slice(0, 2)
  } else {
    promoDataBlocks = data?.promo_block_promo_icons
  }

  console.log(data.promo_block_akero_logo)

  return (
    <section
      className={`promo-blocks${
        reversedLayout ? ' promo-blocks--reversed' : ''
      } ${classes ? classes : ''} ${!promoDataBlocks ? 'no-promo-blocks' : ''}`}
    >
      {data?.service_heading && (
        <BlackArrowTitle level={2} text={data?.service_heading} />
      )}

      {title && <BlackArrowTitle level={2} text={title} />}

      <div className="promo-blocks__inner">
        {promoDataBlocks && noPromoIcons && (
          <div className="promo-blocks__column promo-blocks__column--promo-icons">
            <div className="promo-blocks__promo-icons-container">
              {promoDataBlocks.map((icon, index) => (
                <PromoIcon data={icon?.document?.data} key={index} />
              ))}
            </div>
          </div>
        )}

        <div className="promo-blocks__column promo-blocks__column--blocks">
          {data?.promo_block_bullet_points && (
            <div className="promo-blocks__bullet-points">
              <RichText field={data?.promo_block_bullet_points?.richText} />
            </div>
          )}

          {data?.image_1 && (
            <Image
              image={data?.image_1}
              className="promo-blocks__image promo-blocks__image--one"
              layout={ImageLayout.cover}
            />
          )}

          {data?.promo_block_akero_text && (
            <div className="promo-blocks__akero">
              <div className="promo-blocks__akero-content">
                <div className="promo-blocks__akero-logo">
                  {data?.promo_block_akero_logo?.gatsbyImageData ? (
                    <>
                      <Image image={data?.promo_block_akero_logo} />
                      <h2 className="screenreader-text">
                        {data?.promo_block_akero_title}
                      </h2>
                    </>
                  ) : (
                    <AnimatedTitle
                      text={data?.promo_block_akero_title}
                      level={2}
                      className="promo-blocks__akero-title"
                    />
                  )}
                </div>
                <RichText field={data?.promo_block_akero_text?.richText} />

                {data?.promo_block_akero_button_url && (
                  <RectangleButton
                    text={data?.promo_block_akero_button_label}
                    styles={['transparent-white-faded', 'small']}
                    link={linkResolver(data?.promo_block_akero_button_url)}
                  />
                )}
              </div>
            </div>
          )}

          {data?.service_title_1 && (
            <div
              className={`promo-blocks__text promo-blocks__text--one ${
                data?.service_background_color_1
                  ? `promo-blocks__text--${data?.service_background_color_1}`
                  : ''
              }`}
            >
              <div className="promo-blocks__text-inner">
                <AnimatedTitle
                  text={data?.service_title_1}
                  level={animatedTitleSize ? animatedTitleSize : 3}
                  className="promo-blocks__text__title"
                />

                {data?.service_description_1 && (
                  <div className="promo-blocks__text__description">
                    <RichText field={data?.service_description_1?.richText} />
                  </div>
                )}

                {data?.service_button_text_1 &&
                  data?.service_button_link_1?.url && (
                    <RectangleButton
                      text={data?.service_button_text_1}
                      styles={['transparent-navy-faded', 'small']}
                      link={data?.service_button_link_1?.url}
                    />
                  )}
              </div>
            </div>
          )}

          {data?.promo_block_slider && (
            <div className="promo-blocks__slider">
              <PromoSlider
                data={data?.promo_block_slider}
                logo={data?.promo_block_slider_logo}
                title={data?.promo_block_slider_title}
              />
            </div>
          )}

          {data?.service_title_2 && (
            <div
              className={`promo-blocks__text promo-blocks__text--two ${
                data?.service_background_color_2
                  ? `promo-blocks__text--${data?.service_background_color_2}`
                  : ''
              }`}
            >
              <div className="promo-blocks__text-inner">
                <AnimatedTitle
                  text={data?.service_title_2}
                  level={animatedTitleSize ? animatedTitleSize : 3}
                  className="promo-blocks__text__title"
                />

                {data?.service_description_2 && (
                  <div className="promo-blocks__text__description">
                    <RichText field={data?.service_description_2?.richText} />
                  </div>
                )}

                {data?.service_button_text_2 &&
                  data?.service_button_link_2?.url && (
                    <RectangleButton
                      text={data?.service_button_text_2}
                      styles={['transparent-white-faded', 'small']}
                      link={data?.service_button_link_2?.url}
                    />
                  )}
              </div>
            </div>
          )}

          {data?.image_square?.image_square_image && (
            <div className="promo-blocks__image-square">
              <Image
                image={data?.image_square?.image_square_image}
                className="promo-blocks__image-square-image"
              />

              <div className="promo-blocks__image-square-text">
                {data?.image_square?.image_square_title && (
                  <AnimatedTitle
                    text={data?.image_square?.image_square_title}
                    level={2}
                    className="promo-blocks__image-square-title"
                  />
                )}

                {data?.image_square?.image_square_text && (
                  <RichText
                    field={data?.image_square?.image_square_text?.richText}
                  />
                )}

                <RectangleButton
                  text="Find out more"
                  styles={['transparent-white-faded', 'small']}
                  link={linkResolver(
                    data?.image_square?.image_square_button_url,
                  )}
                />
              </div>
            </div>
          )}

          {data?.image_2 && (
            <Image
              image={data?.image_2}
              className="promo-blocks__image promo-blocks__image--two"
              layout={ImageLayout.cover}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default PromoBlocks
