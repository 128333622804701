import { useAsync } from '@superrb/gatsby-addons/hooks'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { NewsletterContext } from '../context/newsletter-context-provider'
import Close from './icons/close.svg'
import IframeResizer from 'react-iframe-resizer-super'

const Newsletter = () => {
  const form = useRef(null)
  const { newsletterPopupOpen, closeNewsletterPopup } =
    useContext(NewsletterContext)

  return (
    <>
      <aside
        className="newsletter"
        aria-hidden={!newsletterPopupOpen}
        onClick={closeNewsletterPopup}
      >
        <div className="newsletter__wrapper" onClick={e => e.stopPropagation()}>
          <button className="newsletter__close" onClick={closeNewsletterPopup}>
            <span className="screenreader-text">Close form</span>
            <Close />
          </button>

          <div id="form-sign-up-to-the-newsletter-container">
            <h2 className="newsletter__title">Stay in the loop</h2>

            <IframeResizer
              src={
                'https://dt6xp7y75wdq4.cloudfront.net/2022/09/nn-forms/newsletter/index.html'
              }
            />
          </div>
        </div>
      </aside>
    </>
  )
}

export default Newsletter
