import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import React from 'react'
import Content from '../components/blog-detail/content'
import ClientLogos from '../components/client-logos'
import ContactForm from '../components/contact-form'
import Animation from '../components/hero/animation'
import PromoBlocks from '../components/promo-blocks'
import StatisticsSlider from '../components/statistics-slider'
import Testimonials from '../components/testimonials'
import TickerTape from '../components/ticker-tape'
import TwoImageBlock from '../components/two-image-block'
import colors from '../components/utils/colors'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const PostContent = ({ page }) => {
  const isMobile = useIsMobile(true)
  const showForm = page.data.show_contact_form || false

  return (
    <div className="post-content">
      {page.data?.client_logos.length > 1 && (
        <ClientLogos
          data={{
            title: page.data.who_we_work_with_title,
            client_logos: page.data.client_logos.map(
              item => item?.client_logo?.document,
            ),
          }}
        />
      )}

      <Content
        intro={page.data.intro}
        content={page.data.content}
        center={page.data.center_content}
        video={page.data.video}
        videoThumbnail={page.data.video_thumbnail}
      />

      {page.data?.testimonials?.filter(item => item.document !== null).length >=
        1 &&
        page.data.testimonials_title && (
          <Testimonials
            data={{
              testimonials_title: page.data.testimonials_title,
              testimonials: page.data?.testimonials?.map(
                item => item?.testimonial?.document,
              ),
              testimonials_promo_icons: [
                page.data?.testimonials_promo_icon_1,
                page.data?.testimonials_promo_icon_2,
              ],
            }}
          />
        )}

      {page.data?.promo_block_image && page.data?.promo_block_title && (
        <PromoBlocks
          data={{
            image_1: page.data?.promo_block_image,
            service_title_1: page.data?.promo_block_title,
            service_description_1: page.data?.promo_block_text,
          }}
          classes="promo-blocks--blog-detail"
        />
      )}

      {page.data?.ticker_tape && (
        <TickerTape text={page.data?.ticker_tape} background="white" />
      )}

      {page.data?.statistics?.length > 1 && (
        <StatisticsSlider data={page.data?.statistics} />
      )}

      {page.data?.additional_content_content && (
        <Content
          intro={page.data.additional_content_intro}
          content={page.data.additional_content_content}
          video={page.data.additional_content_video}
          videoThumbnail={page.data.additional_content_video_thumbnail}
          reverse={true}
        />
      )}

      {page.data?.images.slice(0, 2).length === 2 && (
        <TwoImageBlock
          images={page.data?.images.slice(0, 2).map(item => item.media)}
        />
      )}

      {!isMobile && page.data?.images.slice(2, 3).length === 1 && (
        <TwoImageBlock
          images={page.data?.images.slice(2, 3).map(item => item.media)}
          animation={
            <Animation
              backgroundStyle={'pink'}
              colors={[colors.purple, colors.navy]}
              key={0}
            />
          }
          reverse={true}
        />
      )}

      {page.data?.raw_html?.text && <Content html={page.data.raw_html} />}
      {page.data?.iframe_url && (
        <Content iframe={{ url: page.data.iframe_url }} />
      )}

      {showForm && <ContactForm title={'Get in touch'} />}
    </div>
  )
}

export default withPrismicPreview(PostContent)
