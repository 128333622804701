import { Link } from 'gatsby'
import React from 'react'
import ButtonArrow from '../icons/button-arrow'
import PinkButtonArrow from '../icons/pink-button-arrow'

type Style =
  | 'navy'
  | 'black'
  | 'pink'
  | 'transparent-white'
  | 'transparent-navy'
  | 'transparent-black'
  | 'transparent-white-faded'
  | 'transparent-black-faded'
  | 'rainbow'
type ArrowStyle = 'pink' | 'white' | 'none'

interface Props {
  text: string
  link: string
  style: Style
  arrowStyle: ArrowStyle
  type: 'button' | 'submit' | 'reset'
  styles: Style[]
  className: string
  onClick: () => void
}

const RectangleButton = ({
  text,
  link,
  style,
  arrowStyle,
  type = 'button',
  styles = [],
  className = '',
  onClick = () => {},
}: Props) => {
  if (style && styles.length === 0) {
    styles = [style]
  }

  const inner = (
    <>
      <div className="button__bg"></div>
      <span className="button__rectangle-inner">
        {arrowStyle !== 'none' ? (
          <>{arrowStyle === 'pink' ? <PinkButtonArrow /> : <ButtonArrow />}</>
        ) : (
          <></>
        )}
        <span className="button__text">{text}</span>
      </span>
    </>
  )

  if (link) {
    return (
      <Link
        to={link}
        className={`button button__rectangle ${styles
          .map(style => `button__rectangle--${style}`)
          .join(' ')} ${className}`}
      >
        {inner}
      </Link>
    )
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={`button button__rectangle ${styles
        .map(style => `button__rectangle--${style} ${styles}`)
        .join(' ')} ${className}`}
    >
      {inner}
    </button>
  )
}

export default RectangleButton
