import { Image } from '@superrb/gatsby-addons/components'
import { useImageSources } from '@superrb/gatsby-addons/hooks'
import { Link } from 'gatsby'
import React from 'react'

const PromoIcon = ({ data }) => {
  let icon

  if (data?.promo_icon?.document?.data) {
    icon = data?.promo_icon?.document?.data
  } else {
    icon = data
  }

  if (!icon) {
    return null
  }

  const { src: backgroundSrc } = useImageSources(icon.background_image)
  const { src: foregroundSrc } = useImageSources(icon.image)

  let linkContent = (
    <div
      className={`promo-icon ${backgroundSrc ? 'background-image' : ''} ${
        icon.background_color
          ? icon.background_color.replace(' ', '_').toLowerCase()
          : ''
      } text--${
        icon.text_color
          ? icon.text_color.replace(' ', '_').toLowerCase()
          : 'white'
      }`}
    >
      {foregroundSrc && !backgroundSrc && (
        <Image
          image={icon.image}
          className="promo-icon__image"
          alt={icon.image.alt}
        />
      )}
      {icon.title && !backgroundSrc && (
        <h5 className="promo-icon__title">{icon.title}</h5>
      )}
      {backgroundSrc && (
        <Image
          image={icon.background_image}
          alt={icon.background_image.alt}
          className="promo-icon__background"
          style={{ position: 'absolute' }}
        />
      )}
    </div>
  )

  return (
    <>
      {icon.link?.url ? (
        <Link to={icon.link.url} className="promo-icon__link">
          {linkContent}
        </Link>
      ) : (
        <>{linkContent}</>
      )}
    </>
  )
}

export default PromoIcon
