import React from 'react'
import BlogNewsletter from '../blog-newsletter'
import BlogArticle from './blog-article'

const BlogLatestArticles = ({ articles = [], showNewsletter }) => {
  return (
    <div className="blog__articles blog__article-row our-work__articles">
      {articles &&
        articles?.map((article, index) => {
          return (
            <BlogArticle
              key={`article--${index}`}
              article={article}
              showNewsletter={showNewsletter}
              index={index}
            />
          )
        })}
      {showNewsletter && articles.length > 2 && <BlogNewsletter />}
    </div>
  )
}

export default BlogLatestArticles
