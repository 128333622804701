import React from 'react'
import CtaBase from '../components/careers/cta'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'

export const Cta = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    cta_title: slice.primary.title,
    cta_description: slice.primary.description,
    cta_link: slice.primary.link,
    cta_link_text: slice.primary.link_text,
  }

  return <CtaBase data={data} theme={slice.primary.theme} />
}

export const query = graphql`
  fragment ContentPageDataBodyCta on PrismicContentPageDataBodyCta {
    primary {
      title
      description {
        ...RichText
      }
      link {
        ...Link
      }
      link_text
      theme
    }
  }
`
