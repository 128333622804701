import React from 'react'
import FeaturedWorkBase from '../components/featured-work'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image, RichText } from '@superrb/gatsby-addons/types'

export const FeaturedWork = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    featured_work_title: slice.primary.title,
    featured_work_promo_icons: [
      slice.primary.promo_icon_1,
      slice.primary.promo_icon_2,
    ],
    featured_work: slice.items.map(
      ({
        title,
        text,
        image,
      }: {
        title: string
        text: RichText
        image: Image
      }) => ({
        slide_title: title,
        slide_text: text,
        slide_image: image,
      }),
    ),
  }

  return <FeaturedWorkBase data={data} showLogos={slice.primary.show_logos} />
}

export const query = graphql`
  fragment ContentPageDataBodyFeaturedWork on PrismicContentPageDataBodyFeaturedWork {
    primary {
      title
      promo_icon_1 {
        document {
          ... on PrismicPromoIcon {
            ...PromoIcon
          }
        }
      }
      promo_icon_2 {
        document {
          ... on PrismicPromoIcon {
            ...PromoIcon
          }
        }
      }
      show_logos
    }
    items {
      title
      text {
        ...RichText
      }
      image {
        ...Image
      }
    }
  }
`
