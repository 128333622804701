import { Seo } from '@superrb/gatsby-addons/components'
import { graphql } from 'gatsby'
import moment from 'moment'
import React, { useContext, useLayoutEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import LatestArticles from '../components/blogs-resources-events/latest-articles'
import LatestEvents from '../components/blogs-resources-events/latest-events'
import LatestResources from '../components/blogs-resources-events/latest-resources'
import Hero from '../components/hero/hero'
import TickerTape from '../components/ticker-tape'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import OurWork from '../types/pages/our-work'
import BlackArrowTitle from './../components/black-arrow-title'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import BlogLatestArticles from '../components/blog/blog-latest-article'
import BlogLatestEvents from '../components/event/blog-latest-event'
import BlogLatestResources from '../components/resource/blog-latest-resource'
import { Layout } from '../components/layout'

const BlogsResourcesEvents = ({ data }) => {
  const { setHeaderVariant } = useContext(HeaderVariantContext)
  setHeaderVariant('light')

  /** @type {OurWork} page */
  const page = data.page

  const {
    data: { page_title },
  } = page

  const hero = {
    hero_title: page.data.hero_title,
    hero_subtitle: page.data.hero_subtitle,
    hero_button_text: page.data.hero_button_text,
  }

  const tickerTape = page.data.ticker_tape
  const latestArticles = data.articles
  const featuredArticle = data.featuredArticle
  const latestResources = data.resources
  const featuredResource = data.featuredResource
  const featuredEvent = data.featuredEvent
  const latestEvents = data.events

  const [articles, setArticles] = useState([])
  const [resources, setResources] = useState([])
  const [events, setEvents] = useState([])

  useLayoutEffect(() => {
    setArticles([
      ...featuredArticle.nodes,
      ...latestArticles.nodes.slice(
        0,
        featuredArticle.nodes.length == 0 ? 3 : 2,
      ),
    ])
    setResources([
      ...featuredResource.nodes,
      ...latestResources.nodes.slice(
        0,
        featuredResource.nodes.length == 0 ? 3 : 2,
      ),
    ])
    setEvents([
      ...featuredEvent.nodes
        .filter(item => moment(item.data?.filter_date).isAfter(moment()))
        .slice(0, 1),
      ...latestEvents.nodes
        .filter(item => moment(item?.data?.filter_date).isAfter(moment()))
        .slice(0, featuredEvent.nodes.length == 0 ? 3 : 2),
    ])
  }, [
    featuredArticle,
    featuredEvent.nodes,
    featuredResource.nodes,
    latestArticles,
    latestEvents.nodes,
    latestResources.nodes,
  ])

  if (!page) {
    return null
  }

  return (
    <Layout>
      <Seo data={page.data} />
      {hero && (
        <Hero
          data={{
            title: hero.hero_title,
            subtitle: hero.hero_subtitle,
            buttonLabel: hero.hero_button_text,
          }}
          backgroundStyle="blue"
          colors={['#7633F4', '#0F1449']}
        />
      )}
      {tickerTape && <TickerTape text={tickerTape} background="black" />}

      {page?.data?.articles_title && (
        <BlackArrowTitle
          text={page?.data?.articles_title}
          level={2}
          className="articles-title"
        />
      )}

      {articles && (
        <LatestArticles
          articles={articles}
          linkTitle={page?.data?.articles_link_title}
          showTitle={false}
        />
      )}

      {page?.data?.resources_title && (
        <BlackArrowTitle
          text={page?.data?.resources_title}
          level={2}
          backgroundColor="purple"
          className="must-read-resources"
        />
      )}

      {resources && (
        <LatestResources
          resources={resources}
          linkTitle={page?.data?.resources_link_title}
          reverse={false}
          showTitle={false}
        />
      )}

      {page?.data?.events_title && (
        <BlackArrowTitle
          text={page?.data?.events_title}
          level={2}
          className="events-title"
          alignment="left"
        />
      )}

      {events && (
        <LatestEvents
          events={events}
          linkTitle={page?.data?.events_link_title}
          showTitle={false}
        />
      )}
    </Layout>
  )
}

export const query = graphql`query BlogResourceEventsQuery {
  page: prismicBlogsResourcesEvents {
    ...BlogsResourcesEvents
  }
  featuredArticle: allPrismicBlogDetail(
    sort: {data: {date: DESC}}
    limit: 1
    filter: {data: {featured: {eq: true}}}
  ) {
    nodes {
      ...BlogPost
    }
  }
  articles: allPrismicBlogDetail(
    sort: {data: {date: DESC}}
    limit: 3
    filter: {data: {featured: {eq: false}}}
  ) {
    nodes {
      ...BlogPost
    }
  }
  featuredResource: allPrismicResource(
    sort: {data: {date: DESC}}
    limit: 1
    filter: {data: {featured: {eq: true}}}
  ) {
    nodes {
      ...ResourcePost
    }
  }
  resources: allPrismicResource(
    sort: {data: {date: DESC}}
    limit: 3
    filter: {data: {featured: {eq: false}}}
  ) {
    nodes {
      ...ResourcePost
    }
  }
  featuredEvent: allPrismicEvent(
    sort: {data: {date: DESC}}
    limit: 5
    filter: {data: {featured: {eq: true}}}
  ) {
    nodes {
      ...EventPost
    }
  }
  events: allPrismicEvent(
    sort: {data: {date: DESC}}
    limit: 10
    filter: {data: {featured: {eq: false}}}
  ) {
    nodes {
      ...EventPost
    }
  }
}`

export default withPrismicPreview(BlogsResourcesEvents)
