import { Seo } from '@superrb/gatsby-addons/components'
import { graphql } from 'gatsby'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import LatestEvents from '../components/blogs-resources-events/latest-events'
import EventDetailHeader from '../components/event-detail/header'
import Hero from '../components/hero/hero'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import EventDetail from '../types/pages/event-detail'
import PostContent from './post-content'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'

const EventPost = ({ data }) => {
  const { setHeaderVariant } = useContext(HeaderVariantContext)
  setHeaderVariant('light')

  const [latestArticles, setLatestArticles] = useState([])
  useEffect(() => {
    if (data?.latest?.nodes.length > 0) {
      const featured =
        data?.featured?.nodes
          .filter(item => moment(item?.data?.filter_date).isAfter(moment()))
          .slice(0, 1) || null
      const latest = data?.latest?.nodes
        .filter(item => moment(item?.data?.filter_date).isAfter(moment()))
        .filter(article => article !== featured)
        .slice(0, 3 - featured.length)
        .map(article => {
          article.data.featured = false
          return article
        })

      if (featured.length === 0) {
        const featured = data?.featured?.nodes
          .filter(item => moment(item?.data?.filter_date).isAfter(moment()))
          .slice(0, 1)
        const latest = data?.latest?.nodes
          .filter(item => moment(item?.data?.filter_date).isAfter(moment()))
          .filter(article => article !== featured)
          .slice(0, 3 - featured.length)
          .map((article, index) => {
            article.data.featured = false
            if (index == 2) {
              article.data.featured = true
            }
            return article
          })
      }

      setLatestArticles([...featured, ...latest])
    }
  }, [data, setLatestArticles])

  /** @type {EventDetail} page */
  const page = data?.page || data?.prismicEvent
  if (!page) {
    return null
  }

  const hideDate = page.data.hide_date

  return (
    <Layout>
      <Seo data={page.data} />
      <Hero
        data={{
          title: page.data.page_title,
          subtitle: page.data.date,
        }}
        backgroundImage={page.data.image}
        hideDate={hideDate}
        className="hero--detail"
      >
        <EventDetailHeader page={page} hideDate={hideDate} />
      </Hero>

      <PostContent page={page} />

      {latestArticles?.length > 0 && (
        <LatestEvents events={latestArticles} linkTitle={'See all events'} />
      )}
    </Layout>
  )
}

export const query = graphql`query EventDetailQuery($uid: String!) {
  page: prismicEvent(uid: {eq: $uid}) {
    ...EventDetail
  }
  featured: allPrismicEvent(
    filter: {uid: {ne: $uid}, data: {featured: {eq: true}}}
    sort: {first_publication_date: DESC}
    limit: 3
  ) {
    nodes {
      ...EventPost
    }
  }
  latest: allPrismicEvent(
    filter: {uid: {ne: $uid}}
    sort: {first_publication_date: DESC}
    limit: 10
  ) {
    nodes {
      ...EventPost
    }
  }
}`

export default withPrismicPreview(EventPost)
