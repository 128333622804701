import { SliceComponentProps, SliceComponentType } from '@prismicio/react'
import { TickerTape } from './ticker-tape'
import { FunctionComponent } from 'react'
import { FeaturedWork } from './featured-work'
import { ClientLogos } from './client-logos'
import { Testimonials } from './testimonials'
import { TeamMembers } from './team-members'
import { Cta } from './cta'
import { BenefitsSlider } from './benefits-slider'
import { Awards } from './awards'
import { Map } from './map'
import { ImageSlider } from './image-slider'
import { EmploymentBenefits } from './employment-benefits'
import { Video } from './video'
import { PromoBlock } from './promo-block'
import { ContactForm } from './contact-form'
import { Values } from './values'
import { Vacancies } from './vacancies'
import { LatestNews } from './latest-news'
import { Instagram } from './instagram'
import { Services } from './services'
import { SubService } from './sub-service'

export const components: Record<string, SliceComponentType<any, any>> = {
  ticker_tape: TickerTape as FunctionComponent<SliceComponentProps<any, any>>,
  featured_work: FeaturedWork as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  client_logos: ClientLogos as FunctionComponent<SliceComponentProps<any, any>>,
  testimonials: Testimonials as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  team_members: TeamMembers as FunctionComponent<SliceComponentProps<any, any>>,
  cta: Cta as FunctionComponent<SliceComponentProps<any, any>>,
  benefits_slider: BenefitsSlider as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  awards: Awards as FunctionComponent<SliceComponentProps<any, any>>,
  map: Map as FunctionComponent<SliceComponentProps<any, any>>,
  image_slider: ImageSlider as FunctionComponent<SliceComponentProps<any, any>>,
  employment_benefits: EmploymentBenefits as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  video: Video as FunctionComponent<SliceComponentProps<any, any>>,
  promo_block: PromoBlock as FunctionComponent<SliceComponentProps<any, any>>,
  contact_form: ContactForm as FunctionComponent<SliceComponentProps<any, any>>,
  values: Values as FunctionComponent<SliceComponentProps<any, any>>,
  vacancies: Vacancies as FunctionComponent<SliceComponentProps<any, any>>,
  latest_news: LatestNews as FunctionComponent<SliceComponentProps<any, any>>,
  instagram: Instagram as FunctionComponent<SliceComponentProps<any, any>>,
  services: Services as FunctionComponent<SliceComponentProps<any, any>>,
  'sub-service': SubService as FunctionComponent<SliceComponentProps<any, any>>,
}
