import { RichText } from 'ProjectRoot/src/components/rich-text'
import React from 'react'
import { A11y, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { linkResolver } from '../../../utils/linkResolver'
import AnimatedTitle from '../../animated-title'
import RectangleButton from '../../buttons/rectangle-button'
import { Image } from '@superrb/gatsby-addons/components'

const PromoSlider = ({ data, title, logo }) => {
  return (
    <div className="promo-blocks__slider-slider">
      <div className="promo-blocks__slider-image">
        {logo?.gatsbyImageData ? (
          <>
            <Image image={logo} />
            <h2 className="screenreader-text">{title}</h2>
          </>
        ) : (
          <AnimatedTitle
            text={title}
            level={2}
            className="promo-blocks__slider-slide-title"
          />
        )}
      </div>
      <Swiper
        modules={[Pagination, A11y]}
        pagination={{ clickable: true, el: '.promo-blocks__slider-pagination' }}
        slidesPerView={1}
        speed={1000}
        simulateTouch={false}
        allowTouchMove={false}
      >
        {data?.map((slide, index) => {
          return (
            <SwiperSlide
              key={`promo--${index}`}
              className="promo-blocks__slider-slide objFit"
            >
              <RichText field={slide.promo_slide_text.richText} />
              <RectangleButton
                link={linkResolver(slide.promo_slide_link)}
                style="transparent-white-faded"
                text={slide.promo_slide_button_text}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className="promo-blocks__slider-pagination"></div>
    </div>
  )
}

export default PromoSlider
