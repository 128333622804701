import React from 'react'
import { A11y, Pagination } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import Testimonial from '../types/testimonial'
import AnimatedTitle from './animated-title'
import Animation from './hero/animation'
import TestimonialQuote from './icons/testimonial-quote'
import PromoIcon from './promo-icon'
import colors from './utils/colors'

const Testimonials = ({ data, backgroundColor }) => {
  return (
    <section className="testimonials" style={{ backgroundColor }}>
      <div className="testimonials__container container container--flex">
        <div className="testimonials__column testimonials__column--graphic">
          {data?.testimonials_graphic ? (
            <PromoIcon data={data?.testimonials_graphic?.document?.data} />
          ) : (
            <Animation
              backgroundColor={colors.navy}
              colors={[colors.hotPink, colors.purple]}
            />
          )}
        </div>

        <div className="testimonials__column testimonials__column--slider">
          {data?.testimonials_title && (
            <AnimatedTitle
              text={data?.testimonials_title}
              level={2}
              className="testimonials__title"
            />
          )}
          <div className="testimonials__slider">
            <Swiper
              modules={[Pagination, A11y]}
              pagination={{
                clickable: true,
                el: '.testimonials__slider-pagination',
              }}
              slidesPerView={1}
              speed={1000}
            >
              {data?.testimonials &&
                data?.testimonials.map((testimonial, index) => {
                  /** @type {Testimonial} testimonial */
                  return (
                    <SwiperSlide
                      key={index}
                      className="testimonials__slider-slide"
                    >
                      <div className="testimonials__slider-slide__inner">
                        <TestimonialQuote className="testimonials__slider-slide-quotemark" />

                        {testimonial?.data?.quote && (
                          <h5 className="testimonials__slider-slide-title">
                            {testimonial?.data?.quote?.text}
                          </h5>
                        )}

                        {testimonial?.data?.quote_author && (
                          <h5 className="testimonials__slider-slide-author">
                            {testimonial?.data?.quote_author}
                          </h5>
                        )}

                        {testimonial?.data?.quote_job_role && (
                          <div className="testimonials__slider-slide-job">
                            {testimonial?.data?.quote_job_role}
                          </div>
                        )}
                      </div>
                    </SwiperSlide>
                  )
                })}
            </Swiper>
            {data.testimonials.length > 1 && (
              <div className="testimonials__slider-pagination light"></div>
            )}
          </div>
        </div>

        {data?.testimonials_promo_icons && (
          <div className="testimonials__column testimonials__column--promo-icons">
            <div className="testimonials__promo-icons">
              {data?.testimonials_promo_icons.map((icon, index) => (
                <PromoIcon data={icon?.document?.data} key={index} />
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Testimonials
