import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { Link } from 'gatsby'
import { RichText } from '../components/rich-text'
import React, { useContext, useEffect, useState } from 'react'
import SquareButton from '../components/buttons/square-button'
import { linkResolver } from '../utils/linkResolver'
import { FilterContext } from '../context/filter-context-provider'
import { ImageLayout } from '@superrb/gatsby-addons/types'

const Article = ({ article }) => {
  const isMobile = useIsMobile()
  const [tags, setTags] = useState([])

  useEffect(() => {
    if (article.tags.length >= 3) {
      setTags(article.tags.slice(0, 3))
    } else {
      setTags(article.tags)
    }
  }, [article])

  const colors = {
    dark_blue: 'white',
    pastel_pink: '',
    off_white: '',
    hot_pink: '',
    purple: 'white',
    navy: 'white',
  }

  const { selectedTags = [], addTag, removeTag } = useContext(FilterContext)

  return (
    <article
      className={`article ${article?.data?.featured ? ' featured' : ''}`}
    >
      <Link to={linkResolver(article)}>
        <div className="article__inner">
          {article?.data?.work_featured_image && (
            <Image
              image={article?.data?.work_featured_image}
              className={`article__image ${
                article?.data?.featured ? ' featured' : ''
              }`}
              alt={article?.data?.work_featured_image?.alt}
              layout={ImageLayout.cover}
            />
          )}
          {article?.data?.work_description_homepage && !isMobile && (
            <div
              className={`article__description ${
                article?.data?.featured ? ' featured' : ''
              } ${article?.data?.background_color
                .replace(' ', '_')
                .toLowerCase()}`}
            >
              <RichText
                field={article?.data?.work_description_homepage.richText}
              />
            </div>
          )}
          <div
            className={`article__details ${article?.data?.background_color
              .replace(' ', '_')
              .toLowerCase()}`}
          >
            <div className="article__services">
              {article?.data?.services && (
                <span>{article?.data?.services}</span>
              )}
            </div>
            {article?.data?.title && <h6>{article.data.title}</h6>}
            <div className="article__footer">
              <div className="article__tags">
                {tags &&
                  tags.map((tag, index) => {
                    const selected = selectedTags.includes(tag)
                    const handleClick = async (event, tag) => {
                      const fn = selected ? removeTag : addTag
                      await fn(tag)
                    }
                    return (
                      <span
                        className="article__tags-item"
                        key={index}
                        onClick={event => handleClick(event, tag)}
                      >
                        {tag}
                      </span>
                    )
                  })}
              </div>
              <SquareButton
                link={linkResolver(article)}
                text={'Read More'}
                theme={
                  colors[
                    article?.data?.background_color
                      .replace(' ', '_')
                      .toLowerCase()
                  ]
                }
              />
            </div>
          </div>
        </div>
      </Link>
    </article>
  )
}

export default Article
