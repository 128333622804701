import { RichText } from 'ProjectRoot/src/components/rich-text'
import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

const Offices = ({ offices }) => {
  return (
    <Accordion className="offices accordion" allowZeroExpanded={true}>
      <h5 className="offices__title">Our offices</h5>

      {offices.map((office, index) => (
        <AccordionItem
          className="accordion__item office"
          key={index}
          uuid={`accordion-item-${index}`}
        >
          <AccordionItemHeading className="accordion__heading">
            <AccordionItemButton className="accordion__button">
              {office.offices.document?.data?.office_title && (
                <>
                  <h6 className="accordion__heading-title">
                    {office.offices.document?.data?.office_title}
                  </h6>

                  <span className="accordion__heading-icon"></span>
                </>
              )}
            </AccordionItemButton>
          </AccordionItemHeading>

          <AccordionItemPanel className="accordion__panel">
            <div className="accordion__panel-inner">
              {office.offices.document?.data?.office_address && (
                <div className="office__address">
                  <RichText
                    field={office.offices.document?.data?.office_address.richText}
                  />
                </div>
              )}

              {office.offices.document?.data?.office_phone_number && (
                <a
                  href={`tel:${office.offices.document?.data?.office_phone_number}`}
                  className="office__telephone"
                >
                  {office.offices.document?.data?.office_phone_number}
                </a>
              )}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default Offices
