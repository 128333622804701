import { Seo } from '@superrb/gatsby-addons/components'
import { graphql } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import LatestArticles from '../components/blogs-resources-events/latest-articles'
import Hero from '../components/hero/hero'
import ResourceDetailHeader from '../components/resource-detail/header'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import BlogDetail from '../types/pages/blog-detail'
import PostContent from './post-content'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'

const BlogPost = ({ data }) => {
  const { setHeaderVariant } = useContext(HeaderVariantContext)
  setHeaderVariant('light')

  const [latestArticles, setLatestArticles] = useState([])
  useEffect(() => {
    if (data?.latest?.nodes.length > 0) {
      const featured = data?.featured?.nodes.slice(0, 1) || null
      const latest = data?.latest?.nodes
        .filter(article => article !== featured)
        .slice(0, 2)
        .map(article => {
          article.data.featured = false
          return article
        })

      setLatestArticles([...featured, ...latest])
    }
  }, [data, setLatestArticles])

  useEffect(() => {
    if (data?.page) {
      const page = data?.page
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'view_blog_post',
        article_name: page.data.page_title,
        article_author: page.data.author,
        article_publish_date: page.publication_date,
        article_tag: page.tags.join(','),
      })
    }
  }, [])

  /** @type {BlogDetail} page */
  let page = data?.page || data?.prismicBlogDetail
  if (!page) {
    return null
  }

  return (
    <Layout>
      <Seo data={page.data} />
      <Hero
        data={{
          title: page.data.page_title,
          subtitle: page.data?.date,
        }}
        backgroundImage={page.data.image}
        className="hero--detail"
      >
        <ResourceDetailHeader page={page} />
      </Hero>

      <PostContent page={page} />

      {latestArticles?.length > 0 && (
        <LatestArticles articles={latestArticles} linkTitle={'See all Posts'} />
      )}
    </Layout>
  )
}

export const query = graphql`query BlogDetailQuery($uid: String!) {
  page: prismicBlogDetail(uid: {eq: $uid}) {
    ...BlogDetail
  }
  featured: allPrismicBlogDetail(
    filter: {uid: {ne: $uid}, data: {featured: {eq: true}}}
    sort: {first_publication_date: DESC}
    limit: 1
  ) {
    nodes {
      ...BlogPost
    }
  }
  latest: allPrismicBlogDetail(
    filter: {uid: {ne: $uid}}
    sort: {first_publication_date: DESC}
    limit: 3
  ) {
    nodes {
      ...BlogPost
    }
  }
}`

export default withPrismicPreview(BlogPost)
