import React from 'react'
import RectangleButton from '../buttons/rectangle-button'
import IconAkero from '../icons/icon-akero'

const Login = () => {
  return (
    <div className="login">
      <div className="login__text">
        Connect to <IconAkero />
      </div>

      <RectangleButton
        text="Login"
        link="https://admin.akerolabs.com/login"
        styles={['navy', 'small']}
      />
    </div>
  )
}

export default Login
