import { Image } from '@superrb/gatsby-addons/components'
import { Image as ImageType } from '@superrb/gatsby-addons/types'
import React, { ReactElement } from 'react'

/**
 * @param {ImageType[]} images
 */
const TwoImageBlock = ({ images = [], reverse = false, animation }) => (
  <section
    className={`two-image-block ${reverse ? 'two-image-block--reverse' : ''} ${
      animation ? 'two-image-block--with-animation' : ''
    }`}
  >
    {images.map((image, index) => (
      <Image
        image={image}
        key={index}
        className="two-image-block__image"
        alt={image.alt}
      />
    ))}

    {animation && <div className="two-image-block__animation">{animation}</div>}
  </section>
)

export default TwoImageBlock
