import { RichText } from '../components/rich-text'
import React from 'react'
import AwardArrow from './icons/award-arrow'

const Awards = ({ data }) => {
  return (
    <section className="awards">
      <div className="awards__inner">
        {data.title && <h2 className="awards__title">{data.title}</h2>}
        <div className="awards__container container container--flex">
          {data.awards &&
            data.awards.map((award, index) => (
              <div key={index} className="awards__single">
                {award?.award?.document?.data?.award_title && (
                  <h5 className="awards__single-title">
                    <AwardArrow className="awards__single-title-arrow" />
                    {award?.award?.document?.data?.award_title}
                  </h5>
                )}
                {award?.award?.document?.data?.award_description && (
                  <div className="awards__single-desc">
                    <RichText
                      field={
                        award?.award?.document?.data?.award_description.richText
                      }
                    />
                  </div>
                )}
                {award?.award?.document?.data?.award_year && (
                  <h6 className="awards__single-year">
                    {award?.award?.document?.data?.award_year}
                  </h6>
                )}
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Awards
