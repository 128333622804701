import React from 'react'
import VacanciesBase from '../components/careers/vacancies'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Link } from '@superrb/gatsby-addons/types'

export const Vacancies = ({ slice }: SliceComponentProps<any, any>) => {
    const data = {
        title: slice.primary.vacancies_title,
        vacancies: slice.items.map(
            (item: {
                vacancy_title: string
                vacancy_location: string
                vacancy_link: Link
            }) => ({
                vacancy_title: item.vacancy_title,
                vacancy_location: item.vacancy_location,
                vacancy_link: item.vacancy_link
            }),
        ),
    }

    return <VacanciesBase data={data} />
}

export const query = graphql`
  fragment ContentPageDataBodyVacancies on PrismicContentPageDataBodyVacancies {
    primary {
      vacancies_title
    }
    items {
      vacancy_title
      vacancy_location
      vacancy_link {
        ...Link
      }
    }
  }
`
