import { Seo } from '@superrb/gatsby-addons/components'
import { graphql } from 'gatsby'
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Article from '../components/article'
import Hero from '../components/hero/hero'
import TickerTape from '../components/ticker-tape'
import WorkFilters from '../components/work-filters'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import chunkArray from '../helpers/chunk-array'
import useArticleFilter from '../hooks/use-article-filter'
import OurWork from '../types/pages/our-work'
import BlackArrowTitle from './../components/black-arrow-title'
import Video from './../components/video'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'

const OurWorkPage = ({ data }) => {
  const [availableTags, setAvailableTags] = useState([])
  const [splitArticles, setSplitArticles] = useState(
    chunkArray(data.articles.nodes, 3),
  )

  useEffect(() => {
    const uniqueTags = Array.from(
      new Set(data.tags.nodes.flatMap(tag => tag.tags)),
    )
    // uniqueTags.unshift('ALL')
    setAvailableTags(uniqueTags)
  }, [data.tags, setAvailableTags])

  const filteredArticles = useArticleFilter(data.articles.nodes, availableTags)

  useLayoutEffect(() => {
    setSplitArticles(chunkArray(filteredArticles, 3))
  }, [filteredArticles])

  const { setHeaderVariant } = useContext(HeaderVariantContext)
  setHeaderVariant('light')

  /** @type {OurWork} page */
  const page = data.page
  if (!page) {
    return null
  }

  const {
    data: { page_title },
  } = page

  const hero = {
    hero_title: page.data.hero_title,
    hero_subtitle: page.data.hero_subtitle,
    hero_button_text: page.data.hero_button_text,
  }

  const tickerTape = page.data.ticker_tape

  const video = {
    video: page.data.video_url,
    background: page.data.video_poster_image,
  }

  return (
    <Layout>
      <Seo data={page.data} />
      {hero && (
        <Hero
          data={{
            title: hero.hero_title,
            subtitle: hero.hero_subtitle,
            buttonLabel: hero.hero_button_text,
          }}
          backgroundStyle="purple"
          colors={['#FF80B1', '#FF80B1']}
        />
      )}
      {tickerTape && <TickerTape text={tickerTape} background="black" />}

      {video && <Video url={video.video} backgroundImage={video.background} />}

      <BlackArrowTitle text={'Expertise'} level={2} />

      <WorkFilters />

      {splitArticles.map((list, listIndex) => (
        <div className={`our-work__articles`} key={listIndex}>
          {list?.map((article, index) => (
            <Article key={`article--${index}`} article={article} />
          ))}
        </div>
      ))}
    </Layout>
  )
}

export const query = graphql`query OurWorkQuery {
  page: prismicOurWo {
    ...OurWork
  }
  tags: allPrismicWork {
    nodes {
      tags
    }
  }
  articles: allPrismicWork(sort: {first_publication_date: DESC}) {
    nodes {
      ...Work
    }
  }
}`

export default withPrismicPreview(OurWorkPage)
