import React from 'react'
import { Image } from '@superrb/gatsby-addons/components'

const HeaderFooter = ({ logos }) => {
  return (
    <div className="main-header__footer">
      {logos &&
        logos.map((logo, index) => (
          <div key={index}>
            <Image image={logo.logos} alt={logo.logos.alt} />
          </div>
        ))}
    </div>
  )
}

export default HeaderFooter
