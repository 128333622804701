import { Seo } from '@superrb/gatsby-addons/components'
import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Awards from '../components/awards'
import ImageSlider from '../components/careers/image-slider'
import ContactForm from '../components/contact-form'
import PromoBlocks from '../components/promo-blocks'
import StatisticsSlider from '../components/statistics-slider'
import Testimonials from '../components/testimonials'
import Video from '../components/video'
import Hero from '../components/hero/hero'
import LatestWork from '../components/work-detail/latest-work'
import Results from '../components/work-detail/results'
import TwoColImg from '../components/work-detail/two-col-img'
import TwoColIntro from '../components/work-detail/two-col-intro'
import WorkDetail from '../types/pages/work-detail'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Content from '../components/blog-detail/content'
import { Layout } from '../components/layout'

const WorkDetailPage = ({ data }) => {
  const latestWork = data?.latest
  const featuredWork = data?.featured

  const [work, setWork] = useState([])

  useEffect(() => {
    if (latestWork && featuredWork) {
      setWork([...featuredWork.nodes, ...latestWork.nodes])
    }
  }, [data])

  /** @type {WorkDetail} page */
  const page = data?.page || data?.prismicWork
  if (!page) {
    return null
  }

  const video = {
    url: page.data.video?.url,
    backgroundImage: page.data.video_thumbnail,
  }

  const imageSlider = page.data.images

  const promoBlockOne = {
    promo_block_promo_icons: [
      page.data.promo_blocks[0]?.promo_icon_1,
      page.data.promo_blocks[0]?.promo_icon_2,
      page.data.promo_blocks[0]?.promo_icon_3,
    ],
    image_1: page.data.promo_blocks[0]?.promo_image_1,
    image_2: page.data.promo_blocks[0]?.promo_image_2,
    service_title_1: page.data.promo_blocks[0]?.promo_title_1,
    service_title_2: page.data.promo_blocks[0]?.promo_title_2,
    service_description_1: page.data.promo_blocks[0]?.promo_description_1,
    service_description_2: page.data.promo_blocks[0]?.promo_description_2,
    service_button_text_1: page.data.promo_blocks[0]?.promo_button_text_1,
    service_button_text_2: page.data.promo_blocks[0]?.promo_button_text_2,
    service_button_link_1: page.data.promo_blocks[0]?.promo_button_link_1,
    service_button_link_2: page.data.promo_blocks[0]?.promo_button_link_2,
  }

  const promoBlockTwo = {
    promo_block_promo_icons: [
      page.data.promo_blocks_two[0]?.promo_icon_1,
      page.data.promo_blocks_two[0]?.promo_icon_2,
      page.data.promo_blocks_two[0]?.promo_icon_3,
    ],
    image_1: page.data.promo_blocks_two[0]?.promo_image_1,
    image_2: page.data.promo_blocks_two[0]?.promo_image_2,
    service_title_1: page.data.promo_blocks_two[0]?.promo_title_1,
    service_title_2: page.data.promo_blocks_two[0]?.promo_title_2,
    service_description_1: page.data.promo_blocks_two[0]?.promo_description_1,
    service_description_2: page.data.promo_blocks_two[0]?.promo_description_2,
    service_button_text_1: page.data.promo_blocks_two[0]?.promo_button_text_1,
    service_button_text_2: page.data.promo_blocks_two[0]?.promo_button_text_2,
    service_button_link_1: page.data.promo_blocks_two[0]?.promo_button_link_1,
    service_button_link_2: page.data.promo_blocks_two[0]?.promo_button_link_2,
  }

  const awards = {
    awards: page.data.awards,
    title: page.data.awards_title,
  }

  const hero = {
    backgroundImage: page.data.hero_image,
    data: {
      title: page.data.title,
      subtitle: 'Our Work',
      tags: page.tags,
      backgroundColor: page.data.background_color,
    },
  }

  const twoColInto = {
    content: page.data.intro,
    services: page.data.services,
    client: page.data.client,
  }

  const twoColImg = {
    text: page.data.additional_content_content,
    image: page.data.additional_content_image,
  }

  const results = {
    title: page.data.results_title,
    text: page.data.results_content,
  }

  const showForm = page.data.show_contact_form || false

  return (
    <Layout>
      <Seo data={page.data} />

      <Hero {...hero} />

      <div className="post-content">
        {twoColInto.content.richText.length > 0 && (
          <TwoColIntro {...twoColInto} />
        )}

        {video.url && video.backgroundImage && (
          <Video url={video.url} backgroundImage={video.backgroundImage} />
        )}

        {page.data?.testimonials?.filter(item => item.document !== null)
          .length >= 1 &&
          page.data.testimonials_title && (
            <Testimonials
              data={{
                testimonials_title: page.data.testimonials_title,
                testimonials: page.data?.testimonials?.map(
                  item => item?.testimonial?.document,
                ),
                testimonials_promo_icons: [
                  page.data?.testimonials_promo_icon_1,
                  page.data?.testimonials_promo_icon_2,
                ],
              }}
            />
          )}

        {page?.data?.statistics.length > 0 && (
          <StatisticsSlider
            data={page.data?.statistics}
            animationColor={page.data?.animation_color || 'purple'}
          />
        )}

        {twoColImg.image && twoColImg.text.richText.length > 0 && (
          <TwoColImg {...twoColImg} />
        )}

        {imageSlider && imageSlider.length > 1 && (
          <ImageSlider
            images={imageSlider}
            background={'hotPink'}
            paginationSide={'right'}
            paginationTheme={'light'}
          />
        )}

        {promoBlockOne && <PromoBlocks data={promoBlockOne} />}

        {results.text && results.title && <Results {...results} />}

        {promoBlockTwo && <PromoBlocks data={promoBlockTwo} classes="second" />}

        {awards.awards.length > 0 && awards.title && <Awards data={awards} />}

        {showForm && <ContactForm />}
      </div>

      {work && <LatestWork works={work} />}
    </Layout>
  )
}

export const query = graphql`
  query WorkDetailQuery($uid: String!) {
    page: prismicWork(uid: { eq: $uid }) {
      ...WorkDetail
    }
    featured: allPrismicWork(
      filter: { uid: { ne: $uid }, data: { featured: { eq: true } } }
      sort: { first_publication_date: DESC }
      limit: 1
    ) {
      nodes {
        ...Work
      }
    }
    latest: allPrismicWork(
      filter: { uid: { ne: $uid }, data: { featured: { eq: false } } }
      sort: { first_publication_date: DESC }
      limit: 3
    ) {
      nodes {
        ...Work
      }
    }
  }
`

export default withPrismicPreview(WorkDetailPage)
