import React from 'react'

const Logo = ({ style }) => (
  <svg
    width="155"
    height="41"
    viewBox="0 0 155 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M5.15944 39.9211H0V0.337158H7.68455L12.0202 32.2137C11.4167 25.1178 10.3744 11.7019 10.3744 5.76892V0.337158H15.5339V39.9211H8.12329L4.50038 10.2602C4.82991 16.5241 5.15944 24.009 5.15944 28.2235V39.9211Z"
      fill="currentColor"
    />
    <path
      d="M28.7753 39.9211H18.2361V0.337158H28.7753V5.99145H24.1638V17.1897H28.1162V22.1803H24.1638V34.2668H28.7753V39.9211Z"
      fill="currentColor"
    />
    <path
      d="M40.3905 5.99145V39.9211H34.4628V5.99145H30.7307V0.337158H44.1782V5.99145H40.3905Z"
      fill="currentColor"
    />
    <path
      d="M58.116 39.9211H52.9565V0.337158H60.6411L64.9786 32.2137C64.3732 25.1178 63.331 11.7019 63.331 5.76892V0.337158H68.4904V39.9211H61.0798L57.4588 10.2602C57.7865 16.5241 58.116 24.009 58.116 28.2235V39.9211Z"
      fill="currentColor"
    />
    <path
      d="M80.0831 34.8763H77.3376L77.1173 39.9211H71.134L74.483 0.337158H83.2653L86.5031 39.9211H80.3015L80.0831 34.8763ZM77.5024 29.878H79.8627L79.3684 18.5675C79.2592 16.9614 78.8741 8.14519 78.6021 3.4894C78.3262 8.20131 77.9986 17.1278 77.943 18.5675L77.5024 29.878Z"
      fill="currentColor"
    />
    <path
      d="M96.5194 5.99145V39.9211H90.5917V5.99145H86.8596V0.337158H100.307V5.99145H96.5194Z"
      fill="currentColor"
    />
    <path
      d="M108.555 0.337158H102.628V39.9211H108.555V0.337158Z"
      fill="currentColor"
    />
    <path
      d="M114.554 39.9205L110.657 0.33662H116.868L117.799 20.1286L118.458 34.0437C118.678 29.6085 118.897 23.9523 119.117 20.0725L119.995 0.334686H126.026L121.964 39.9205H114.554Z"
      fill="currentColor"
    />
    <path
      d="M138.645 39.9211H128.105V0.337158H138.645V5.99145H134.033V17.1897H137.985V22.1803H134.033V34.2668H138.645V39.9211Z"
      fill="currentColor"
    />
    <path
      d="M154.939 31.9366C154.939 37.8676 151.921 40.2574 147.913 40.2574C143.083 40.2574 140.723 37.5967 140.723 32.1649V26.946H146.651V32.5461C146.651 34.0439 147.09 34.5973 147.858 34.5973C148.846 34.5973 149.007 33.4885 149.007 31.9927V30.4949C149.007 27.7239 148.35 27.2788 145.275 22.5108C142.476 18.242 140.94 14.6389 140.94 10.7068V9.09492C140.94 3.21809 143.409 6.10352e-05 148.028 6.10352e-05C151.981 6.10352e-05 155 1.21916 155 9.09492V13.7526H149.176V8.5918C149.176 6.76315 148.958 5.65435 148.026 5.65435C147.04 5.65435 146.766 6.54062 146.766 8.5918V9.64641C146.766 12.5297 147.915 14.635 150.498 18.6832C153.297 23.1726 154.943 25.5024 154.943 29.9918L154.939 31.9366Z"
      fill="currentColor"
    />
  </svg>
)

export default Logo
