import { Seo } from '@superrb/gatsby-addons/components'
import { graphql } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ResourceDetailHeader from '../components/blog-detail/header'
import LatestResources from '../components/blogs-resources-events/latest-resources'
import Hero from '../components/hero/hero'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import ResourceDetail from '../types/pages/blog-detail'
import PostContent from './post-content'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'

const ResourcePost = ({ data }) => {
  /** @type {ResourceDetail} page */
  const page = data?.page || data?.prismicResource

  const { setHeaderVariant } = useContext(HeaderVariantContext)
  const [showButton, setShowButton] = useState(false)
  const [latestArticles, setLatestArticles] = useState([])

  setHeaderVariant('light')

  useEffect(() => {
    if (data?.latest?.nodes.length > 0) {
      const featured = data?.featured?.nodes.slice(0, 1) || null

      const latest = data?.latest?.nodes
        .filter(article => article !== featured)
        .slice(0, 2)
        .map(article => {
          article.data.featured = false
          return article
        })

      if (featured.length === 0) {
        const latest = data?.latest?.nodes
          .filter(article => article !== featured)
          .slice(0, 3)
          .map((article, index) => {
            article.data.featured = false
            if (index === 2) {
              article.data.featured = true
            }
            return article
          })
        setLatestArticles([...latest])
        return
      }

      setLatestArticles([...featured, ...latest])
    }
  }, [data, setLatestArticles])

  useEffect(() => {
    if (page?.data?.raw_html?.html && page?.data?.raw_html?.html.length > 1) {
      setShowButton(true)
    }
  }, [page])

  useEffect(() => {
    if (data?.page) {
      const page = data?.page
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'view_resource',
        resource_name: page.data.page_title,
        resource_category: page.tags.join(','),
      })
    }
  }, [])

  if (!page) {
    return null
  }

  return (
    <Layout>
      <Seo data={page.data} />
      <Hero
        data={{
          title: page.data.page_title,
          subtitle: page.data?.date,
        }}
        backgroundImage={page.data.image}
        className="hero--detail"
      >
        <ResourceDetailHeader page={page} showButton={showButton} />
      </Hero>

      <PostContent page={page} />

      {latestArticles?.length > 0 && (
        <LatestResources
          resources={latestArticles}
          linkTitle={'See all resources'}
          reverse={true}
        />
      )}
    </Layout>
  )
}

export const query = graphql`query ResourceDetailQuery($uid: String!) {
  page: prismicResource(uid: {eq: $uid}) {
    ...ResourceDetail
  }
  featured: allPrismicResource(
    filter: {uid: {ne: $uid}, data: {featured: {eq: true}}}
    sort: {first_publication_date: DESC}
    limit: 1
  ) {
    nodes {
      ...ResourcePost
    }
  }
  latest: allPrismicResource(
    filter: {uid: {ne: $uid}}
    sort: {first_publication_date: DESC}
    limit: 3
  ) {
    nodes {
      ...ResourcePost
    }
  }
}`

export default withPrismicPreview(ResourcePost)
