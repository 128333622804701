import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import Event from './event'
import PinkArrowTitle from './pink-arrow-title'
import BlackArrowTitle from './../black-arrow-title'

const LatestEvents = ({ events, linkTitle, showTitle = true }) => {
  useEffect(() => {
    if (
      events.length > 1 &&
      !events?.some(event => event?.data?.featured === true)
    ) {
      events.map((article, index) => {
        if (article.data) {
          article.data.featured = false
          if (index == 2) {
            article.data.featured = true
          }
          return article
        }
      })
    }
  }, [events])

  return (
    <div className="blog__events blog__articles our-work__articles latest-articles">
      {showTitle && (
        <div className="our-work__articles-title">
          <BlackArrowTitle
            text="More Events"
            level={2}
            alignment="left"
            backgroundColor="black"
          />
        </div>
      )}

      {events?.map((event, index) => (
        <Event key={`event--${index}`} event={event} />
      ))}
      {linkTitle && (
        <Link to="/events" className="blog__articles-link second">
          <PinkArrowTitle
            text={linkTitle.toUpperCase()}
            level={5}
            alignment="right"
            className="link"
            backgroundColor="black"
          />
        </Link>
      )}
    </div>
  )
}

export default LatestEvents
