import React from 'react'
import { animator } from '@superrb/gatsby-addons/utils'

const elements = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
}

const AnimatedTitle = ({ text, level, className }) => {
  if (!text) {
    return null
  }

  const tag = React.createElement(
    elements[level],
    { className: `animated-title__title` },
    text,
  )

  return (
    <header
      className={`animated-title ${className ? className : ''}`}
      ref={animator}
    >
      {tag}
    </header>
  )
}

export default AnimatedTitle
