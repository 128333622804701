import React from 'react'

const ButtonArrow = ({ ...props }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.063 21.6839L8.37337 18.5929L14.2205 13.4284H0.265381V8.88979H14.2205L8.37337 3.7252L11.063 0.634277L21.8997 11.0808V11.2373L11.063 21.6839Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ButtonArrow
