import React from 'react'
import TickerTapeBase from '../components/ticker-tape'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'

export const TickerTape = ({
  slice: {
    primary: { text, background_color },
  },
}: SliceComponentProps<any, any>) => (
  <TickerTapeBase text={text} background={background_color} />
)

export const query = graphql`
  fragment ContentPageDataBodyTickerTape on PrismicContentPageDataBodyTickerTape {
    primary {
      text
      background_color
    }
  }
`
