import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import React, { useLayoutEffect, useState } from 'react'
import { A11y, Pagination } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import ArrowTitle from '../black-arrow-title'

const BenefitsSlider = ({ data }) => {
  const isMobile = useIsMobile()
  const [finalData, setFinalData] = useState([])

  useLayoutEffect(() => {
    setFinalData(data.items.slice(0, 3))
  }, [data])

  return (
    <section className="employment-benefits">
      {data?.title && (
        <ArrowTitle
          text={data.title}
          level={2}
          alignment={'left'}
          className="employment-benefits__title"
        />
      )}
      {data?.text && (
        <div className="employment-benefits__text">
          <RichText field={data.text.richText} />
        </div>
      )}
      <div className="employment-benefits__inner">
        <Swiper
          modules={[Pagination, A11y]}
          pagination={{ clickable: true, el: '.benefits-slider__pagination' }}
          slidesPerView={isMobile ? 1.15 : '3'}
          speed={1000}
        >
          {finalData?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className={`employment-benefits__slide slide--${index + 1}`}>
                {item?.employment_benefit_image && (
                  <Image
                    image={item?.employment_benefit_image}
                    className="employment-benefits__image"
                  />
                )}
                <div className="employment-benefits__slide--text">
                  {item?.employment_benefit_title && (
                    <h5 className="employment-benefits__slide--title">
                      {item.employment_benefit_title}
                    </h5>
                  )}
                  {item?.employment_benefit_text && (
                    <RichText field={item?.employment_benefit_text?.richText} />
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {isMobile && <div className={`benefits-slider__pagination`}></div>}
      </div>
    </section>
  )
}

export default BenefitsSlider
