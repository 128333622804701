import { Seo } from '@superrb/gatsby-addons/components'
import { graphql } from 'gatsby'
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import BlogLatestEvents from '../components/event/blog-latest-event'
import Hero from '../components/hero/hero'
import WorkFilters from '../components/work-filters'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import chunkArray from '../helpers/chunk-array'
import useArticleFilter from '../hooks/use-article-filter'
import OurWork from '../types/pages/our-work'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'

const Events = ({ data }) => {
  const [availableTags, setAvailableTags] = useState([])
  const { setHeaderVariant } = useContext(HeaderVariantContext)
  setHeaderVariant('light')

  /** @type {OurWork} page */
  const page = data.page
  const articles = data.articles.nodes

  const {
    data: { page_title },
  } = page

  const hero = {
    hero_title: page.data.hero_title,
    hero_subtitle: page.data.hero_subtitle,
    hero_button_text: page.data.hero_button_text,
  }

  const [splitArticles, setSplitArticles] = useState(chunkArray(articles, 3))

  useEffect(() => {
    const uniqueTags = Array.from(
      new Set(data.tags.nodes.flatMap(tag => tag.tags)),
    )
    setAvailableTags(uniqueTags)
  }, [data, setAvailableTags])

  const filteredArticles = useArticleFilter(articles, availableTags, 'date')
  //Split array into three when filteredArticles updates
  useLayoutEffect(() => {
    setSplitArticles(chunkArray(filteredArticles, 3))
  }, [filteredArticles])

  if (!page) {
    return null
  }

  return (
    <Layout>
      <Seo data={page.data} />
      {hero && (
        <Hero
          data={{
            title: hero.hero_title,
            subtitle: hero.hero_subtitle,
            buttonLabel: hero.hero_button_text,
          }}
          backgroundStyle="pink"
          colors={['#7633F4', '#FF80B1']}
        />
      )}

      <WorkFilters />

      {/* Map over the chunks of three  */}
      {splitArticles &&
        splitArticles.map((articleChunk, index) => {
          return (
            <BlogLatestEvents
              articles={articleChunk}
              key={index}
              showNewsletter={index == 0 ? true : false}
            />
          )
        })}
    </Layout>
  )
}

export const query = graphql`query EventsQuery {
  page: prismicEvents {
    ...EventListing
  }
  tags: allPrismicEvent {
    nodes {
      tags
    }
  }
  articles: allPrismicEvent(sort: {data: {date: DESC}}) {
    nodes {
      ...EventPost
    }
  }
}`

export default withPrismicPreview(Events)
