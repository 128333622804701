import { Seo } from '@superrb/gatsby-addons/components'
import { graphql } from 'gatsby'
import React, { useContext } from 'react'
import TeamMembers from '../components/about-us/team-members'
import Awards from '../components/awards'
import BenefitsSlider from '../components/benefits-slider'
import Cta from '../components/careers/cta'
import ClientLogos from '../components/client-logos'
import ContactForm from '../components/contact-form'
import FeaturedWork from '../components/featured-work'
import GlobalMap from '../components/global-map'
import Hero from '../components/hero/hero'
import PromoBlocks from '../components/promo-blocks'
import Testimonials from '../components/testimonials'
import TickerTape from '../components/ticker-tape'
import colors from '../components/utils/colors'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import AboutUs from '../types/pages/about-us'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'

const Index = ({ data }) => {
  const { setHeaderVariant } = useContext(HeaderVariantContext)
  setHeaderVariant('dark')

  /** @type {AboutUs} page */
  const page = data.page
  if (!page) {
    return null
  }

  const hero = {
    hero_title: page.data.hero_title,
    hero_button_text: page.data.hero_button_text,
    hero_subtitle: page.data.hero_subtitle,
  }

  const tickerTape = page.data.ticker_tape

  const ourEthos = {
    featured_work_title: page.data.ethos_title,
    featured_work_promo_icons: [
      page.data.ethos_promo_icon_1,
      page.data.ethos_promo_icon_2,
    ],
    featured_work: page.data.ethos_slides,
  }

  const whoWeWorkWith = {
    title: page.data.who_we_work_with_title,
    client_logos: page.data.client_logos.map(
      item => item?.client_logo?.document,
    ),
  }

  const testimonials = {
    testimonials_title: page.data.testimonials_title,
    testimonials: page.data.testimonials.map(
      item => item?.testimonial?.document,
    ),
    testimonials_promo_icons: [
      page.data.testimonials_promo_icon_1,
      page.data.testimonials_promo_icon_2,
    ],
    testimonials_graphic: page.data.testimonials_graphic,
  }

  const servicesTop = {
    service_heading: page.data.services_top[0].services_heading,
    promo_block_promo_icons: [
      page.data.services_top[0].services_promo_icon_1,
      page.data.services_top[0].services_promo_icon_2,
      page.data.services_top[0].services_promo_icon_3,
    ],
    image_1: page.data.services_top[0].services_image_1,
    image_2: page.data.services_top[0].services_image_2,
    service_title_1: page.data.services_top[0].services_title_1,
    service_title_2: page.data.services_top[0].services_title_2,
    service_description_1: page.data.services_top[0].services_description_1,
    service_description_2: page.data.services_top[0].services_description_2,
    service_button_text_1: page.data.services_top[0].services_button_text_1,
    service_button_text_2: page.data.services_top[0].services_button_text_2,
    service_button_link_1: page.data.services_top[0].services_button_link_1,
    service_button_link_2: page.data.services_top[0].services_button_link_2,
  }

  const servicesBottom = {
    promo_block_promo_icons: [
      page.data.services_bottom[0].services_promo_icon_1,
      page.data.services_bottom[0].services_promo_icon_2,
      page.data.services_bottom[0].services_promo_icon_3,
    ],
    image_1: page.data.services_bottom[0].services_image_1,
    image_2: page.data.services_bottom[0].services_image_2,
    service_title_1: page.data.services_bottom[0].services_title_1,
    service_title_2: page.data.services_bottom[0].services_title_2,
    service_description_1: page.data.services_bottom[0].services_description_1,
    service_description_2: page.data.services_bottom[0].services_description_2,
    service_button_text_1: page.data.services_bottom[0].services_button_text_1,
    service_button_text_2: page.data.services_bottom[0].services_button_text_2,
    service_button_link_1: page.data.services_bottom[0].services_button_link_1,
    service_button_link_2: page.data.services_bottom[0].services_button_link_2,
  }

  const teamMembers = {
    title: page.data.team_title,
    team_members: page.data.team_members,
  }

  const biographyTitle = page?.data?.meet_the_team_biography_title

  const joinUs = {
    cta_title: page.data.join_us_title,
    cta_description: page.data.description,
    cta_link_text: page.data.button_text,
    cta_link: page.data.button_link,
  }

  const ourStory = {
    title: page.data.our_story_title,
    items: page.data.our_stories,
  }

  const causes = {
    title: page.data.causes_heading,
    image_1: page.data.causes_image_1,
    image_2: page.data.causes_image_2,
    service_title_1: page.data.causes_title_1,
    service_title_2: page.data.causes_title_2,
    service_description_1: page.data.causes_description_1,
    service_description_2: page.data.causes_description_2,
  }

  const awards = {
    title: page.data.awards_title,
    awards: page.data.awards,
  }

  const globalMap = {
    title: page.data.map_title,
    text: page.data.map_text,
    button_text: page.data.map_button_text,
    button_link: page.data.map_button_link,
  }

  const showForm = page.data.show_contact_form || false

  return (
    <Layout>
      <Seo data={page.data} />
      {hero && (
        <Hero
          data={{
            title: hero.hero_title,
            subtitle: hero.hero_subtitle,
            buttonLabel: hero.hero_button_text,
          }}
          backgroundStyle="light-purple"
          colors={['#0F1449', '#DDCCFC']}
        />
      )}

      {tickerTape && <TickerTape text={tickerTape} background="black" />}

      {ourEthos && <FeaturedWork data={ourEthos} showLogos={false} />}

      {whoWeWorkWith && <ClientLogos data={whoWeWorkWith} />}

      {testimonials?.testimonials_title && (
        <Testimonials data={testimonials} backgroundColor={colors.purple} />
      )}

      {servicesTop && <PromoBlocks data={servicesTop} />}

      {servicesBottom && (
        <PromoBlocks data={servicesBottom} reversedLayout={true} />
      )}

      {teamMembers && <TeamMembers data={teamMembers} title={biographyTitle} />}

      {joinUs && <Cta data={joinUs} theme="light-alt" />}

      {ourStory && <BenefitsSlider data={ourStory} titleAlignment="right" />}

      {causes && (
        <PromoBlocks
          title={causes.title}
          data={causes}
          classes="promo-blocks--causes second"
        />
      )}

      {awards && <Awards data={awards} />}

      {showForm && <ContactForm />}

      {globalMap.title && <GlobalMap data={globalMap} />}
    </Layout>
  )
}

export const query = graphql`
  query AboutUsQuery {
    page: prismicAboutUs {
      ...AboutUs
    }
  }
`

export default withPrismicPreview(Index)
