import React from 'react'
import WhiteArrow from './icons/white-arrow'

const elements = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
}

const ArrowTitle = ({ text, level, className, alignment, backgroundColor }) => {
  const body = (
    <>
      <WhiteArrow className="black-arrow-title__icon" />
      <span className="black-arrow-title__text">{text}</span>
    </>
  )

  return React.createElement(
    elements[level],
    {
      className: `black-arrow-title ${backgroundColor ? backgroundColor : ''} ${
        className ? className : ''
      } ${alignment == 'right' ? 'black-arrow-title--right' : ''}`,
    },
    body,
  )
}

export default ArrowTitle
