import { Seo } from '@superrb/gatsby-addons/components'
import { graphql } from 'gatsby'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import LegalPage from '../types/pages/legal-page'
import Hero from './../components/hero/hero'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'

const Legal = ({ data }) => {
  const { setHeaderVariant } = useContext(HeaderVariantContext)
  setHeaderVariant('dark')

  /** @type {LegalPage} page */
  const page = data?.page || data?.prismicLegalPage
  if (!page) {
    return null
  }

  return (
    <Layout>
      <Seo data={page.data} />
      <Hero
        data={{
          title: page.data.page_title,
        }}
        backgroundStyle="pink"
        colors={['#0F1449', '#DDCCFC']}
      />
      <div className="legal">
        <div className="legal__content">
          <RichText field={page.data.page_content.richText} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query LegalPageQuery($id: String) {
    page: prismicLegalPage(id: { eq: $id }) {
      ...LegalPage
    }
  }
`

export default withPrismicPreview(Legal)
