import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import BlackArrowTitle from '../black-arrow-title'
import { Image, Link, RichText } from '@superrb/gatsby-addons/components'
import { A11y, Pagination, Swiper as SwiperInstance } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { Item } from '../../slices/sub-service'
import { linkResolver } from '../../utils/linkResolver'
import RectangleButton from '../buttons/rectangle-button'

const SubService = ({
  data: { title, items },
}: {
  data: { title: string; items: Item[] }
}) => {
  const [current, setCurrent] = useState<string | undefined>()
  const categories = useRef<string[]>([
    ...new Set(
      items
        .map(({ category }: Item) => category)
        .filter(category => !!category),
    ),
  ] as string[]) as MutableRefObject<string[]>
  const swiper = useRef<SwiperInstance>() as MutableRefObject<SwiperInstance>
  const isMobile = useIsMobile(false)

  useEffect(() => {
    categories.current = [
      ...new Set(
        items
          .map(({ category }: Item) => category)
          .filter(category => !!category),
      ),
    ] as string[]
  }, [items])

  return (
    <section className="sub-service" aria-role="title">
      <BlackArrowTitle text={title} level={2} className="services__title" />

      <nav className="services__pagination">
        <ul className="services__pagination-items">
          <li className="services__pagination-item" key="all">
            <button
              className="work-filter__item-button services__pagination-item-button"
              onClick={() => setCurrent(undefined)}
              aria-selected={current === undefined}
            >
              ALL
            </button>
          </li>

          {categories.current.map((category, index) => (
            <li className="services__pagination-item" key={index}>
              <button
                className="work-filter__item-button services__pagination-item-button"
                onClick={() => setCurrent(category)}
                aria-selected={category === current}
              >
                {category}
              </button>
            </li>
          ))}
        </ul>
      </nav>

      <div className="sub-service__content">
        <div className="sub-service__content-wrapper">
          <Swiper
            className="sub-service__content-swiper"
            onSwiper={(swiperInstance: SwiperInstance) =>
              (swiper.current = swiperInstance)
            }
            modules={[Pagination, A11y]}
            pagination={{ clickable: true, el: '.benefits-slider__pagination' }}
            spaceBetween={isMobile ? 25 : 50}
          >
            {items
              .filter(({ category }: Item) => !current || category === current)
              .map(({ title, text, link, link_label, icon }: Item, index) => {
                return (
                  <SwiperSlide
                    className="sub-service__content-swiper-slide"
                    key={index}
                  >
                    <Image
                      image={icon}
                      className="sub-service__content-swiper-slide-image"
                    />
                    <div className="sub-service__content-swiper-slide-content">
                      <h3 className="sub-service__content-swiper-slide-title">
                        {title}
                      </h3>
                      <RichText
                        className="sub-service__content-swiper-slide-text"
                        field={text.richText}
                      />

                      {(link?.type || link?.url) && (
                        <Link
                          to={link}
                          className="sub-service__content-swiper-slide-link"
                        >
                          {link_label}
                        </Link>
                      )}
                    </div>
                  </SwiperSlide>
                )
              })}
          </Swiper>
        </div>
        <div className="benefits-slider__pagination"></div>
      </div>
    </section>
  )
}

export default SubService
