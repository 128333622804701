import { PrismicProvider } from '@prismicio/react'
import { LocationProvider } from '@reach/router'
import { LazyLoadingContextProvider, PreloadContextProvider, TranslationContextProvider, NavContextProvider } from '@superrb/gatsby-addons/context'
import { Link } from 'gatsby'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import * as React from 'react'
// import { IntercomProvider } from 'react-use-intercom'
import { StateInspector } from 'reinspect'
import { Layout } from './src/components/layout'
import CookiesContextProvider from './src/context/cookies-context'
import { FilterContextProvider } from './src/context/filter-context-provider'
import HeaderVariantContextProvider from './src/context/header-variant-context-provider'
import NewsletterContextProvider from './src/context/newsletter-context-provider'
import './src/stylesheets/style.sass'
import { repositoryConfigs } from './src/utils/prismic-previews'

export const wrapRootElement = ({ element }) => (
  <StateInspector name="App">
    <PrismicProvider
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
        {/* <IntercomProvider
          appId={process.env.GATSBY_INTERCOM_APP_ID}
          autoBoot={true}
        > */}
        <PreloadContextProvider>
          <LazyLoadingContextProvider>
            <TranslationContextProvider>
              <CookiesContextProvider>
                <LocationProvider>
                  <FilterContextProvider>
                    <HeaderVariantContextProvider>
                      <NavContextProvider>
                        <NewsletterContextProvider>
                            {element}
                        </NewsletterContextProvider>
                      </NavContextProvider>
                    </HeaderVariantContextProvider>
                  </FilterContextProvider>
                </LocationProvider>
              </CookiesContextProvider>
            </TranslationContextProvider>
          </LazyLoadingContextProvider>
        </PreloadContextProvider>
      </PrismicPreviewProvider>
    </PrismicProvider>
  </StateInspector>
)

export const onRouteUpdate = ({ location: { pathname } }) => {
  if (pathname === '/') {
    pathname = 'home'
  }

  document.body.setAttribute(
    'class',
    `page ${[
      ...pathname
        .split('/')
        .filter(x => !!x)
        .map(slug => `page--${slug}`),
    ].join(' ')}`,
  )
}
