import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { Link } from 'gatsby'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import React, { useEffect, useState } from 'react'
import SquareButton from '../../components/buttons/square-button'
import { linkResolver } from '../../utils/linkResolver'
import { ImageLayout } from '@superrb/gatsby-addons/types'

const Resource = ({ resource }) => {
  const isMobile = useIsMobile()
  const [tags, setTags] = useState([])

  useEffect(() => {
    if (resource && resource.tags.length >= 3) {
      setTags(resource.tags.slice(0, 3))
    } else if (resource) {
      setTags(resource.tags)
    }
  }, [resource])

  const colors = {
    dark_blue: 'white',
    pastel_pink: 'navy',
    off_white: 'navy',
    hot_pink: 'navy',
    purple: 'white',
    navy: 'white',
    white: 'navy',
  }

  if (!resource) return null

  return (
    <article
      className={`blog-article article ${
        resource?.data?.featured ? ' featured' : ''
      }`}
    >
      <Link to={linkResolver(resource)}>
        <div className="article__inner">
          {resource?.data?.featured_image && (
            <Image
              image={resource?.data?.featured_image}
              className={`article__image blog-article__image ${
                resource?.data?.featured ? ' featured' : ''
              }`}
              alt={resource?.data?.featured_image?.alt}
              layout={ImageLayout.cover}
            />
          )}
          {resource?.data?.description_homepage?.richText?.length > 0 &&
            !isMobile && (
              <div
                className={`article__description blog-article__description ${
                  resource?.data?.featured ? ' featured' : ''
                } ${resource?.data?.background_color
                  .replace(' ', '_')
                  .toLowerCase()}`}
              >
                <RichText
                  field={resource?.data?.description_homepage.richText}
                />
              </div>
            )}
          <div
            className={`article__details blog-article__details ${
              resource?.data?.featured ? ' featured' : ''
            } ${resource?.data?.background_color
              .replace(' ', '_')
              .toLowerCase()}`}
          >
            {resource?.data?.date && (
              <div className="article__services">
                {resource?.data?.date && <span>{resource?.data?.date}</span>}
              </div>
            )}
            {resource?.data?.page_title && <h6>{resource.data.page_title}</h6>}
            {(!isMobile || resource?.data?.featured) && (
              <div className="article__footer blog-article__footer">
                <SquareButton
                  link={linkResolver(resource)}
                  text={'Read More'}
                  theme={
                    colors[
                      resource?.data?.background_color
                        .replace(' ', '_')
                        .toLowerCase()
                    ]
                  }
                />
              </div>
            )}
          </div>
        </div>
      </Link>
    </article>
  )
}

export default Resource
