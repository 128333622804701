import { RichText } from 'ProjectRoot/src/components/rich-text'
import React from 'react'
import ArrowTitle from '../arrow-title'

const Results = ({ title, text }) => {
  return (
    <section className="work-detail__results">
      {title && <ArrowTitle text={title} level={2} className="white" />}
      <div className="work-detail__results-content">
        <div className="work-detail__results-content-left">
          {text && <RichText field={text.richText} />}
        </div>
      </div>
    </section>
  )
}

export default Results
