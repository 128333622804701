import React from 'react'
import ImageSliderBase from '../components/careers/image-slider'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'

export const ImageSlider = ({ slice }: SliceComponentProps<any, any>) => {
  return (
    <ImageSliderBase
      background={'pink'}
      images={slice.items}
      paginationTheme={'light'}
    />
  )
}

export const query = graphql`
  fragment ContentPageDataBodyImageSlider on PrismicContentPageDataBodyImageSlider {
    items {
      image {
        ...Image
      }
    }
  }
`
