import React from 'react'

const Twitter = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5928 2.00415C18.8677 2.31197 18.095 2.51599 17.2895 2.61506C18.1182 2.13624 18.7507 1.3838 19.048 0.476855C18.2754 0.92266 17.4223 1.23756 16.5132 1.41328C15.7795 0.657302 14.7339 0.189087 13.5932 0.189087C11.38 0.189087 9.59833 1.92749 9.59833 4.05862C9.59833 4.36526 9.62512 4.66012 9.69095 4.94079C6.36757 4.78395 3.42687 3.24249 1.45137 0.894355C1.10648 1.47343 0.904178 2.13624 0.904178 2.84977C0.904178 4.18954 1.61711 5.37718 2.67981 6.06473C2.03756 6.05296 1.4075 5.87252 0.873712 5.5883C0.873712 5.60008 0.873712 5.6154 0.873712 5.63072C0.873712 7.51067 2.25936 9.07217 4.07643 9.43187C3.75104 9.51795 3.3964 9.55924 3.02836 9.55924C2.77243 9.55924 2.51407 9.54509 2.27155 9.4932C2.78949 11.0252 4.25926 12.1515 6.00684 12.1881C4.64678 13.2177 2.9199 13.838 1.05042 13.838C0.722595 13.838 0.408172 13.8239 0.09375 13.785C1.86451 14.89 3.9631 15.521 6.2262 15.521C13.5822 15.521 17.6039 9.62412 17.6039 4.51267C17.6039 4.34167 17.5978 4.17656 17.5893 4.01265C18.3827 3.46776 19.0493 2.78727 19.5928 2.00415Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Twitter
