import { useEventListener, useIsInViewport, useIsMobile } from '@superrb/gatsby-addons/hooks'
import { Link } from 'gatsby'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import PinkTickerArrow from './icons/pink-ticker-arrow'
import TickerArrow from './icons/ticker-arrow'

const TickerTapeFooter = ({ text, background }) => {
  const [styles, setStyles] = useState({
    transform: '0px',
  })
  const [splitText, setSplitText] = useState(null)
  const isMobile = useIsMobile()
  const element = useRef(null)

  const { isInViewport, setRef } = useIsInViewport(false)

  if (!text) {
    text = 'Text Missing'
  }

  const updateStyles = useCallback(() => {
    if (!element.current) {
      return
    }

    const top = element.current.getBoundingClientRect().top

    requestAnimationFrame(() => {
      setStyles({
        transform: !isMobile
          ? top - window.innerWidth
          : top - window.innerWidth - 500,
      })
    })
  }, [isMobile])

  useEffect(() => {
    const split = text.split(',')
    const multi = [...split, ...split, ...split, ...split]
    setSplitText(multi)
  }, [text])

  useEventListener(
    'scroll',
    updateStyles,
    { passive: true },
    typeof window !== 'undefined' ? window : undefined,
    isInViewport,
  )

  return (
    <div
      className={`ticker-tape ticker-tape--${background} ticker-tape__link`}
      ref={ref => {
        setRef(ref)
        element.current = ref
      }}
    >
      <Link
        className="ticker-tape__inner"
        style={{ transform: `translateX(${styles.transform * 0.5}px)` }}
        to={'/contact'}
      >
        {splitText &&
          splitText.map((text, index) => {
            return (
              <span key={index} className="ticker-tape__item">
                {background == 'navy' ? <TickerArrow /> : <PinkTickerArrow />}

                {text}
              </span>
            )
          })}
      </Link>
    </div>
  )
}

export default TickerTapeFooter
