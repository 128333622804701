import { Map } from '@superrb/gatsby-addons/components'
import { graphql, useStaticQuery } from 'gatsby'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import React, { useEffect, useState } from 'react'
import Office from '../../types/office'
import ArrowTitle from '../arrow-title'
import MapPin from '../icons/map-pin.svg'
import mapOptions from '../utils/map-options'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

const Offices = () => {
  const staticData = useStaticQuery(graphql`
    query OfficesQuery {
      offices: allPrismicOffice(sort: { data: { sort_order: ASC } }) {
        nodes {
          ...Office
        }
      }
    }
  `)

  const data = useMergePrismicPreviewData(staticData)
  const [selectedOffice, setSelectedOffice] = useState(null)

  /**
   * @type {Office[]} offices
   */
  const offices = data?.offices?.nodes || data?.allPrismicOffice?.nodes
  const order = ['New York', 'Brighton', 'Portsmouth', 'China']
  const sortedOffices = order
    .map(n => offices.find(i => i.data.office_title === n))
    .filter(office => !!office)

  useEffect(() => {
    setSelectedOffice(offices[0])
  }, [offices])

  return (
    <section className="offices">
      <div className="offices__content">
        <h2 className="offices__title">Our offices</h2>

        <ul className="offices__list">
          {sortedOffices?.map(office => (
            <li className="offices__office" key={office.uid}>
              <button
                className="offices__office-select"
                onClick={() => setSelectedOffice(office)}
              >
                <ArrowTitle
                  level="4"
                  text={office.data.office_title}
                  className="offices__office-title"
                />

                <div className="offices__office-content">
                  {office?.data?.office_address && (
                    <RichText field={office.data.office_address.richText} />
                  )}

                  {office?.data?.office_phone_number && (
                    <span className="offices__office-phone">
                      {office.data.office_phone_number}
                    </span>
                  )}
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>

      {selectedOffice && (
        <Map
          className="offices__map"
          center={{
            lat: selectedOffice.data.office_latitude,
            lng: selectedOffice.data.office_longitude,
          }}
          useClustering={true}
          initialZoom={15}
          markers={[
            {
              label: selectedOffice.data.office_title,
              center: {
                lat: selectedOffice.data.office_latitude,
                lng: selectedOffice.data.office_longitude,
              },
            },
          ]}
          markerIcon={
            <MapPin
              style={{
                position: 'absolute',
                bottom: '100%',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            />
          }
          options={mapOptions}
        />
      )}
    </section>
  )
}

export default Offices
