import { useContext, useEffect, useState } from 'react'
import { FilterContext } from '../context/filter-context-provider'
import BlogPost from '../types/pages/blog-post'
import EventPost from '../types/pages/event-post'
import ResourcePost from '../types/pages/resource-post'

type Article = BlogPost | EventPost | ResourcePost

const extractKey = (article: Article, sortField: string) => {
  let value

  if (sortField in article) {
    value = article[sortField]
  }

  if (sortField in article.data) {
    value = article.data[sortField]
  }

  if (value === undefined) {
    throw new Error(`Unknown sort field "${sortField}"`)
  }

  try {
    return new Date(value).setHours(0, 0, 0, 0)
  } catch {
    throw new Error(`Sort value ${value} is not a valid date`)
  }
}


const useArticleFilter = (
  articles: Article[],
  availableTags: [],
  sortField: string = 'first_publication_date',
) => {
  const { selectedTags = [], setAvailableTags } = useContext(FilterContext)
  const [isFiltered, setIsFiltered] = useState(false)

  useEffect(() => {
    setAvailableTags(availableTags)
  }, [availableTags])

  const [filteredArticles, setFilteredArticles] = useState<Article[]>([])

  //Filter the results based of current tags
  useEffect(() => {
    const filtered = articles.filter(article => {
      if (selectedTags.length === 0) {
        return true
      }

      return (
        article.tags.filter(tag => selectedTags.includes(tag)).length ===
        selectedTags.length
      )
    })

    const featured = filtered.filter(article => article.data.featured === true)
    const nonFeatured = filtered.filter(
      article => article.data.featured === false,
    )

    if (featured.length + nonFeatured.length !== filtered.length) {
      throw new Error('Error whilst filtering projects')
    }

    // If we have too many featured articles, inject them into the non-featured
    // array, then sort it, and remove featured flags
    nonFeatured.unshift(...featured.splice(Math.floor(filtered.length / 3)))
    nonFeatured
      .sort((a, b) => {
        const keyA = extractKey(a, sortField)
        const keyB = extractKey(b, sortField)

        return keyB - keyA
      })
      .map(article => {
        article.data.featured = false

        return article
      })

    let finalListing = new Array(filtered.length)
    finalListing.fill(null)
    finalListing = finalListing.map((_, i) => {
      if ([0, 5].includes(i % 6)) {
        const item = featured.shift()
        if (item) {
          return item
        }
      }

      return nonFeatured.shift()
    })

    setFilteredArticles(finalListing)
    setIsFiltered(true)
  }, [selectedTags, setFilteredArticles, setAvailableTags, articles])

  if (isFiltered) {
    return filteredArticles
  } else {
    return articles
  }

}

export default useArticleFilter
