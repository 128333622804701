import React from 'react'
import ContactFormBase from '../components/contact-form'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'

export const ContactForm = ({ slice }: SliceComponentProps<any, any>) => {
  return (
    <ContactFormBase
      title={slice.primary.title}
      formUrl={slice.primary.form_url.url}
    />
  )
}

export const query = graphql`
  fragment ContentPageDataBodyContactForm on PrismicContentPageDataBodyContactForm {
    primary {
      title
      form_url {
        ...Link
      }
    }
  }
`
