import React, { useCallback, useContext, useEffect, useState } from 'react'
import ArrowTitle from '../arrow-title'
import { Image } from '@superrb/gatsby-addons/components'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import RectangleButton from '../buttons/rectangle-button'
import ReactPlayer from 'react-player'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import HeroPlayButton from './hero-play-button'
import { CookiesContext } from '../../context/cookies-context'
import VideoClose from '../icons/video-close.svg'
import {linkResolver} from "../../utils/linkResolver";

const Hero = ({
  data: {
    hero_title: title,
    hero_description: subtitle,
    hero_button: heroButton,
    hero_button_text: heroButtonLabel,
    hero_image: image,
    hero_image_mobile: imageMobile,
    hero_video_preview_url: videoPreviewUrl,
    hero_video_url: videoUrl,
    hero_video_mobile_url: videoMobileUrl,
  },
}) => {
  const { cookiesAccepted } = useContext(CookiesContext)
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [videoIsEmbedCode, setVideoIsEmbedCode] = useState(true)
  const [videoPreviewIsEmbedCode, setVideoPreviewIsEmbedCode] = useState(true)
  const [videoMobileIsEmbedCode, setVideoMobileIsEmbedCode] = useState(true)
  const isMobile = useIsMobile(true)
  const [containerSize, setContainerSize] = useState({
    height: '100vh',
    width: '100vw',
  })

  useEffect(() => {
    let checkVideoUrl = (url) => {
      if (url.includes('player.vimeo.com')) {
        return false;
      } else {
        return true;
      }
    }

    setVideoIsEmbedCode(checkVideoUrl(videoUrl))
    setVideoPreviewIsEmbedCode(checkVideoUrl(videoPreviewUrl))
    setVideoMobileIsEmbedCode(checkVideoUrl(videoMobileUrl))
  }, [videoUrl, videoPreviewUrl, videoMobileUrl])

  const handleVideoPlay = useCallback(
    event => {
      setVideoPlaying(true)
    },
    [setVideoPlaying],
  )

  const handleVideoClose = useCallback(
    event => {
      setVideoPlaying(false)
    },
    [setVideoPlaying],
  )

  const handleResize = useCallback(() => {
    let height = window.innerHeight
    let width = window.innerHeight * (16 / 9)

    if (width < window.innerWidth) {
      width = window.innerWidth
      height = width * (9 / 16)
    }
    setContainerSize({
      height: `${height}px`,
      width: `${width}px`,
    })
  }, [setContainerSize])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return (
    <section
      className={`homepage-hero ${videoPlaying && 'homepage-hero--playing'}`}
      aria-label={title}
    >
      <div className="homepage-hero__container container">
        <Image
          className="homepage-hero__background"
          image={isMobile ? imageMobile : image}
          style={{ position: 'absolute' }}
        />

        {(cookiesAccepted || !videoPreviewIsEmbedCode) && !isMobile && videoPreviewUrl && (
          <div className="homepage-hero__preview-video">
            <ReactPlayer
              url={videoPreviewUrl}
              muted={true}
              playsinline={true}
              playing={true}
              autoPlay={true}
              loop={true}
              className="video-player__player"
              light={false}
              height={containerSize.height}
              width={containerSize.width}
              config={{
                vimeo: {
                  playerOptions: {
                    responsive: true,
                    height: containerSize.height,
                    width: containerSize.width,
                  },
                },
              }}
            />
          </div>
        )}

        <div className="homepage-hero__content">
          <ArrowTitle className="homepage-hero__title" level={1} text={title} />

          <div className="homepage-hero__subtitle">
            <RichText field={subtitle.richText} />
          </div>

          {heroButtonLabel && heroButton && (
            <RectangleButton
              text={heroButtonLabel}
              link={linkResolver(heroButton)}
              style="rainbow"
              className="homepage-hero__button"
            />
          )}
        </div>

        <HeroPlayButton onClick={handleVideoPlay} />

        {(cookiesAccepted || !videoIsEmbedCode) && videoUrl && (
          <button
            className="homepage-hero__video-close"
            onClick={handleVideoClose}
          >
            <span className="homepage-hero__video-close-label">Close</span>
            <VideoClose />
          </button>
        )}

        {(cookiesAccepted || !videoIsEmbedCode) && videoUrl && !isMobile && (
          <div className="homepage-hero__video">
            <ReactPlayer
              url={videoUrl}
              muted={false}
              playsinline={true}
              playing={videoPlaying}
              autoPlay={false}
              loop={true}
              className="video-player__player"
              light={false}
              height={containerSize.height}
              width={containerSize.width}
              config={{
                vimeo: {
                  playerOptions: {
                    responsive: true,
                    height: containerSize.height,
                    width: containerSize.width,
                  },
                },
              }}
            />
          </div>
        )}
        {(cookiesAccepted || !videoIsEmbedCode) && videoMobileUrl && isMobile && (
          <div className="homepage-hero__video">
            <ReactPlayer
              url={videoMobileUrl}
              muted={false}
              playsinline={true}
              playing={videoPlaying}
              autoPlay={false}
              loop={true}
              className="video-player__player"
              light={false}
              height={containerSize.height}
              width={containerSize.width}
              config={{
                vimeo: {
                  playerOptions: {
                    responsive: true,
                    height: containerSize.height,
                    width: containerSize.width,
                  },
                },
              }}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default Hero
