import React from 'react'

const Menu = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 2H20" stroke="#06081C" strokeWidth="3" />
    <path d="M0 8.22217H20" stroke="#06081C" strokeWidth="3" />
    <path d="M0 14.4443H20" stroke="#06081C" strokeWidth="3" />
  </svg>
)

export default Menu
