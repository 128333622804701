import React from 'react'
import TeamMembersBase from '../components/about-us/team-members'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image, Link, RichText } from '@superrb/gatsby-addons/types'

export const TeamMembers = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.title,
    team_members: slice.items.map(
      ({
        name,
        role,
        description,
        description_mobile,
        twitter,
        linked_in,
        background_color,
        image,
      }: {
        name: string
        role: string
        description: RichText
        description_mobile: RichText
        twitter: Link
        linked_in: Link
        background_color: string
        image: Image
      }) => ({
        team_member_name: name,
        team_member_role: role,
        team_member_description: description,
        team_member_description_mobile: description_mobile,
        team_member_twitter: twitter,
        team_member_linked_in: linked_in,
        team_member_background_color: background_color,
        team_member_image: image,
      }),
    ),
  }

  return <TeamMembersBase data={data} />
}

export const query = graphql`
  fragment ContentPageDataBodyTeamMembers on PrismicContentPageDataBodyTeamMembers {
    primary {
      title
    }
    items {
      name
      role
      description {
        ...RichText
      }
      description_mobile {
        ...RichText
      }
      twitter {
        ...Link
      }
      linked_in {
        ...Link
      }
      background_color
      image {
        ...Image
      }
    }
  }
`
