import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { Link } from 'gatsby'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import React from 'react'
import LinkedIn from './../icons/linked-in'
import Twitter from './../icons/twitter'

const TeamMemberCard = ({ data, title }) => {
  const isMobile = useIsMobile()

  return (
    <div className="team-member">
      <div
        className={`team-member__card ${
          data?.team_member_image && data?.team_member_background_color
        }`}
      >
        {data?.team_member_image && (
          <Image
            image={data?.team_member_image}
            className="team-member__card-image"
          />
        )}
        <div className="team-member__card-details">
          {data?.team_member_name && data?.team_member_role && (
            <div className="team-member__card-info">
              <h6 className="team-member__card-info-name">
                {data?.team_member_name}
              </h6>
              <p className="team-member__card-info-title">
                {data?.team_member_role}
              </p>
            </div>
          )}
          <div className="team-member__card-socials">
            {data?.team_member_linked_in?.url && (
              <Link
                to={data?.team_member_linked_in.url}
                target="_blank"
                rel="noopener"
                className="team-member__card-socials-link"
              >
                <LinkedIn />
              </Link>
            )}
            {data?.team_member_twitter?.url && (
              <Link
                to={data?.team_member_twitter?.url}
                target="_blank"
                rel="noopener"
                className="team-member__card-socials-link"
              >
                <Twitter />
              </Link>
            )}
          </div>
        </div>
        {data?.team_member_description?.richText?.filter(({ text }) => !!text)
          .length > 0 && (
          <div className="team-member__card-biog">
            {title && <h6 className="team-member__card-biog-title">{title}</h6>}
            <RichText
              field={
                isMobile
                  ? data?.team_member_description_mobile?.richText
                  : data?.team_member_description?.richText
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TeamMemberCard
