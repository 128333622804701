import { RichText } from 'ProjectRoot/src/components/rich-text'
import React from 'react'
import WhiteArrow from '../icons/white-arrow'

const TwoColIntro = ({ content, services, client }) => {
  return (
    <section className="work-detail__two-col">
      <div className="work-detail__two-col-text">
        {content && <RichText field={content.richText} />}
      </div>
      <div className="work-detail__two-col-details">
        <div className="work-detail__two-col-details-top">
          {services && (
            <>
              <div className="work-detail__two-col-details-title subtitle">
                Services
              </div>
              <div className="work-detail__two-col-details-content">
                <ul className="work-detail__two-col-list">
                  {services.split(',').map((service, index) => {
                    return (
                      <li
                        key={index}
                        className="work-detail__two-col-list-item"
                      >
                        <WhiteArrow className="nav__item-featured-arrow" />
                        {service}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default TwoColIntro
