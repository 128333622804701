import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import AnimatedTitle from '../animated-title'
import VacancyArrow from '../icons/vacancy-arrow'

const loadWorkableApi = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = 'https://www.workable.com/assets/embed.js'
    script.async = true
    script.onload = () => {
      resolve()
    }
    script.onerror = () => {
      reject()
    }
    document.body.appendChild(script)
  })
}

const Vacancies = ({ data }) => {
  useEffect(() => {
    loadWorkableApi().then(() => {
      whr_embed(604023, {
        detail: 'titles',
        base: 'jobs',
        zoom: 'country',
        grouping: 'none',
      })
    })
  }, [])

  return (
    <section className="vacancies">
      <div className="vacancies__inner">
        {data?.title && (
          <AnimatedTitle
            text={data?.title}
            level={2}
            className="vacancies__title"
          />
        )}
        <div id="whr_embed_hook"></div>
      </div>
    </section>
  )
}

export default Vacancies
