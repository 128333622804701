import React from 'react'
import BlogDetail from '../../types/pages/blog-detail'
import RectangleButton from '../buttons/rectangle-button'

const Tag = ({ label }) => (
  <button
    className="tag"
    onClick={() => {
      if (window.location.pathname.includes('/blog')) {
        window.location.href = `/blog?tags[]=${label}`
      }
      if (window.location.pathname.includes('/resource')) {
        window.location.href = `/resource?tags[]=${label}`
      }
      if (window.location.pathname.includes('/events')) {
        window.location.href = `/event?tags[]=${label}`
      }
    }}
  >
    {label}
  </button>
)

/**
 *
 * @param {BlogDetail} page
 * @returns
 */
const BlogDetailHeader = ({ page, showButton }) => (
  <section className="blog-detail-header">
    {page.tags.length > 0 && (
      <ul className="blog-detail-header__tags">
        {page.tags.map((tag, index) => (
          <Tag label={tag} key={index} />
        ))}
      </ul>
    )}
    <ul className="blog-detail-header__meta">
      {page?.data?.author && (
        <li className="blog-detail-header__meta-item">
          <span className="blog-detail-header__meta-item-title">Author</span>
          <span className="blog-detail-header__meta-item-value">
            {page.data.author}
          </span>
        </li>
      )}

      {page?.data?.reading_time && (
        <li className="blog-detail-header__meta-item">
          <span className="blog-detail-header__meta-item-title">
            Reading time
          </span>
          <span className="blog-detail-header__meta-item-value">
            {page.data.reading_time} mins
          </span>
        </li>
      )}
    </ul>
    {showButton && (
      <RectangleButton
        className="blog-detail-header__button"
        link={'#blog-content-html'}
        text={page?.data?.ticket_button_label || 'Download now'}
        style="pink"
      />
    )}
  </section>
)

export default BlogDetailHeader
