import React, { useCallback, useEffect, useState } from 'react'

const HeroPlayButton = ({ onClick }) => {
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseEnter = useCallback(event => {
    setIsHovering(true)
  })

  const handleMouseLeave = useCallback(event => {
    setIsHovering(false)
  })

  return (
    <button
      className="homepage-hero__play-button play-button"
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <svg
        width="198"
        height="199"
        viewBox="0 0 198 199"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="play-button__roundel"
      >
        <path
          className="play-button__play"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.3455 85L109.911 98.7003L87.3455 112.401V85Z"
          fill="url(#paint0_linear_1608_195)"
        />
        <g
          clipPath="url(#clip0_1608_195)"
          className={`play-button__text ${
            isHovering ? 'play-button__text--rotating' : ''
          }`}
        >
          <path
            d="M90.5714 5.73654C90.4828 2.41182 92.7515 0.102239 96.3487 4.51794e-05C99.3328 -0.08171 101.383 1.34219 101.908 3.69266L99.1693 4.25813C98.8627 2.84785 97.8816 2.07118 96.3623 2.11887C94.4411 2.17337 93.3101 3.52233 93.3646 5.63434C93.4192 7.70547 94.6387 9.01356 96.594 8.96587C98.0724 8.92499 99.0126 8.12788 99.2851 6.74485L102.024 7.26263C101.438 9.57222 99.5372 11.0166 96.594 11.0983C92.9014 11.2005 90.6667 9.0885 90.5714 5.74335V5.73654Z"
            fill="white"
          />
          <path
            d="M105.11 10.9144L106.268 0.483765L108.939 0.783534L108.019 9.08169L113.456 9.68804L113.217 11.8205L105.11 10.9212V10.9144Z"
            fill="white"
          />
          <path
            d="M118.824 13.0331L116.195 12.4677L118.409 2.2074L121.039 2.77287L118.824 13.0331Z"
            fill="white"
          />
          <path
            d="M123.586 8.68659C124.656 5.53901 127.592 4.1628 130.992 5.321C133.82 6.28162 135.243 8.33232 134.916 10.7168L132.157 10.2944C132.362 8.86373 131.708 7.80091 130.27 7.31038C128.451 6.6904 126.925 7.56245 126.244 9.55864C125.576 11.5208 126.264 13.1695 128.11 13.8031C129.507 14.28 130.665 13.8576 131.401 12.6585L133.785 14.0961C132.43 16.0582 130.154 16.7463 127.368 15.7993C123.873 14.607 122.51 11.8546 123.586 8.68659Z"
            fill="white"
          />
          <path
            d="M142.063 22.0126L141.437 16.4805L139.12 17.2027L137.778 19.7712L135.394 18.5244L140.258 9.22473L142.643 10.4715L140.476 14.6138L146.649 12.5699L149.476 14.0483L143.917 15.8469L144.823 23.4638L142.057 22.0194L142.063 22.0126Z"
            fill="white"
          />
          <path
            d="M165.834 26.2912L164.478 27.9127L161.705 25.6031L156.343 32.0481L154.272 30.3245L159.634 23.8794L156.861 21.5698L158.217 19.9484L165.827 26.2844L165.834 26.2912Z"
            fill="white"
          />
          <path
            d="M164.171 31.8573C166.678 29.5818 169.86 29.8748 172.279 32.5523C174.711 35.2366 174.677 38.4182 172.17 40.6937C169.663 42.9692 166.495 42.6831 164.062 39.9988C161.637 37.3281 161.671 34.1261 164.171 31.8573ZM170.296 38.6158C171.884 37.1782 172.068 35.3728 170.759 33.9285C169.458 32.4909 167.646 32.4978 166.059 33.9353C164.478 35.366 164.287 37.1782 165.588 38.609C166.897 40.0533 168.709 40.0533 170.296 38.609V38.6158Z"
            fill="white"
          />
          <path
            d="M185.557 51.6965C186.777 53.8562 186.409 55.8524 184.603 56.8675C182.723 57.9304 180.761 57.2218 179.528 55.0349L178.083 52.4732L174.943 54.2446L173.621 51.9009L182.757 46.7435L185.557 51.6965ZM180.863 53.6042C181.435 54.6193 182.28 54.9395 183.159 54.449C184.024 53.9652 184.188 53.0864 183.609 52.0576L182.45 50.0137L179.712 51.5603L180.87 53.6042H180.863Z"
            fill="white"
          />
          <path
            d="M179.534 62.9924L189.284 59.1022L190.278 61.6025L182.518 64.6956L184.549 69.778L182.559 70.5752L179.534 62.9924Z"
            fill="white"
          />
          <path
            d="M185.503 81.7347L187.54 80.3381L186.456 75.7802L184.004 75.4532L183.356 72.7349L194.598 74.6084L195.34 77.7287L186.15 84.4599L185.503 81.7416V81.7347ZM188.391 76.0323L189.154 79.2412L191.743 77.4698C192.111 77.2178 192.69 76.8499 193.194 76.6046V76.5637C192.622 76.5774 191.934 76.4956 191.498 76.4479L188.391 76.0323Z"
            fill="white"
          />
          <path
            d="M186.531 88.5682L190.21 88.1867L196.54 83.2814L196.839 86.2109L193.051 88.9634C192.786 89.1541 192.595 89.2631 192.404 89.3244V89.3517C192.602 89.3789 192.82 89.4403 193.113 89.5765L197.384 91.5318L197.677 94.4001L190.476 90.8778L186.797 91.2593L186.518 88.5682H186.531Z"
            fill="white"
          />
          <path
            d="M189.44 103.598L190.599 105.703C189.393 106.241 188.5 107.365 188.316 109.068C188.166 110.451 188.459 111.33 189.284 111.419C190.006 111.494 190.333 111.071 190.646 109.947L191.185 107.835C191.77 105.744 192.861 104.715 194.686 104.913C196.655 105.124 197.67 107.004 197.35 109.961C197.112 112.148 196.233 113.837 194.7 114.655L193.446 112.781C194.543 112.264 195.272 111.133 195.443 109.593C195.579 108.346 195.259 107.563 194.571 107.488C193.91 107.42 193.555 107.808 193.235 108.993L192.683 111.044C192.084 113.286 191.096 114.28 189.25 114.076C187.131 113.844 185.986 111.909 186.334 108.735C186.613 106.132 187.812 104.286 189.447 103.598H189.44Z"
            fill="white"
          />
          <path
            d="M193.031 129.459L182.879 126.775L183.561 124.186L187.751 125.297L189.038 120.439L184.848 119.329L185.536 116.726L195.688 119.41L195 122.013L190.953 120.943L189.665 125.801L193.712 126.871L193.031 129.459Z"
            fill="white"
          />
          <path
            d="M186.715 131.789C189.808 133.172 190.817 136.197 189.345 139.488C187.867 142.792 184.944 144.046 181.858 142.663C178.765 141.28 177.75 138.269 179.228 134.964C180.7 131.674 183.643 130.413 186.715 131.789ZM182.996 140.115C184.951 140.987 186.675 140.428 187.472 138.65C188.262 136.885 187.526 135.23 185.571 134.351C183.629 133.486 181.892 134.038 181.102 135.802C180.304 137.58 181.04 139.236 182.996 140.115Z"
            fill="white"
          />
          <path
            d="M174.765 144.482L185.128 147.916L183.588 150.178L177.02 147.711C176.707 147.589 176.435 147.473 176.182 147.316L176.155 147.35C176.38 147.541 176.584 147.766 176.802 148.025L181.36 153.448L179.821 155.71L173.103 153.468C172.783 153.359 172.497 153.25 172.238 153.114L172.211 153.148C172.449 153.325 172.66 153.543 172.885 153.788L177.593 158.993L176.053 161.255L169.056 152.882L170.759 150.375L177.572 152.739C177.831 152.828 178.09 152.95 178.329 153.093L178.369 153.032C178.145 152.862 177.94 152.664 177.756 152.453L173.055 146.982L174.759 144.475L174.765 144.482Z"
            fill="white"
          />
          <path
            d="M160.404 162.434L164.757 163.558L166.263 162.012L163.47 159.286L165.343 157.358L172.865 164.682L168.893 168.763C166.842 170.868 165.016 170.623 163.735 169.376C162.843 168.511 162.407 167.196 163.136 165.738L158.333 164.566L160.404 162.434ZM169.663 165.323L167.537 163.252L165.847 164.989C164.941 165.922 165.078 166.733 165.691 167.333C166.283 167.912 167.06 168.007 167.98 167.06L169.669 165.323H169.663Z"
            fill="white"
          />
          <path
            d="M155.097 169.669L150.321 173.355L149.135 171.822L156.044 166.495L162.455 174.806L155.628 180.073L154.443 178.54L159.137 174.915L157.747 173.11L153.25 176.578L152.113 175.099L156.609 171.632L155.09 169.663L155.097 169.669Z"
            fill="white"
          />
          <path
            d="M144.925 176.707L139.692 179.705L138.732 178.022L146.301 173.689L151.52 182.798L144.039 187.083L143.078 185.4L148.222 182.45L147.091 180.475L142.165 183.295L141.239 181.681L146.165 178.86L144.931 176.707H144.925Z"
            fill="white"
          />
          <path
            d="M135.625 179.48L139.604 189.195L137.117 190.217L133.949 182.484L128.887 184.556L128.076 182.573L135.625 179.48Z"
            fill="white"
          />
          <path
            d="M107.719 192.377C107.828 195.702 105.573 198.025 101.983 198.148C98.9988 198.25 96.9413 196.839 96.4031 194.489L99.1351 193.91C99.4485 195.32 100.436 196.083 101.956 196.036C103.877 195.967 105.001 194.618 104.926 192.506C104.858 190.435 103.632 189.134 101.676 189.202C100.198 189.25 99.2645 190.054 98.9988 191.437L96.26 190.939C96.8323 188.63 98.7195 187.172 101.663 187.077C105.355 186.954 107.604 189.052 107.713 192.391L107.719 192.377Z"
            fill="white"
          />
          <path
            d="M93.1601 187.295L92.07 197.732L89.3925 197.453L90.2646 189.148L84.8279 188.582L85.0527 186.45L93.1669 187.301L93.1601 187.295Z"
            fill="white"
          />
          <path
            d="M79.4183 185.271L82.0549 185.823L79.9157 196.097L77.2791 195.545L79.4183 185.271Z"
            fill="white"
          />
          <path
            d="M74.6904 189.658C73.6412 192.813 70.7116 194.21 67.3052 193.079C64.471 192.138 63.0266 190.094 63.34 187.71L66.1061 188.112C65.9085 189.543 66.5694 190.605 68.0137 191.082C69.8396 191.689 71.3657 190.81 72.0265 188.8C72.6806 186.838 71.9856 185.182 70.1325 184.569C68.7291 184.099 67.5709 184.528 66.8419 185.734L64.4437 184.317C65.7859 182.348 68.0614 181.64 70.8547 182.566C74.3633 183.731 75.7396 186.484 74.6836 189.652L74.6904 189.658Z"
            fill="white"
          />
          <path
            d="M56.1182 176.455L56.7859 181.987L59.0955 181.245L60.4172 178.669L62.8085 179.902L58.0122 189.236L55.6209 188.01L57.7533 183.854L51.5944 185.939L48.7534 184.481L54.2991 182.641L53.3385 175.031L56.1114 176.455H56.1182Z"
            fill="white"
          />
          <path
            d="M32.3139 172.327L33.6561 170.691L36.4426 172.981L41.7635 166.501L43.8414 168.212L38.5205 174.691L41.307 176.98L39.9649 178.615L32.3071 172.327H32.3139Z"
            fill="white"
          />
          <path
            d="M33.935 166.753C31.4415 169.043 28.2667 168.77 25.8208 166.12C23.3682 163.456 23.3818 160.274 25.8753 157.985C28.3689 155.696 31.5369 155.955 33.9895 158.619C36.4286 161.276 36.4217 164.478 33.935 166.753ZM27.7625 160.036C26.1819 161.487 26.0116 163.286 27.3333 164.723C28.6414 166.147 30.4536 166.133 32.0342 164.682C33.6012 163.245 33.7851 161.433 32.4702 160.002C31.1485 158.564 29.3363 158.585 27.7625 160.036Z"
            fill="white"
          />
          <path
            d="M12.413 147.064C11.1799 144.911 11.5342 142.915 13.326 141.886C15.1995 140.81 17.1685 141.505 18.4152 143.685L19.88 146.24L23.0071 144.441L24.3493 146.778L15.2404 152.003L12.4062 147.064H12.413ZM17.1003 145.129C16.5212 144.114 15.6696 143.801 14.7976 144.298C13.9391 144.788 13.7756 145.667 14.3683 146.696L15.5402 148.733L18.2722 147.166L17.1003 145.129Z"
            fill="white"
          />
          <path
            d="M18.3676 135.727L8.6455 139.686L7.63037 137.192L15.3631 134.044L13.2987 128.976L15.2813 128.165L18.3539 135.72L18.3676 135.727Z"
            fill="white"
          />
          <path
            d="M12.2767 117.026L10.2465 118.436L11.3638 122.987L13.8164 123.301L14.4773 126.012L3.23595 124.214L2.4729 121.1L11.6159 114.314L12.2767 117.026ZM9.42891 122.742L8.64542 119.54L6.07013 121.325C5.70904 121.584 5.12994 121.952 4.62579 122.197L4.63941 122.238C5.19807 122.224 5.88618 122.299 6.32221 122.34L9.42891 122.735V122.742Z"
            fill="white"
          />
          <path
            d="M11.1937 110.199L7.5215 110.601L1.21954 115.547L0.89933 112.625L4.6737 109.845C4.93259 109.654 5.13016 109.545 5.31411 109.477V109.45C5.11654 109.429 4.89852 109.361 4.60557 109.232L0.320231 107.304L0.00683594 104.436L7.22855 107.91L10.9007 107.508L11.1937 110.199Z"
            fill="white"
          />
          <path
            d="M8.18938 95.204L7.01755 93.1125C8.21663 92.5674 9.10231 91.4365 9.27945 89.7264C9.42252 88.3434 9.12275 87.4645 8.29839 87.3828C7.57622 87.3078 7.24919 87.7371 6.94261 88.868L6.41802 90.9868C5.85254 93.0852 4.76247 94.1208 2.94342 93.9368C0.974483 93.7393 -0.0542707 91.8657 0.238685 88.9089C0.463512 86.7151 1.32875 85.0187 2.85485 84.1943L4.12206 86.0611C3.03199 86.5925 2.303 87.7234 2.14631 89.2632C2.01686 90.5167 2.34388 91.2866 3.03199 91.3615C3.69284 91.4297 4.04712 91.0345 4.36051 89.8491L4.89192 87.7916C5.47783 85.5501 6.45208 84.5486 8.3052 84.7326C10.424 84.9438 11.5822 86.8786 11.262 90.0535C10.9963 92.656 9.81767 94.5159 8.18257 95.2108L8.18938 95.204Z"
            fill="white"
          />
          <path
            d="M4.42825 69.3557L14.5931 71.9787L13.9255 74.5676L9.72871 73.4843L8.47513 78.3488L12.6719 79.432L11.9974 82.0346L1.83252 79.4116L2.507 76.8091L6.5607 77.8514L7.81428 72.987L3.76058 71.9446L4.42825 69.3557Z"
            fill="white"
          />
          <path
            d="M10.7167 66.9848C7.61684 65.6222 6.58809 62.6041 8.03243 59.3066C9.48359 55.9887 12.3995 54.7215 15.4994 56.0773C18.5993 57.4399 19.6349 60.4444 18.1837 63.7555C16.7325 67.0597 13.803 68.3406 10.7167 66.9848ZM14.3821 58.6321C12.42 57.7737 10.7031 58.3392 9.91962 60.131C9.14294 61.9023 9.88555 63.5511 11.8477 64.4163C13.7962 65.2679 15.5267 64.7093 16.3033 62.9311C17.0868 61.1461 16.3442 59.4974 14.3821 58.6321Z"
            fill="white"
          />
          <path
            d="M22.5851 54.2039L12.2021 50.8383L13.7282 48.5696L20.3095 50.9881C20.6229 51.1108 20.9023 51.2266 21.1543 51.3765L21.1816 51.3424C20.95 51.1517 20.7456 50.9268 20.5276 50.6748L15.9356 45.2857L17.4617 43.017L24.1929 45.2176C24.5131 45.3266 24.7993 45.4288 25.0582 45.5719L25.0854 45.5378C24.847 45.3607 24.6358 45.1495 24.4041 44.9042L19.6691 39.7332L21.1952 37.4645L28.2466 45.7899L26.557 48.3039L19.7304 45.9875C19.4715 45.8989 19.2127 45.7831 18.9674 45.64L18.9265 45.7013C19.1513 45.8716 19.3625 46.0624 19.5397 46.2736L24.2747 51.7103L22.5851 54.2243V54.2039Z"
            fill="white"
          />
          <path
            d="M36.8102 36.1631L32.4499 35.0731L30.9578 36.6332L33.7716 39.338L31.9048 41.2796L24.3289 34.0102L28.2735 29.902C30.3106 27.7764 32.1365 28.0149 33.4241 29.2548C34.3234 30.1133 34.7663 31.4213 34.0509 32.8929L38.8608 34.0307L36.8033 36.1768L36.8102 36.1631ZM27.5309 33.3426L29.677 35.4001L31.353 33.6492C32.2523 32.709 32.1092 31.8982 31.4961 31.3055C30.8965 30.7332 30.1199 30.6447 29.2137 31.5917L27.5377 33.3426H27.5309Z"
            fill="white"
          />
          <path
            d="M42.0837 28.8869L46.8323 25.1671L48.0245 26.6932L41.1571 32.0754L34.6848 23.8113L41.4705 18.4972L42.6628 20.0233L37.9959 23.6818L39.3994 25.4736L43.8686 21.9718L45.02 23.4366L40.5508 26.9384L42.0837 28.8937V28.8869Z"
            fill="white"
          />
          <path
            d="M52.2005 21.781L57.4124 18.7424L58.3866 20.4184L50.8515 24.8128L45.5647 15.7447L53.0112 11.4049L53.9855 13.0809L48.8622 16.065L50.0067 18.0339L54.912 15.1725L55.8522 16.7803L50.9469 19.6417L52.1937 21.7878L52.2005 21.781Z"
            fill="white"
          />
          <path
            d="M61.48 18.9468L57.4331 9.26567L59.913 8.2301L63.1287 15.9355L68.1771 13.8303L69.0015 15.8061L61.4732 18.9537L61.48 18.9468Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1608_195"
            x1="99.1711"
            y1="91.7538"
            x2="86.6711"
            y2="112.254"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFCDE4" />
            <stop offset="1" stopColor="#FDFDFD" />
          </linearGradient>
          <clipPath id="clip0_1608_195">
            <rect width="197.691" height="198.147" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  )
}

export default HeroPlayButton
