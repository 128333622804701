import React from 'react'
import ServicesBase from '../components/services/services'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'

export const Services = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.title,
    services: slice.items,
  }

  return <ServicesBase data={data} />
}

export const query = graphql`
  fragment ContentPageDataBodyServices on PrismicContentPageDataBodyServices {
    primary {
      title
    }
    items {
      title
      text {
        ...RichText
      }
      image {
        ...Image
      }
    }
  }
`
