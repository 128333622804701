import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { linkResolver } from '../../utils/linkResolver'
import Article from '../article'
import PinkArrowTitle from '../blogs-resources-events/pink-arrow-title'
import BlackArrowTitle from './../black-arrow-title'

const LatestWork = ({ works = [] }) => {
  const [newWork, setNewWork] = useState(works)

  // If the array of works doesn't contain a featured item, add a featured item
  useEffect(() => {
    setNewWork(
      works
        .map((article, index) => {
          if (article.data) {
            article.data.featured = false
            if (index == 2) {
              article.data.featured = true
            }
            return article
          }
        })
        .reverse(),
    )
  }, [works])

  if (newWork.length === 0) {
    return null
  }

  return (
    <div className="blog__articles our-work__articles latest-articles">
      <div className="our-work__articles-title">
        <BlackArrowTitle
          text="More Projects"
          level={2}
          alignment="left"
          backgroundColor="black"
        />
      </div>
      {newWork?.map((article, index) => (
        <Article key={`article--${index}`} article={article} />
      ))}
      <Link
        to={linkResolver({ type: 'our_wo' })}
        className="blog__articles-link second "
      >
        <PinkArrowTitle
          text={'SEE ALL WORK'}
          level={5}
          alignment="right"
          className="link"
          backgroundColor="black"
        />
      </Link>
    </div>
  )
}

export default LatestWork
