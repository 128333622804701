import React from 'react'
import ValuesBase from '../components/careers/values'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image, RichText } from '@superrb/gatsby-addons/types'

export const Values = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.values_title,
    values: slice.items.map(
      (item: {
        value_image: Image
        value_title: string
        value_description: RichText
        value_background: string
      }) => ({
        value_image: item.value_image,
        value_title: item.value_title,
        value_description: item.value_description,
        value_background: item.value_background,
      }),
    ),
  }

  return <ValuesBase data={data} />
}

export const query = graphql`
  fragment ContentPageDataBodyValues on PrismicContentPageDataBodyValues {
    primary {
      values_title
    }
    items {
      value_title
      value_description {
        ...RichText
      }
      value_background
      value_image {
        ...Image
      }
    }
  }
`
