import { Image } from '@superrb/gatsby-addons/components'
import { animator } from '@superrb/gatsby-addons/utils'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { HeaderVariantContext } from '../../context/header-variant-context-provider'
import HeroType from '../../types/hero'
import RectangleButton from '../buttons/rectangle-button'
import Animation from './animation'

/**
 * @param {ReactNode} children
 * @param {string[]} colors
 * @param {HeroType} hero
 */
const Hero = ({
  children,
  backgroundStyle,
  colors,
  data: { title, subtitle, buttonLabel },
  onButtonClick,
  backgroundImage = undefined,
  hideDate = false,
  className = '',
}) => {
  const element = useRef(null)
  const [textColor, setTextColor] = useState('white')
  const [fragmentedTitle, setFragmentedTitle] = useState([])

  const { setHeaderVariant } = useContext(HeaderVariantContext)

  useEffect(() => {
    setFragmentedTitle(title.split(' '))
  }, [title])

  useEffect(() => {
    const light = ['pink', 'light-purple'].includes(backgroundStyle)
    setTextColor(light ? 'black' : 'white')
    setHeaderVariant(light ? 'dark' : 'light')
  }, [backgroundStyle, setTextColor, setHeaderVariant])

  const handleClick = useCallback(() => {
    if (onButtonClick) {
      return onButtonClick()
    }

    if (element.current && element.current.nextElementSibling) {
      window.scrollBy({
        left: 0,
        top: element.current.nextElementSibling.getBoundingClientRect().top,
        behavior: 'smooth',
      })
    }
  }, [element, onButtonClick])

  return (
    <section
      className={`hero hero--background-${backgroundStyle} hero--text-${textColor} ${className}`}
      ref={element}
    >
      {backgroundImage ? (
        <Image image={backgroundImage} className="hero__image" />
      ) : (
        <Animation backgroundStyle={backgroundStyle} colors={colors} />
      )}

      <div className="hero__container">
        <div className="hero__content" ref={animator}>
          {subtitle && !hideDate && (
            <span className="hero__subtitle">
              <span className="hero__subtitle-text">{subtitle}</span>
            </span>
          )}

          <h1 className="hero__title">
            {fragmentedTitle.map((line, index) => (
              <span className="hero__title-text" key={index}>
                <span className="hero__title-fragment">{line}</span>
              </span>
            ))}
          </h1>
        </div>

        {buttonLabel && (
          <RectangleButton
            styles={[`transparent-${textColor}-faded`, `small`]}
            onClick={handleClick}
            text={buttonLabel}
          />
        )}
      </div>

      {children}
    </section>
  )
}

export default Hero
