import { Image } from '@superrb/gatsby-addons/components'
import React, { useEffect, useState } from 'react'
import { A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

const FeaturedLogo = ({ items, setCurrentIndex, currentIndex }) => {
  const [slider, setSliderWork] = useState(null)

  useEffect(() => {
    if (slider) {
      slider.slideTo(currentIndex)
    }
  }, [slider, currentIndex])

  return (
    <div className=" featured-work__logo">
      <Swiper
        modules={[A11y]}
        slidesPerView={1}
        onInit={swiper => setSliderWork(swiper)}
        onSlideChange={swiper => {
          setCurrentIndex(swiper.realIndex)
        }}
        speed={1000}
      >
        {items.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Image
                image={item?.work?.document?.data?.work_logo}
                className="objFit"
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default FeaturedLogo
