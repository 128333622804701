import {graphql, Script, useStaticQuery} from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import React from 'react'
import { Helmet } from 'react-helmet'
import { CookieBanner } from './cookies'
import Footer from './footer/footer'
import Header from './header'
import Newsletter from './newsletter'
import { SkipTo } from './skip-to'

export const Layout = ({ children }) => {
  const staticData = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const data = useMergePrismicPreviewData(staticData)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
      </Helmet>
      <SkipTo />
      <Script id="sopro-embed">{`
       (function (o, u, t, b, a, s, e) {      
       window[b] = window[b] || {}; (e = e || [])['key'] = o; e['__obr'] = u.location.href; a = [];
       u.location.search.replace('?', '').split('&').forEach(function (q) { if (q.startsWith(b) || q.startsWith('_obid')) e[q.split('=')[0]] = q.split('=')[1]; });
       e['_obid'] = e['_obid'] || (u.cookie.match(/(^|;)\\s*_obid\\s*=\\s*([^;]+)/) || []).pop() || 0;
       for (k in e) { if (e.hasOwnProperty(k)) a.push(encodeURIComponent(k) + '=' + encodeURIComponent(e[k])); }
       s = u.createElement('script'); s.src = t + '?' + a.join('&'); u.body.appendChild(s);
       })('7bd3a2f6-bbda-408e-bd68-d3228a4ca33d', document, 'https://plugin.sopro.io/hq.js', 'outbase')
      `}</Script>
      <Header />
      <main>{children}</main>
      <CookieBanner />
      <Newsletter />
      <Footer siteTitle={data.site.siteMetadata?.title || `Title`} />
    </>
  )
}
