import React from 'react'
import MapBase from '../components/global-map'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'

export const Map = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.title,
    text: slice.primary.map_text,
    button_text: slice.primary.button_text,
    button_link: slice.primary.button_link,
  }

  return <MapBase data={data} />
}

export const query = graphql`
  fragment ContentPageDataBodyMap on PrismicContentPageDataBodyMap {
    primary {
      title
      map_text: text {
        ...RichText
      }
      button_text
      button_link {
        ...Link
      }
    }
  }
`
