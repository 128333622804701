/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 */
exports.linkResolver = doc => {
  if (doc.url) {
    return doc.url
  }

  switch (doc.type) {
    case 'content_page':
      return `/${doc.uid}/`

    case 'about_us':
      return '/about-us/'

    case 'akero':
      return '/akero/'

    case 'blog_detail':
      return `/blog/${doc.uid}/`

    case 'blogs':
      return `/blog/`

    case 'blogs_resources__events':
      return '/blogs-resources-events/'

    case 'contact':
      return '/contact/'

    case 'event':
      return `/events/${doc.uid}/`

    case 'events':
      return `/events/`

    case 'landing_page':
      return `/${doc.uid}/`

    case 'legal_page':
      return `/legal/${doc.uid}/`

    case 'news_article':
      return `/blog/${doc.uid}/`

    case 'our_wo':
      return '/our-work/'

    case 'resource':
      return `/resources/${doc.uid}/`

    case 'resources':
      return `/resources/`

    case 'work':
      return `/work/${doc.uid}/`

    default:
      return '/'
  }
}
