import React, { useCallback, useContext, useEffect, useRef } from 'react'
import Twitter from './../icons/twitter'
import LinkedIn from './../icons/linked-in'
import Instagram from '../icons/instagram'
import Link from 'gatsby'

const HeaderSocials = ({ config }) => {
  return (
    <div className="main-header-socials">
      <div className="main-header-socials__inner">
        <p>Connect with us</p>
        <div className="main-header-socials-links">
          <ul className="main-header-socials-links-list">
            {config.data.instagram_username && (
              <a
                href={`https://instagram.com/${config.data.instagram_username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram />
              </a>
            )}
            {config.data.linkedin_username && (
              <a
                href={`https://linkedin.com/${config.data.linkedin_username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn />
              </a>
            )}
            {config.data.twitter_username && (
              <a
                href={`https://twitter.com/${config.data.twitter_username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter />
              </a>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default HeaderSocials
