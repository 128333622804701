import React from 'react'
import PinkArrow from './pink-arrow'

const elements = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
}

const PinkArrowTitle = ({
  text,
  level,
  className,
  alignment,
  backgroundColor,
}) => {
  const body = (
    <>
      <span className="black-arrow-title__text">{text}</span>
      <PinkArrow className="black-arrow-title__icon" />
    </>
  )

  return React.createElement(
    elements[level],
    {
      className: `black-arrow-title ${backgroundColor ? backgroundColor : ''} ${
        className ? className : ''
      } ${alignment == 'right' ? 'black-arrow-title--right' : ''}`,
    },
    body,
  )
}

export default PinkArrowTitle
