import { Image } from '@superrb/gatsby-addons/components'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import React from 'react'
import BlackArrowTitle from './../black-arrow-title'

const Values = ({ data: { title, values } }) => {
  return (
    <section className="values">
      <div className="values__title">
        <BlackArrowTitle level={2} text={title} />
      </div>
      <div className="values__inner">
        {values &&
          values.map((value, index) => {
            return (
              <div
                className={`values__item ${
                  value.value_background
                    ? value.value_background.replace(' ', '_').toLowerCase()
                    : ''
                }`}
                key={index}
              >
                <div className="values__item-icon">
                  <Image
                    image={value?.value_image}
                    alt={value?.value_image.alt}
                    className="objFit"
                  />
                </div>
                <h5 className="values__item-title">{value?.value_title}</h5>
                <RichText field={value?.value_description?.richText} />
              </div>
            )
          })}
      </div>
    </section>
  )
}

export default Values
