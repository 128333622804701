import React from 'react'

const LinkedIn = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6705 0.401855H4.68484C2.35753 0.401855 0.4375 2.32188 0.4375 4.64919V16.693C0.4375 19.0203 2.35753 20.9403 4.68484 20.9403H16.7286C19.056 20.9403 20.976 19.0203 20.976 16.693V4.64919C20.976 2.32188 19.056 0.401855 16.7286 0.401855H16.6705ZM7.24487 16.6348H4.68484V7.20923H7.24487V16.693V16.6348ZM5.96485 6.16194C5.56365 6.16194 5.17887 6.00256 4.89518 5.71887C4.61148 5.43517 4.4521 5.0504 4.4521 4.64919C4.4521 4.24798 4.61148 3.86321 4.89518 3.57952C5.17887 3.29582 5.56365 3.13644 5.96485 3.13644C6.83759 3.13644 7.4776 3.83463 7.4776 4.64919C7.4776 5.46375 6.83759 6.16194 5.96485 6.16194ZM17.485 16.6348H14.9832V11.8638C14.9832 8.95471 11.6086 9.18744 11.6086 11.8638V16.6348H9.04854V7.20923H11.4922V8.72198C12.6559 6.51104 17.4268 6.39467 17.4268 10.8747V16.6348H17.485Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default LinkedIn
