import React, { createContext, useCallback, useEffect, useState } from 'react'
import Cookies from 'js-cookie'

export const CookiesContext = createContext({
  cookiesAccepted: false,
  setCookiesAccepted: (cookiesAccepted: boolean) => {},
})

export const CookiesContextProvider = ({ children }) => {
  const [cookiesAccepted, setCookiesAcceptedStorage] = useState<boolean>(false)

  useEffect(() => {
    const accepted = Cookies.get('gatsby-accepted-cookies') || false
    setCookiesAcceptedStorage(!!accepted)
  }, [setCookiesAcceptedStorage])

  const setCookiesAccepted = useCallback(
    (accepted: boolean) => {
      Cookies.set('gatsby-gdpr-google-analytics', accepted.toString(), {
        expires: 30,
      })
      Cookies.set('gatsby-gdpr-google-tagmanager', accepted.toString(), {
        expires: 30,
      })
      Cookies.set('gatsby-gdpr-facebook-pixel', accepted.toString(), {
        expires: 30,
      })
      Cookies.set('gatsby-gdpr-tiktok-pixel', accepted.toString(), {
        expires: 30,
      })
      Cookies.set('gatsby-gdpr-hotjar', accepted.toString(), {
        expires: 30,
      })
      Cookies.set('gatsby-gdpr-linked-in', accepted.toString(), {
        expires: 30,
      })
      Cookies.set('gatsby-accepted-cookies', accepted.toString(), {
        expires: 30,
      })
      setCookiesAcceptedStorage(accepted)
    },
    [setCookiesAcceptedStorage],
  )

  return (
    <CookiesContext.Provider
      value={{
        cookiesAccepted,
        setCookiesAccepted,
      }}
    >
      {children}
    </CookiesContext.Provider>
  )
}

export default CookiesContextProvider
