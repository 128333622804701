import { Seo } from '@superrb/gatsby-addons/components'
import { PageProps, graphql } from 'gatsby'
import React, { useContext } from 'react'
import Hero from '../components/hero/hero'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'
import { SliceZone } from '@prismicio/react'
import { components } from '../slices'
import ContentPageType from '../types/pages/content-page'
import { Metadata } from '@superrb/gatsby-addons/types'
import RichText from '../components/rich-text'

interface Props {
  page: ContentPageType
}

const ContentPage = ({ data }: PageProps<Props>) => {
  const page = data?.page || data?.prismicContentPage
  if (!page) {
    return null
  }

  const hero = {
    hero_title: page.data.hero_title,
    hero_button_text: page.data.hero_button_text,
    hero_subtitle: page.data.hero_subtitle,
    hero_background_color: page.data.hero_background_color || 'blue',
  }

  return (
    <Layout>
      <Seo data={page.data as Metadata} />
      {hero && (
        <Hero
          data={{
            title: hero.hero_title,
            subtitle: hero.hero_subtitle,
            buttonLabel: hero.hero_button_text,
          }}
          backgroundStyle={hero.hero_background_color}
        />
      )}

      <SliceZone slices={page.data.body} components={components} />
    </Layout>
  )
}

export const query = graphql`
  query ContentPageQuery($id: String!) {
    page: prismicContentPage(id: { eq: $id }) {
      ...ContentPage
    }
  }
`

export default withPrismicPreview(ContentPage)
