import React from 'react'
import EmploymentBenefitsBase from '../components/careers/employment-benefits'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image, RichText } from '@superrb/gatsby-addons/types'

export const EmploymentBenefits = ({
  slice,
}: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.employment_benefits_title,
    text: slice.primary.employment_benefits_text,
    items: slice.items.map(
      (item: {
        employment_benefit_image: Image
        employment_benefit_title: string
        employment_benefit_text: RichText
      }) => ({
        employment_benefit_image: item.employment_benefit_image,
        employment_benefit_title: item.employment_benefit_title,
        employment_benefit_text: item.employment_benefit_text,
      }),
    ),
  }

  return <EmploymentBenefitsBase data={data} />
}

export const query = graphql`
  fragment ContentPageDataBodyEmploymentBenefits on PrismicContentPageDataBodyEmploymentBenefits {
    primary {
      employment_benefits_title
      employment_benefits_text {
        ...RichText
      }
    }
    items {
      employment_benefit_title
      employment_benefit_text {
        ...RichText
      }
      employment_benefit_image {
        ...Image
      }
    }
  }
`
