const colors = {
  black: '#000000',
  white: '#FFFFFF',
  offWhite: '#FAFAFA',
  palePink: '#FFF2F8',
  pastelPink: '#FFCDE4',
  hotPink: '#FF80B1',
  pastelPurple: '#DDCCFC',
  purple: '#7633F4',
  navy: '#0F1449',
  darkBlue: '#06081C',
}

export default colors
