import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import React from 'react'
import { A11y, Pagination } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import Animation from './hero/animation'

const StatisticsSlider = ({ data, animationColor = 'purple' }) => {
  const isMobile = useIsMobile()

  if (data.length === 0) {
    return null
  }

  return (
    <section className="statistics-slider">
      {data && (
        <div className="statistics-slider__container container--flex">
          {data.length === 1 && <Animation backgroundStyle={animationColor} />}
          <Swiper
            modules={[Pagination, A11y]}
            pagination={
              data.length > 1
                ? {
                    clickable: true,
                    el: '.statistics-slider__pagination',
                  }
                : false
            }
            slidesPerView={isMobile ? 1 : 3}
            speed={1000}
          >
            {data.map((statistic, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className={`statistics-slider__item statistics`}
                >
                  <h1>{statistic.statistic}</h1>
                  <h5>{statistic.title}</h5>
                </SwiperSlide>
              )
            })}
          </Swiper>
          {data.length > 1 && (
            <div
              className="statistics-slider__pagination"
              aria-hidden={!isMobile && data.length <= 3}
            ></div>
          )}
        </div>
      )}
    </section>
  )
}

export default StatisticsSlider
