import { Link } from 'gatsby'
import React from 'react'
import { linkResolver } from '../../utils/linkResolver'

const Navigation = ({ navItems }) => {
  return (
    <nav className="footer-nav">
      <ul className="footer-nav__list">
        {navItems.map((navItem, index) => {
          if (navItem?.link?.url && navItem.label) {
            return (
              <li className="footer-nav__list-item" key={index}>
                <Link
                  to={linkResolver(navItem.link)}
                  className="footer-nav__link"
                >
                  {navItem.label}
                </Link>
              </li>
            )
          }
        })}
      </ul>
    </nav>
  )
}

export default Navigation
