import React from 'react'

const TestimonialQuote = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1242 0V7.8758H16.6247C16.6247 10.3611 14.6095 12.3763 12.1242 12.3763V15.7516C16.4744 15.7516 20 12.2257 20 7.87584V3.73587e-05L12.1242 0ZM0.873047 7.8758H5.37351C5.37351 10.3611 3.35839 12.3763 0.873047 12.3763V15.7516C5.22324 15.7516 8.74885 12.2257 8.74885 7.87584V3.73587e-05H0.873047V7.8758Z"
        fill="#06081C"
      />
    </svg>
  )
}

export default TestimonialQuote
