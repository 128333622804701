import React from 'react'
import BenefitsSliderBase from '../components/benefits-slider'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image, RichText } from '@superrb/gatsby-addons/types'

export const BenefitsSlider = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.title,
    items: slice.items.map(
      (item: {
        year: string
        description: RichText
        background_color: string
        image: Image
      }) => ({
        story_year: item.year,
        story_description: item.description,
        story_background_color: item.background_color,
        story_image: item.image,
      }),
    ),
  }

  return <BenefitsSliderBase data={data} />
}

export const query = graphql`
  fragment ContentPageDataBodyBenefitsSlider on PrismicContentPageDataBodyBenefitsSlider {
    primary {
      title
    }
    items {
      year
      description {
        ...RichText
      }
      background_color
      image {
        ...Image
      }
    }
  }
`
