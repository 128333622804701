import { Seo } from '@superrb/gatsby-addons/components'
import { graphql } from 'gatsby'
import React, { useCallback, useContext } from 'react'
import ContactForm from '../components/contact-form'
import Offices from '../components/contact/offices'
import Hero from '../components/hero/hero'
import TickerTape from '../components/ticker-tape'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import ContactPage from '../types/pages/contact'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'

const Contact = ({ data }) => {
  const { setHeaderVariant } = useContext(HeaderVariantContext)
  setHeaderVariant('dark')
  /**
   * @type {ContactPage} page
   */
  const page = data?.page

  const handleButtonClick = useCallback(() => {
    const element = document.getElementById('contact-form')

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [])

  if (!page) {
    return null
  }

  return (
    <Layout>
      <Hero
        backgroundStyle="pink"
        colors={['#FF80B1', '#FFCDE4']}
        data={{
          title: page.data.hero_title,
          subtitle: page.data.hero_subtitle,
          buttonLabel: page.data.hero_button_text,
        }}
        onButtonClick={handleButtonClick}
      />
      <Seo data={page.data} />
      <TickerTape text={page.data.ticker_tape} background="black" />
      <Offices />
      <ContactForm />
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery {
    page: prismicContact {
      ...ContactPage
    }
  }
`

export default withPrismicPreview(Contact)
