import React from 'react'
import BlogNewsletter from '../blog-newsletter'
import BlogResource from './blog-resource'

const BlogLatestResources = ({ articles = [], showNewsletter }) => {
  return (
    <div className="blog__articles blog__article-row our-work__articles">
      {articles &&
        articles?.map((article, index) => {
          return (
            <BlogResource
              key={`article--${index}`}
              resource={article}
              showNewsletter={showNewsletter}
              index={index}
            />
          )
        })}
      {showNewsletter && articles.length > 2 && <BlogNewsletter />}
    </div>
  )
}

export default BlogLatestResources
