import React from 'react'
import TeamMemberCard from './team-member-card'
import BlackArrowTitle from '../black-arrow-title'

const TeamMembers = ({ data, title }) => {
  return (
    <section className="team-members">
      <div className="team-members__container container">
        {data.title && <BlackArrowTitle level={2} text={data.title} />}

        <div className="team-members__list">
          {data.team_members &&
            data.team_members.map((teamMember, index) => {
              return (
                <TeamMemberCard data={teamMember} key={index} title={title} />
              )
            })}
        </div>
      </div>
    </section>
  )
}

export default TeamMembers
