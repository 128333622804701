import React from 'react'
import ClientLogosBase from '../components/client-logos'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import ClientLogo from '../types/client-logo'

export const ClientLogos = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.title,
    client_logos: slice.items.map(
      ({ logo }: { logo: { document: ClientLogo } }) => logo.document,
    ),
  }

  return <ClientLogosBase data={data} />
}

export const query = graphql`
  fragment ContentPageDataBodyClientLogos on PrismicContentPageDataBodyClientLogos {
    primary {
      title
    }
    items {
      logo {
        document {
          ... on PrismicClientLogo {
            ...ClientLogo
          }
        }
      }
    }
  }
`
