import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import BlackArrowTitle from '../black-arrow-title'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import RectangleButton from '../buttons/rectangle-button'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperInstance } from 'swiper'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'

const Services = ({ data: { title, services } }) => {
  const [current, setCurrent] = useState<number>(0)
  const swiper = useRef<SwiperInstance>(
    null,
  ) as MutableRefObject<SwiperInstance>
  const isMobile = useIsMobile(false)

  useEffect(() => {
    if (swiper.current) {
      swiper.current.slideTo(current)
    }
  }, [current])

  return (
    <section className="services" aria-role="title">
      <BlackArrowTitle text={title} level={2} className="services__title" />

      <nav className="services__pagination">
        <span className="services__pagination-label">Services:</span>
        <ul className="services__pagination-items">
          {services.map(({ title, text, image }, index) => (
            <li className="services__pagination-item" key={index}>
              <button
                className="work-filter__item-button services__pagination-item-button"
                onClick={() => setCurrent(index)}
                aria-selected={index === current}
              >
                {title}
              </button>
            </li>
          ))}
        </ul>
      </nav>

      <div className="services__content">
        <Swiper
          className="services__content-swiper"
          onSwiper={(swiperInstance: SwiperInstance) =>
            (swiper.current = swiperInstance)
          }
          onSlideChange={(swiperInstance: SwiperInstance) =>
            setCurrent(swiperInstance.activeIndex)
          }
          spaceBetween={isMobile ? 0 : 50}
        >
          {services.map(({ title, text, image }, index) => (
            <SwiperSlide className="services__content-swiper-slide" key={index}>
              <Image
                image={image}
                className="services__content-swiper-slide-image"
              />
              <div className="services__content-swiper-slide-content">
                <h3 className="services__content-swiper-slide-title">
                  {title}
                </h3>
                <RichText
                  className="services__content-swiper-slide-text"
                  field={text.richText}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}

export default Services
