import { useLockBodyScroll } from '@superrb/gatsby-addons/hooks'
import React, { createContext, useCallback, useState } from 'react'

export const NewsletterContext = createContext({
  newsletterPopupOpen: false,
  toggleNewsletterPopup: () => {},
  closeNewsletterPopup: () => {},
  openNewsletterPopup: () => {},
})

export const NewsletterContextProvider = ({ children }) => {
  const [newsletterPopupOpen, setNewsletterPopupOpen] = useState(false)
  useLockBodyScroll(newsletterPopupOpen)

  const toggleNewsletterPopup = useCallback(() => {
    setNewsletterPopupOpen(newsletterPopupOpen => !newsletterPopupOpen)
  }, [setNewsletterPopupOpen])

  const openNewsletterPopup = useCallback(() => {
    setNewsletterPopupOpen(true)
  }, [setNewsletterPopupOpen])

  const closeNewsletterPopup = useCallback(() => {
    setNewsletterPopupOpen(false)
  }, [setNewsletterPopupOpen])

  return (
    <NewsletterContext.Provider
      value={{
        newsletterPopupOpen,
        toggleNewsletterPopup,
        closeNewsletterPopup,
        openNewsletterPopup,
      }}
    >
      {children}
    </NewsletterContext.Provider>
  )
}

export default NewsletterContextProvider
