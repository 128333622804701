import { Image } from '@superrb/gatsby-addons/components'
import { ImageLayout } from '@superrb/gatsby-addons/types'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import React from 'react'

const TwoColImg = ({ image, text }) => {
  return (
    <section className="work-detail__two-col-img">
      <div className="work-detail__two-col-img__text">
        {text && (
          <div className="work-detail__two-col-img__text-inner">
            <RichText field={text?.richText} />
          </div>
        )}
      </div>
      {image && (
        <div className="work-detail__two-col-img__image">
          <Image
            image={image}
            alt={image.alt}
            layout={ImageLayout.cover}
            className="work-detail__two-col-img__image-wrapper"
          />
        </div>
      )}
    </section>
  )
}

export default TwoColImg
