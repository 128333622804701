import React from 'react'

const IconAkero = () => (
  <>
    <svg
      width="223"
      height="65"
      viewBox="0 0 223 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="akero-logo"
    >
      <path
        d="M89.7225 63.0983L73.9772 42.0445V63.0983H61.4731V0H74.0043V39.5256L89.7496 19.5642H104.556L87.637 40.3291L105.107 63.1163H89.7586L89.7225 63.0983Z"
        fill="currentColor"
      />
      <path
        d="M124.444 18.4536C112.382 18.4536 102.668 27.6534 102.668 41.4756C102.668 54.6839 111.443 64.1906 126.719 64.1906C132.014 64.2517 137.229 62.8957 141.823 60.2633V49.9712C137.819 52.6839 133.117 54.1853 128.281 54.2957C121.708 54.2957 116.301 51.0726 114.648 44.8612H144.189C144.418 43.4587 144.524 42.0389 144.505 40.6179C144.532 26.0825 135.504 18.4536 124.444 18.4536ZM114.423 37.3136C115.596 31.8154 119.668 28.3576 124.354 28.3576C129.039 28.3576 132.479 31.6529 132.578 37.3136H114.423Z"
        fill="currentColor"
      />
      <path
        d="M199.532 18.4536C186.757 18.4536 176.735 27.7256 176.735 41.3311C176.735 54.9367 186.757 64.1996 199.532 64.1996C212.451 64.1996 222.328 54.7651 222.328 41.3311C222.319 27.7256 212.451 18.4536 199.532 18.4536ZM199.532 52.8782C194.358 52.8782 189.42 48.7162 189.42 41.3311C189.42 33.946 194.358 29.775 199.532 29.775C204.705 29.775 209.706 33.937 209.706 41.3311C209.706 48.7253 204.696 52.8782 199.532 52.8782Z"
        fill="currentColor"
      />
      <path
        d="M181.24 21.171C178.803 19.3654 173.918 19.0855 172.682 19.0855C170.275 19.0788 167.912 19.7263 165.844 20.9587C163.777 22.1911 162.084 23.9621 160.945 26.0824H160.782L159.518 19.555H149.587V63.0981H162.128V41.8005C162.128 33.6209 167.138 30.7951 172.384 30.7951C173.113 30.7975 173.841 30.8609 174.559 30.9847C175.976 27.2295 178.266 23.8655 181.24 21.171Z"
        fill="currentColor"
      />
      <path
        d="M13.3076 63.098H0L21.8573 4.47778H36.5824L58.7647 63.098H45.3488L40.1846 48.7973H25.4686L29.1522 38.2704H36.5734L29.2153 17.8396L13.3076 63.098Z"
        fill="currentColor"
      />
    </svg>
    <span className="screenreader-text">Akero</span>
  </>
)

export default IconAkero
