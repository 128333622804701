import React from 'react'
import PinkButtonArrow from './icons/pink-button-arrow'

const elements = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
}

const ArrowTitle = ({ text, level, className }) => {
  const body = (
    <>
      <PinkButtonArrow className="arrow-title__icon" />
      <span className="arrow-title__text">{text}</span>
    </>
  )

  return React.createElement(
    elements[level],
    { className: `arrow-title ${className}` },
    body,
  )
}

export default ArrowTitle
