import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import {
  Image,
  Link,
  RichText as RichTextType,
} from '@superrb/gatsby-addons/types'
import { RichText } from '../rich-text'
import React from 'react'
import Animation from '../hero/animation'
import colors from '../utils/colors'
import Video from '../video'
import { TikTokEmbed } from 'react-social-media-embed'
import IframeResizer from 'react-iframe-resizer-super'

const Content = ({
  intro,
  content,
  html,
  iframe,
  video,
  videoThumbnail,
  reverse = false,
  center = false,
}) => {
  /**
   * @type {RichTextType} intro
   * @type {RichTextType} content
   * @type {RichTextType} html
   * @type {Link} video
   * @type {Image} videoThumbnail
   * @type {boolean} reverse
   * @type {boolean} center
   */

  const isMobile = useIsMobile()

  return (
    <section
      className={`blog-content ${reverse ? 'blog-content--reverse' : ''} ${
        center ? 'blog-content--center' : ''
      }`}
    >
      <div
        className={`blog-content__wrapper ${
          intro?.text?.length > 1 && content?.text?.length > 1
            ? html?.text.length > 1
              ? 'content has-html'
              : 'content'
            : html?.text.length > 1 || iframe?.url
            ? 'content has-html'
            : 'no-content'
        }`}
      >
        {intro?.text?.length > 0 && (
          <div className="blog-content__intro">
            <RichText
              field={intro.richText}
              components={{
                embed: ({ node, key }) => {
                  const url = node.oembed.embed_url

                  if (url.includes('tiktok.com')) {
                    return <TikTokEmbed url={url} />
                  }

                  return (
                    <div
                      key={key}
                      data-oembed={node.oembed.embed_url}
                      data-oembed-type={node.oembed.type}
                      data-oembed-provider={node.oembed.provider_name}
                      dangerouslySetInnerHTML={{
                        __html: node.oembed.html ?? '',
                      }}
                    />
                  )
                },
              }}
            />
          </div>
        )}
        {content?.text?.length > 0 && (
          <div className="blog-content__content">
            <RichText
              field={content.richText}
              components={{
                embed: ({ node, key }) => {
                  const url = node.oembed.embed_url

                  if (url.includes('tiktok.com')) {
                    return <TikTokEmbed url={url} />
                  }

                  return (
                    <div
                      key={key}
                      data-oembed={node.oembed.embed_url}
                      data-oembed-type={node.oembed.type}
                      data-oembed-provider={node.oembed.provider_name}
                      dangerouslySetInnerHTML={{
                        __html: node.oembed.html ?? '',
                      }}
                    />
                  )
                },
              }}
            />
          </div>
        )}
        {html?.text && (
          <div
            className="blog-content__content"
            id="blog-content-html"
            dangerouslySetInnerHTML={{ __html: html.text }}
          />
        )}
        {iframe?.url && (
          <div className="blog-content__content" id="blog-content-html">
            <IframeResizer src={iframe?.url} />
          </div>
        )}
      </div>
      {!center && (
        <div className="blog-content__animation">
          {!isMobile && (
            <Animation
              backgroundStyle={reverse ? 'light-purple' : 'purple'}
              colors={[colors.hotPink, colors.navy]}
            />
          )}
        </div>
      )}

      {video?.url && (
        <div className="blog-content__video">
          <Video url={video.url} backgroundImage={videoThumbnail} />
        </div>
      )}
    </section>
  )
}

export default Content
