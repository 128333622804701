import { Image } from '@superrb/gatsby-addons/components'
import { Link } from 'gatsby'
import React from 'react'

const SubBrands = ({ brands }) => {
  return (
    <div className="sub-brands">
      <ul className="sub-brands__list">
        {brands.map((brand, index) => (
          <li className="sub-brands__list-item" key={index}>
            {brand.link?.url && brand.logo && (
              <Link
                to={brand.link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="sub-brands__link"
              >
                <Image
                  image={brand.logo}
                  alt={brand.logo.alt}
                  className="sub-brands__image"
                />
              </Link>
            )}
            {brand.logo && !brand?.link?.url && (
              <Image
                image={brand.logo}
                alt={brand.logo.alt}
                className="sub-brands__image"
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SubBrands
