import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import React from 'react'

const ClientLogo = ({ logo, ariaHidden }) => {
  const data = logo?.data
  const isMobile = useIsMobile()

  return (
    <div
      className={`client-logos__logo ${
        data?.background_color
          ? data?.background_color.replace(' ', '_').toLowerCase()
          : ''
      }`}
      aria-hidden={ariaHidden}
    >
      {data?.image && (
        <Image
          image={data?.image}
          alt={data?.image?.alt}
          sizes="(min-width: 63.75em) 50vw, 25vw"
          lazyLoad={false}
        />
      )}
    </div>
  )
}

export default ClientLogo
