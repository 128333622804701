/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */

import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews'

import { prismicRepo } from '../../prismic-configuration'
import { linkResolver } from './linkResolver'

import Index from '../pages'
import AboutUs from '../pages/about-us'
import Akero from '../pages/akero'
import BlogPost from '../templates/blog-post'
import Blog from '../pages/blog'
import BlogsResourcesEvents from '../pages/blogs-resources-events'
import Contact from '../pages/contact'
import ContentPage from '../templates/content-page'
import EventPost from '../templates/event-post'
import Events from '../pages/events'
import LandingPage from '../templates/landing-page'
import Legal from '../templates/legal'
import PostContent from '../templates/post-content'
import ResourcePost from '../templates/resource-post'
import Resources from '../pages/resources'
import OurWork from '../pages/our-work'
import WorkDetail from '../templates/work-detail'

// import Post from '../templates/post'
// import Posts from '../templates/posts'
/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: prismicRepo,
    linkResolver,
    componentResolver: {
      homepage: Index,
      about_us: AboutUs,
      akero: Akero,
      blog_detail: BlogPost,
      blogs: Blog,
      blogs_resources_events: BlogsResourcesEvents,
      contact: Contact,
      content_page: ContentPage,
      event: EventPost,
      events: Events,
      landing_page: LandingPage,
      legal_page: Legal,
      news_article: PostContent,
      our_wo: OurWork,
      resource: ResourcePost,
      resources: Resources,
      work: WorkDetail,
    },
  },
]
