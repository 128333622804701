import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { Link } from 'gatsby'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { LatestNewsContext } from '../../context/latest-news-context-provider'
import { linkResolver } from '../../utils/linkResolver'
import ButtonArrow from '../icons/button-arrow'
import { ImageLayout } from '@superrb/gatsby-addons/types'

const LatestNewsItem = ({ index, article }) => {
  const { currentImage, updateImage } = useContext(LatestNewsContext)
  const [transform, setTransform] = useState(0)
  const [transitionDuration, setTransitionDuration] = useState(0)
  const textContainer = useRef()
  const textInner = useRef()
  const isMobile = useIsMobile()

  const transformText = useCallback(() => {
    const containerWidth = textContainer.current.offsetWidth
    const innerWidth = textInner.current.offsetWidth
    const difference = innerWidth - containerWidth
    setTransform(difference)
    setTransitionDuration(difference * 10)
  }, [setTransform, setTransitionDuration])

  const resetText = useCallback(() => {
    setTransform(0)
    setTransitionDuration(0)
  }, [setTransform])

  const handleHover = useCallback(
    index => {
      updateImage(index)
      transformText()
    },
    [updateImage, transformText],
  )

  return (
    <li
      className="latest-news__article-list-item latest-news-article"
      key={index}
    >
      {article?.data?.image && article?.data?.page_title && (
        <Link
          onMouseEnter={() => handleHover(index)}
          onMouseLeave={() => resetText()}
          to={linkResolver(article)}
          className="latest-news-article__link"
        >
          {article?.data?.image && (
            <div
              className={`latest-news-article__image-wrapper${
                index == currentImage
                  ? ' latest-news-article__image-wrapper--active'
                  : ''
              }`}
            >
              <Image
                image={article?.data?.image}
                alt={article?.data?.image?.alt}
                layout={ImageLayout.cover}
              />
            </div>
          )}

          {article?.data?.page_title && (
            <h6 className="latest-news-article__title" ref={textContainer}>
              <span
                className="latest-news-article__title-inner"
                ref={textInner}
                style={{
                  transform: `translate3d(-${transform}px, 0, 0)`,
                  transitionDuration: `${transitionDuration}ms`,
                }}
              >
                <ButtonArrow className="latest-news-article__arrow" />
                <span className="latest-news-article__title-inner-text">
                  {article?.data?.page_title}
                </span>
              </span>
            </h6>
          )}
        </Link>
      )}
    </li>
  )
}

export default LatestNewsItem
