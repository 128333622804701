import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { Link } from 'gatsby'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import React from 'react'
import { linkResolver } from '../../utils/linkResolver'
import SquareButton from '../buttons/square-button'
import { ImageLayout } from '@superrb/gatsby-addons/types'

const Event = ({ event }) => {
  const isMobile = useIsMobile()

  const colors = {
    dark_blue: 'white',
    pastel_pink: 'navy',
    off_white: 'navy',
    hot_pink: 'navy',
    purple: 'white',
    navy: 'white',
    white: 'navy',
  }

  if (!event) return null

  const hideDate = event.data.event_hide_date || false

  return (
    <article
      className={`blog-article article ${
        event?.data?.featured ? ' featured' : ''
      }`}
    >
      <Link to={linkResolver(event)} className="blog-article__link">
        <div className="article__inner blog-article__inner">
          {event?.data?.featured_image && (
            <Image
              image={event?.data?.featured_image}
              className={`article__image blog-article__image ${
                event?.data?.featured ? ' featured' : ''
              }`}
              alt={event?.data?.featured_image?.alt}
              layout={ImageLayout.cover}
            />
          )}
          {event?.data?.description_homepage?.richText?.length > 0 &&
            !isMobile && (
              <div
                className={`article__description blog-article__description ${
                  event?.data?.featured ? ' featured' : ''
                } ${event?.data?.background_color
                  .replace(' ', '_')
                  .toLowerCase()}`}
              >
                <RichText field={event?.data?.description_homepage.richText} />
              </div>
            )}
          <div
            className={`article__details blog-article__details ${
              event?.data?.featured ? ' featured' : ''
            } ${event?.data?.background_color.replace(' ', '_').toLowerCase()}`}
          >
            <div className="article-top blog__article-top">
              <div className="article__services blog-article__services">
                {event?.data?.date && hideDate && (
                  <span>{event?.data?.date}</span>
                )}
              </div>
              {event?.data?.page_title && <h6>{event.data.page_title}</h6>}
            </div>
            {(!isMobile || event?.data?.featured) && (
              <div className="article__footer blog-article__footer">
                {event?.data?.location && (
                  <address>
                    <p className="article__author-title blog-article__footer-title">
                      Location
                    </p>
                    <p className="article__author-author blog-article__footer-location">
                      {event?.data?.location}
                    </p>
                  </address>
                )}
                <SquareButton
                  link={linkResolver(event)}
                  text={'Read More'}
                  theme={
                    colors[
                      event?.data?.background_color
                        .replace(' ', '_')
                        .toLowerCase()
                    ]
                  }
                />
              </div>
            )}
          </div>
        </div>
      </Link>
    </article>
  )
}

export default Event
