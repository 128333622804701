import React from 'react'

const Close = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0015 5.84288L20.1337 0.975098L12.989 8.1198L5.84434 0.975098L0.976562 5.84288L8.12126 12.9876L0.976562 20.1323L5.84434 25.0001L12.989 17.8554L20.1337 25.0001L25.0015 20.1323L17.8568 12.9876L25.0015 5.84288Z"
      fill="white"
    />
  </svg>
)

export default Close
