import React from 'react'

const Logo = () => {
  return (
    <svg
      width="145"
      height="39"
      viewBox="0 0 145 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.82659 38.0984H0V1.06812H7.1888L11.2447 30.8882C10.6801 24.25 9.70515 11.6997 9.70515 6.14946V1.06812H14.5317V38.0984H7.59923L4.21004 10.351C4.51832 16.2108 4.82659 23.2128 4.82659 27.1555V38.0984Z"
        fill="white"
      />
      <path
        d="M26.9198 38.0984H17.0605V1.06812H26.9198V6.35763H22.6058V16.8335H26.3033V21.5021H22.6058V32.8088H26.9198V38.0984Z"
        fill="white"
      />
      <path
        d="M37.7847 6.35763V38.0984H32.2394V6.35763H28.748V1.06812H41.328V6.35763H37.7847Z"
        fill="white"
      />
      <path
        d="M54.3656 38.0984H49.5391V1.06812H56.7279L60.7856 30.8882C60.2192 24.25 59.2442 11.6997 59.2442 6.14946V1.06812H64.0708V38.0984H57.1383L53.7509 10.351C54.0574 16.2108 54.3656 23.2128 54.3656 27.1555V38.0984Z"
        fill="white"
      />
      <path
        d="M74.9166 33.3791H72.3483L72.1422 38.0984H66.5449L69.6778 1.06812H77.8936L80.9225 38.0984H75.1209L74.9166 33.3791ZM72.5024 28.7032H74.7105L74.2481 18.1224C74.1459 16.6199 73.7857 8.37243 73.5312 4.017C73.2731 8.42493 72.9666 16.7756 72.9146 18.1224L72.5024 28.7032Z"
        fill="white"
      />
      <path
        d="M90.2925 6.35763V38.0984H84.7472V6.35763H81.2559V1.06812H93.8358V6.35763H90.2925Z"
        fill="white"
      />
      <path
        d="M101.553 1.06812H96.0078V38.0984H101.553V1.06812Z"
        fill="white"
      />
      <path
        d="M107.163 38.0982L103.518 1.06797H109.328L110.199 19.5831L110.816 32.6005C111.022 28.4515 111.226 23.1601 111.432 19.5306L112.253 1.06616H117.895L114.096 38.0982H107.163Z"
        fill="white"
      />
      <path
        d="M129.699 38.0984H119.84V1.06812H129.699V6.35763H125.385V16.8335H129.083V21.5021H125.385V32.8088H129.699V38.0984Z"
        fill="white"
      />
      <path
        d="M144.943 30.6291C144.943 36.1775 142.12 38.4131 138.371 38.4131C133.853 38.4131 131.645 35.9241 131.645 30.8427V25.9605H137.19V31.1993C137.19 32.6005 137.6 33.1182 138.319 33.1182C139.244 33.1182 139.394 32.0809 139.394 30.6816V29.2805C139.394 26.6882 138.78 26.2719 135.903 21.8114C133.284 17.8181 131.847 14.4474 131.847 10.769V9.26105C131.847 3.76336 134.157 0.75293 138.478 0.75293C142.176 0.75293 145.001 1.89338 145.001 9.26105V13.6183H139.552V8.79039C139.552 7.07971 139.348 6.04245 138.477 6.04245C137.554 6.04245 137.297 6.87154 137.297 8.79039V9.77697C137.297 12.4742 138.373 14.4438 140.789 18.2308C143.407 22.4305 144.947 24.6101 144.947 28.8098L144.943 30.6291Z"
        fill="white"
      />
    </svg>
  )
}

export default Logo
