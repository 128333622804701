import { RichText } from 'ProjectRoot/src/components/rich-text'
import React, { useEffect, useState } from 'react'
import { A11y, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { linkResolver } from '../../utils/linkResolver'
import RectangleButton from '../buttons/rectangle-button'

const FeaturedWorkSlider = ({ items, setCurrentIndex, currentIndex }) => {
  const [slider, setSliderWork] = useState(null)

  useEffect(() => {
    if (slider) {
      slider.slideTo(currentIndex)
    }
  }, [slider, currentIndex])

  return (
    <>
      <Swiper
        modules={[Pagination, A11y]}
        pagination={
          items.length > 1
            ? {
                clickable: true,
                el: '.featured-work__slider-pagination',
              }
            : false
        }
        slidesPerView={1}
        onInit={swiper => setSliderWork(swiper)}
        onSlideChange={swiper => {
          setCurrentIndex(swiper.realIndex)
        }}
        speed={1000}
        simulateTouch={false}
        allowTouchMove={false}
      >
        {items.map((item, index) => {
          let title
          let description

          if (item?.work?.document?.data?.title) {
            title = item?.work?.document?.data?.title
          } else if (item?.slide_title) {
            title = item?.slide_title
          }

          if (item?.work?.document?.data?.work_description_homepage) {
            description = item?.work?.document?.data?.work_description_homepage
          } else if (item?.slide_text) {
            description = item?.slide_text
          }

          return (
            <SwiperSlide key={index} className="featured-work__slider-slide">
              {title && <h5>{title}</h5>}

              {description && (
                <div className="featured-work__slider-text">
                  <RichText field={description?.richText} />
                </div>
              )}

              {item?.work_button && item?.work_button_text && (
                <div className="texter">
                  <RectangleButton
                    text={item?.work_button_text}
                    link={linkResolver(item?.work_button)}
                    styles={['transparent-navy-faded', 'small']}
                  />
                </div>
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>

      <div className="featured-work__slider-pagination light"></div>
    </>
  )
}

export default FeaturedWorkSlider
