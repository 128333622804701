import React from 'react'
import ArrowTitle from './arrow-title'
import IframeResizer from 'react-iframe-resizer-super'

const ContactForm = ({
  title = 'Get in touch',
  formUrl = 'https://dt6xp7y75wdq4.cloudfront.net/2022/09/nn-forms/main/index.html',
}) => {
  return (
    <section className="contact-form" id="contact-form">
      <ArrowTitle text={title} level={2} className="contact-form__title" />

      <IframeResizer src={formUrl} />
    </section>
  )
}

export default ContactForm
