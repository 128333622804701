import React from 'react'

const PinkTickerArrow = ({ props }) => {
  return (
    <svg
      width="80"
      height="78"
      viewBox="0 0 80 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.0466 77.0304L30.2499 65.7722L51.5471 46.9609H0.717712V30.4298H51.5471L30.2499 11.6186L40.0466 0.360352L79.5175 38.4104V38.9804L40.0466 77.0304Z"
        fill="#FDBEDD"
      />
    </svg>
  )
}

export default PinkTickerArrow
