import { Seo } from '@superrb/gatsby-addons/components'
import { graphql } from 'gatsby'
import React, { useContext } from 'react'
import FeaturedWork from '../components/featured-work'
import Hero from '../components/homepage/hero'
import LatestNews from '../components/homepage/latest-news'
import PromoBlocks from '../components/promo-blocks'
import TickerTape from '../components/ticker-tape'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import Homepage from '../types/pages/homepage'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'

const Index = ({ data }) => {
  const { setHeaderVariant } = useContext(HeaderVariantContext)
  setHeaderVariant('light')

  /** @type {Homepage} page */
  const page = data.page
  if (!page) {
    return null
  }

  const {
    data: { page_title },
  } = page

  const hero = {
    hero_title: page.data.hero_title,
    hero_button: page.data.hero_button,
    hero_description: page.data.hero_description,
    hero_button_text: page.data.hero_button_text,
    hero_image: page.data.hero_image,
    hero_image_mobile: page.data.hero_image_mobile,
    hero_video_preview_url: page.data.hero_video_preview_url,
    hero_video_url: page.data.hero_video_url,
    hero_video_mobile_url: page.data.hero_video_mobile_url,
  }

  const tickerTape = page.data.ticker_tape

  const featuredWork = {
    featured_work_title: page.data.title,
    featured_work_promo_icons: [page.data.promo_icon_1, page.data.promo_icon_2],
    featured_work: page.data.featured_work,
  }

  const promoBlock = {
    promo_block_bullet_points: page.data.promo_block_bullet_points,
    promo_block_akero_logo: page.data.akero_logo,
    promo_block_akero_title: page.data.akero_title,
    promo_block_akero_text: page.data.akero_text,
    promo_block_akero_button_url: page.data.akero_button_url,
    promo_block_akero_button_label: page.data.akero_button_label,
    promo_block_slider_logo: page.data.promo_slider_logo,
    promo_block_slider_title: page.data.promo_slider_title,
    promo_block_slider: page.data.promo_slider,
    image_square: {
      image_square_title: page.data.image_square_title,
      image_square_text: page.data.image_square_text,
      image_square_image: page.data.image_square_image,
      image_square_button_url: page.data.image_square_button_url,
    },
  }

  const newsArticles = {
    articles: page.data.articles,
  }

  return (
    <Layout>
      <Seo data={page.data} />

      {hero && <Hero data={hero} />}
      {tickerTape && <TickerTape text={tickerTape} background={'pale-pink'} />}
      {featuredWork && <FeaturedWork data={featuredWork} showLogos={true} />}
      {promoBlock && <PromoBlocks data={promoBlock} PromoIcons={false} />}
      {newsArticles.articles && (
        <LatestNews
          title="Latest from Net Natives"
          articles={newsArticles.articles}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query HomepageQuery {
    page: prismicHomepage {
      ...Homepage
    }
  }
`

export default withPrismicPreview(Index)
