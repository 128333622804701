import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import React, { useCallback, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import {ImageLayout} from "@superrb/gatsby-addons/types";

const Video = ({ url, backgroundImage }) => {
  const isMobile = useIsMobile()
  const [playing, setPlaying] = useState(false)
  const [muted, setMuted] = useState(false)
  const [hasPlayed, setHasPlayed] = useState(false)
  const [playButtonVisible, setPlayButtonVisible] = useState(false)
  const mouseMoveTimer = useRef()

  const handleClick = useCallback(() => {
    setPlaying(prev => !prev)
  }, [setPlaying])

  const handleMouseMove = useCallback(() => {
    setPlayButtonVisible(true)
    clearTimeout(mouseMoveTimer.current)

    mouseMoveTimer.current = setTimeout(() => {
      setPlayButtonVisible(false)
    }, 2000)
  })

  const handleMute = useCallback(() => {
    setMuted(muted => !muted)
  }, [setMuted])

  if (!url) {
    return null
  }

  return (
    <figure className="video-player">
      <ReactPlayer
        url={url}
        onPlay={() => setHasPlayed(true)}
        muted={muted}
        playing={playing}
        autoPlay={true}
        className="video-player__player"
        controls={false}
        height="auto"
        width="100%"
        config={{
          file: { attributes: { poster: backgroundImage } },
          vimeo: {
            playerOptions: {
              responsive: true,
            },
          },
          youtube: {
            playerOptions: {
              responsive: true,
            },
          },
        }}
      />

      <div className="video-player__overlay">
        <button
          className={`video-player__player-play ${
            !playing || playButtonVisible
              ? 'video-player__player-play--visible'
              : ''
          }`}
          onClick={handleClick}
          onMouseMove={handleMouseMove}
          aria-label={playing ? 'Pause video' : 'Play video'}
        >
          <span className="screenreader-text">
            {playing ? 'Pause video' : 'Play video'}
          </span>
          <span className={playing ? 'video__pause' : 'video__play'}>
            <svg
              width="113"
              height="71"
              viewBox="0 0 113 71"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {playing ? (
                <>
                  <rect
                    x="47.3117"
                    y="25.5807"
                    width="6.62364"
                    height="20.8172"
                    fill="white"
                  />
                  <rect
                    x="58.6666"
                    y="25.5807"
                    width="6.62364"
                    height="20.8172"
                    fill="white"
                  />
                </>
              ) : (
                <></>
              )}

              <rect
                x="1.25556"
                y="2.23419"
                width="110.489"
                height="67.5103"
                rx="12.8866"
                stroke="white"
                strokeWidth="2.51111"
              />
            </svg>
          </span>
        </button>

        <button className="video-player__mute" onClick={handleMute}>
          <span className="screenreader-text">
            {muted ? 'Unmute video' : 'Mute video'}
          </span>

          <span className="video-player__mute-icon">
            <svg
              width="40"
              height="29"
              viewBox="0 0 40 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.05143 23.2028H9.58741L20.6018 28.842C20.8125 28.9475 21.076 29 21.3396 29C21.6032 29 21.9193 28.9475 22.1829 28.7894C22.6571 28.5258 22.9207 27.999 22.9207 27.4193V1.59604C22.9207 1.06888 22.6571 0.542123 22.1829 0.225921C21.7087 -0.037657 21.129 -0.0902261 20.6548 0.173353L9.58767 5.81174H2.05169C1.15583 5.81174 0.470581 6.49696 0.470581 7.39285V21.622C0.470581 22.5178 1.1558 23.2027 2.05169 23.2027L2.05143 23.2028ZM19.7061 24.8368L11.2738 20.5152L11.2742 8.49948L19.7065 4.17785L19.7061 24.8368ZM3.63245 8.97397H8.11218V20.0411H3.63245V8.97397Z"
                fill="white"
              />

              {muted && (
                <>
                  <path
                    d="M28.4009 10.3081L37.9999 19.7703"
                    stroke="white"
                    strokeWidth="3.54153"
                  />
                  <path
                    d="M38 10.3047L28.401 19.7669"
                    stroke="white"
                    strokeWidth="3.54153"
                  />
                </>
              )}
            </svg>
          </span>
        </button>

        {!playing && backgroundImage && (
          <Image image={backgroundImage} alt={backgroundImage.alt} layout={ImageLayout.cover} />
        )}
      </div>
    </figure>
  )
}

export default Video
