import { animate } from '@superrb/gatsby-addons/utils'
import { hex2rgb, hsl2rgb, rgb2hsl } from 'string-color-converter'

var pi = Math.PI
var pi2 = 2 * Math.PI

class Waves {
  constructor(holder, options) {
    this.options = extend(options || {}, {
      resize: true,
      rotation: 45,
      colors: ['#7633F4', '#FF80B1'],
      waves: 1,
      width: 5,
      amplitude: 0.5,
      preload: true,
      speed: [0.0004, 0.0008],
      debug: false,
      fps: false,
    })

    this.waves = []

    this.holder = holder
    // this.holder.style.transform = 'translate3d(0, 0, 0)'
    this.canvas = document.createElement('canvas')
    this.ctx = this.canvas.getContext('2d')
    this.holder.appendChild(this.canvas)
    this.stats = new Stats()
    this.playing = false

    this.resize()
    this.init(this.options.preload)

    if (this.options.resize) {
      window.addEventListener('resize', this.resize.bind(this), false)
    }
  }

  init(preload) {
    var options = this.options

    for (var i = 0; i < options.waves; i++) this.waves[i] = new Wave(this)

    if (preload) this.preload()
  }

  preload() {
    var options = this.options

    for (var i = 0; i < options.waves; i++) {
      this.updateColor()
      for (var j = 0; j < options.width; j++) {
        this.waves[i].update()
      }
    }
  }

  render() {
    var ctx = this.ctx
    var options = this.options

    // this.updateColor()
    this.clear()

    if (this.options.debug) {
      ctx.beginPath()
      ctx.strokeStyle = '#f00'
      ctx.arc(this.centerX, this.centerY, this.radius, 0, pi2)
      ctx.stroke()
    }

    each(this.waves, function (wave, i) {
      wave.update()
      wave.draw()
    })
    if (this.canvas && !this.animationVisible) {
      setTimeout(() => {
        if (this.canvas) {
          this.canvas.style.opacity = 1
          this.animationVisible = true
        }
      }, 500)
    }
  }

  start() {
    this.playing = true
    const { r, g, b } = hex2rgb(this.options.colors[0])
    this.color = `rgba(${r}, ${g}, ${b}, .1)`
    // this.animateColor.call(this, this.options.colors, 10000)
    window.requestAnimationFrame(this.animate.bind(this))
  }

  // async animateColor(colors, duration) {
  //   // return
  //   const { r: r1, g: g1, b: b1 } = hex2rgb(colors[0])
  //   const { r: r2, g: g2, b: b2 } = hex2rgb(colors[1])

  //   const { h: h1, s: s1, l: l1 } = rgb2hsl(r1, g1, b1)
  //   const { h: h2, s: s2, l: l2 } = rgb2hsl(r2, g2, b2)

  //   this.hue = h1
  //   this.sat = s1
  //   this.lit = l1

  //   await Promise.all([
  //     animate(
  //       h1,
  //       h2,
  //       t => {
  //         this.hue = t
  //       },
  //       duration,
  //     ),
  //     animate(
  //       s1,
  //       s2,
  //       t => {
  //         this.sat = t
  //       },
  //       duration,
  //     ),
  //     animate(
  //       l1,
  //       l2,
  //       t => {
  //         this.lit = t
  //       },
  //       duration,
  //     ),
  //   ]).then(() => {
  //     if (this.playing === true) {
  //       this.animateColor.call(this, colors.reverse(), duration)
  //     }
  //   })
  // }

  animate() {
    this.render()

    if (this.options.fps) {
      this.stats.log()
      this.ctx.font = '12px Arial'
      this.ctx.fillStyle = '#fff'
      this.ctx.fillText(this.stats.fps() + ' FPS', 10, 22)
    }

    if (this.playing === true) {
      setTimeout(this.animate.bind(this), 1000 / 10)
      // requestAnimationFrame(this.animate.bind(this))
    }
  }

  destroy() {
    this.playing === false

    this.Lines = []
    this.waves.forEach(wave => wave.destroy())
    this.waves = []

    this.clear()
    if (this.canvas && this.canvas.parentElement === this.holder) {
      this.holder.removeChild(this.canvas)
    }
    delete this.canvas
    delete this.holder
  }

  clear() {
    if (this.ctx !== null) {
      this.ctx.clearRect(0, 0, this.width, this.height)
    }
  }

  resize() {
    if (!this.holder) {
      return
    }

    var width = this.holder.offsetWidth
    var height = this.holder.offsetHeight
    this.scale = 0.75
    this.width = width * this.scale
    this.height = height * this.scale
    this.canvas.width = this.width
    this.canvas.height = this.height
    this.canvas.style.width = width + 'px'
    this.canvas.style.height = height + 'px'
    this.radius =
      Math.sqrt(Math.pow(this.width, 2) + Math.pow(this.height, 2)) / 2
    this.centerX = this.width / 2
    this.centerY = this.height / 2
    // this.radius /= 2 // REMOVE FOR FULLSREEN
  }

  updateColor() {
    const { r, g, b } = hsl2rgb(this.hue, this.sat, this.lit)
    this.color = `rgba(${r}, ${g}, ${b}, .1)`
  }
}

class Wave {
  constructor(Waves) {
    var speed = Waves.options.speed

    this.Waves = Waves
    this.Lines = []

    this.angle = [rnd(pi2), rnd(pi2), rnd(pi2), rnd(pi2)]

    this.speed = [
      rnd(speed[0], speed[1]) * rnd_sign(),
      rnd(speed[0], speed[1]) * rnd_sign(),
      rnd(speed[0], speed[1]) * rnd_sign(),
      rnd(speed[0], speed[1]) * rnd_sign(),
    ]
  }

  update() {
    var Lines = this.Lines
    var color = this.Waves.color

    Lines.push(new Line(this, color))

    if (Lines.length > this.Waves.options.width) {
      Lines.shift()
    }
  }

  draw() {
    var Waves = this.Waves

    var ctx = Waves.ctx
    var radius = Waves.radius
    var radius3 = radius / 3
    var x = Waves.centerX
    var y = Waves.centerY
    var rotation = dtr(Waves.options.rotation)
    var amplitude = Waves.options.amplitude
    var debug = Waves.options.debug

    each(this.Lines, function (line, i) {
      if (debug && i > 0) return

      var angle = line.angle

      if (!line.drawn) {
        line.points = {
          x1: x - radius * Math.cos(angle[0] * amplitude + rotation),
          y1: y - radius * Math.sin(angle[0] * amplitude + rotation),
          x2: x + radius * Math.cos(angle[3] * amplitude + rotation),
          y2: y + radius * Math.sin(angle[3] * amplitude + rotation),
          cpx1: x - radius3 * Math.cos(angle[1] * amplitude * 2),
          cpy1: y - radius3 * Math.sin(angle[1] * amplitude * 2),
          cpx2: x + radius3 * Math.cos(angle[2] * amplitude * 2),
          cpy2: y + radius3 * Math.sin(angle[2] * amplitude * 2),
        }
        line.drawn = true
      }

      const { x1, y1, x2, y2, cpx1, cpy1, cpx2, cpy2 } = line.points

      ctx.strokeStyle = debug ? '#fff' : line.color

      ctx.beginPath()
      ctx.moveTo(x1, y1)
      ctx.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, x2, y2)
      ctx.stroke()

      if (debug) {
        ctx.strokeStyle = '#fff'
        ctx.globalAlpha = 0.3

        ctx.beginPath()
        ctx.moveTo(x1, y1)
        ctx.lineTo(cpx1, cpy1)
        ctx.stroke()
        ctx.beginPath()
        ctx.moveTo(x2, y2)
        ctx.lineTo(cpx2, cpy2)
        ctx.stroke()

        ctx.globalAlpha = 1
      }
    })
  }

  destroy() {
    this.Waves = null
  }
}

class Line {
  drawn = false
  points = {}

  constructor(wave, color) {
    var angle = wave.angle
    var speed = wave.speed

    this.angle = [
      Math.sin((angle[0] += speed[0])),
      Math.sin((angle[1] += speed[1])),
      Math.sin((angle[2] += speed[2])),
      Math.sin((angle[3] += speed[3])),
    ]

    this.color = color
  }
}

class Stats {
  constructor() {
    this.data = []
  }

  time() {
    return (performance || Date).now()
  }

  log() {
    if (!this.last) {
      this.last = this.time()
      return 0
    }

    this.new = this.time()
    this.delta = this.new - this.last
    this.last = this.new

    this.data.push(this.delta)
    if (this.data.length > 10) this.data.shift()
  }

  fps() {
    var fps = 0
    each(this.data, function (data, i) {
      fps += data
    })

    return Math.round(1000 / (fps / this.data.length))
  }
}

function each(items, callback) {
  for (var i = 0; i < items.length; i++) {
    callback(items[i], i)
  }
}

function extend(options, defaults) {
  for (var key in options)
    if (defaults.hasOwnProperty(key)) defaults[key] = options[key]
  return defaults
}

function dtr(deg) {
  return (deg * pi) / 180
}

function rtd(rad) {
  return (rad * 180) / pi
}

function diagonal_angle(w, h) {
  var a = Math.atan2(h, w) * 1.27325
  return a
}

function rnd(a, b) {
  if (arguments.length == 1) return Math.random() * a
  return a + Math.random() * (b - a)
}

function rnd_sign() {
  return Math.random() > 0.5 ? 1 : -1
}

export default Waves
