import React from 'react'
import RectangleButton from '../../components/buttons/rectangle-button'
import EventDetail from '../../types/pages/event-detail'

const Tag = ({ label }) => (
  <span
    className="tag"
    onClick={() => {
      if (window.location.pathname.includes('/blog')) {
        window.location.href = `/blog?tags[]=${label}`
      }
      if (window.location.pathname.includes('/resource')) {
        window.location.href = `/resource?tags[]=${label}`
      }
      if (window.location.pathname.includes('/events')) {
        window.location.href = `/events?tags[]=${label}`
      }
    }}
  >
    {label}
  </span>
)

/**
 *
 * @param {EventDetail} page
 * @returns
 */
const EventDetailHeader = ({ page, hideDate }) => {
  return (
    <section className="blog-detail-header">
      <ul className="blog-detail-header__tags">
        {page.tags.map((tag, index) => (
          <Tag label={tag} key={index} />
        ))}
      </ul>

      <ul className="blog-detail-header__meta">
        {!hideDate && (
          <li className="blog-detail-header__meta-item">
            <span className="blog-detail-header__meta-item-title">Time</span>
            <span className="blog-detail-header__meta-item-value">
              {page.data.start_time}&mdash;{page.data.end_time}
            </span>
          </li>
        )}

        <li className="blog-detail-header__meta-item">
          <span className="blog-detail-header__meta-item-title">Location</span>
          <span className="blog-detail-header__meta-item-value">
            {page.data.location}
          </span>
        </li>
      </ul>

      {page?.data?.ticket_url?.url && (
        <RectangleButton
          className="blog-detail-header__button"
          link={page.data.ticket_url.url}
          text={page?.data?.ticket_button_label || 'Buy Tickets'}
          style="pink"
        />
      )}
    </section>
  )
}
export default EventDetailHeader
