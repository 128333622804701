import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import React, { useEffect, useState } from 'react'
import AnimatedTitle from './animated-title'
import FeaturedLogo from './featured-work/featured-logo'
import FeaturedWorkImage from './featured-work/featured-work-image'
import FeaturedWorkSlider from './featured-work/featured-work-slider'
import PromoIcon from './promo-icon'

const FeaturedWork = ({ data, showLogos }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [image, setImage] = useState(data.featured_work[0])
  const [classes, setClasses] = useState('fowards')
  const isMobile = useIsMobile()

  useEffect(() => {
    setImage(data.featured_work[currentIndex])
  }, [currentIndex, setImage, data, image])

  return (
    <section className="featured-work" id="featured-work">
      <div className="featured-work__slider">
        {data?.featured_work_title && (
          <AnimatedTitle
            level={2}
            text={data?.featured_work_title}
            className="featured-work__title"
          />
        )}

        {!isMobile && showLogos && (
          <FeaturedLogo
            items={data?.featured_work}
            setCurrentIndex={setCurrentIndex}
            setClasses={setClasses}
            currentIndex={currentIndex}
          />
        )}

        {data?.featured_work && (
          <div className="featured-work__slider-container">
            <FeaturedWorkSlider
              items={data?.featured_work}
              setCurrentIndex={setCurrentIndex}
              setClasses={setClasses}
              currentIndex={currentIndex}
            />
          </div>
        )}
      </div>

      {data?.featured_work && (
        <div className="featured-work__slider-image">
          <FeaturedWorkImage
            items={data?.featured_work}
            setCurrentIndex={setCurrentIndex}
            setClasses={setClasses}
            currentIndex={currentIndex}
          />
        </div>
      )}

      {data?.featured_work_promo_icons && (
        <div className="featured-work__promo-icons">
          {data?.featured_work_promo_icons.map((icon, index) => (
            <PromoIcon data={icon?.document?.data} key={index} />
          ))}
        </div>
      )}
    </section>
  )
}

export default FeaturedWork
