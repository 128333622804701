import React from 'react'
import Instagram from '../icons/instagram'
import LinkedIn from './../icons/linked-in'
import Twitter from './../icons/twitter'

const Social = ({ config }) => {
  return (
    <div className="social">
      <ul className="social__list">
        {config.data.instagram_username && (
          <li className="social__list-item">
            <a
              href={`https://instagram.com/${config.data.instagram_username}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social__link"
            >
              <Instagram />
            </a>
          </li>
        )}
        {config.data.linkedin_username && (
          <li className="social__list-item">
            <a
              href={`https://linkedin.com/${config.data.linkedin_username}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social__link"
            >
              <LinkedIn />
            </a>
          </li>
        )}
        {config.data.twitter_username && (
          <li className="social__list-item">
            <a
              href={`https://twitter.com/${config.data.twitter_username}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social__link"
            >
              <Twitter />
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}

export default Social
