import React from 'react'
import LatestNewsBase from '../components/homepage/latest-news'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'

export const LatestNews = ({ slice }: SliceComponentProps<any, any>) => {
    const data = {
        title: slice.primary.latest_news_title,
        articles: slice.items
    }

    return <LatestNewsBase title={data.title} articles={data.articles} />
}

export const query = graphql`
  fragment ContentPageDataBodyLatestNews on PrismicContentPageDataBodyLatestNews {
    primary {
      latest_news_title
    }
    items {
      news_articles {
        document {
          ... on PrismicBlogDetail {
            ...BlogDetail
          }
        }
      }
    }
  }
`
