import React from 'react'
import InstagramBase from '../components/careers/instagram'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'

export const Instagram = ({ slice }: SliceComponentProps<any, any>) => {
    const data = {
        title: slice.primary.instagram_ticker_tape,
    }

    return <InstagramBase tickerTape={data.title} />
}

export const query = graphql`
  fragment ContentPageDataBodyInstagram on PrismicContentPageDataBodyInstagram {
    primary {
      instagram_ticker_tape
    }
  }
`
