import React from 'react'

const Partners = () => {
  return (
    <div className="partners">
      <ul className="partners__list">
        <li className="partners__list-item">
          <img
            src="/images/living-wage.png"
            alt="We are a Living Wage employer"
            className="partners__image"
          />
        </li>
      </ul>
    </div>
  )
}

export default Partners
