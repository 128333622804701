import React, { createContext, useCallback, useState } from 'react'

export const LatestNewsContext = createContext({
  currentImage: 0,
  updateImage: () => {},
})

export const LatestNewsContextProvider = ({ children }) => {
  const [currentImage, setCurrentImage] = useState(0)

  const updateImage = useCallback(
    index => {
      setCurrentImage(index)
    },
    [setCurrentImage],
  )

  return (
    <LatestNewsContext.Provider value={{ currentImage, updateImage }}>
      {children}
    </LatestNewsContext.Provider>
  )
}

export default LatestNewsContextProvider
