import { Link } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import { CookiesContext } from '../context/cookies-context'
import RectangleButton from './buttons/rectangle-button'

export const CookieBanner = () => {
  // Have we already accepted cookies?
  const { cookiesAccepted, setCookiesAccepted } = useContext(CookiesContext)

  const [animate, setAnimate] = useState(false)
  const [showCookieBanner, setShowCookieBanner] = useState(!cookiesAccepted)

  const close = () => {
    setAnimate(true)

    window.setTimeout(() => {
      setShowCookieBanner(false)
    }, 2000)
  }

  const accept = () => {
    setCookiesAccepted(true)
    close()
  }

  const reject = () => {
    close()
  }

  useEffect(() => {
    if (cookiesAccepted) {
      setShowCookieBanner(false)
    }
  }, [cookiesAccepted, setShowCookieBanner])

  return (
    <React.Fragment>
      {showCookieBanner && (
        <div
          className={`cookie-banner animate__animated ${
            animate ? ' animate__fadeOutDown' : ''
          } `}
        >
          <div className="cookie-banner__container">
            <div className="cookie-banner__inner">
              <div className="cookie-banner__message">
                <h2 className="cookie-banner__title">
                  Our website uses cookies
                </h2>
                <p className="cookie-banner__text">
                  Not the delicious kind that go with a cool glass of milk, the
                  other kind.{' '}
                  <Link to="/legal/cookie-policy">Read our cookie policy</Link>.
                </p>
              </div>
              <RectangleButton
                onClick={reject}
                className="cookie-banner__reject"
                text="Reject"
                arrowStyle="none"
                styles={['transparent-white']}
              />
              <RectangleButton
                onClick={accept}
                className="cookie-banner__accept"
                text="Accept"
                arrowStyle="none"
                styles={['white']}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
