import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FilterContext } from '../context/filter-context-provider'

const WorkFilters = ({ tags }) => {
  const [allClass, setAllClass] = useState('selected')
  const isMobile = useIsMobile()

  const {
    selectedTags = [],
    availableTags = [],
    addTag,
    removeTag,
    clearTags,
  } = useContext(FilterContext)

  const handleClick = useCallback(
    (tag, selected, event) => {
      const fn = selected ? removeTag : addTag
      fn(tag)
    },
    [addTag, removeTag],
  )

  useEffect(() => {
    if (selectedTags.includes('ALL') || selectedTags.length == 0) {
      setAllClass('selected')
    } else {
      setAllClass('')
    }
  }, [availableTags, selectedTags])

  return (
    <>
      {isMobile && <span className="work-filter__list-title">Categories:</span>}
      <ul className="work-filter__list">
        {!isMobile && <li className="work-filter__list-title">Categories:</li>}
        <li className={`work-filter__item selected`} key={'ALL'}>
          <button
            className={`work-filter__item-button ${allClass}`}
            onClick={clearTags}
          >
            <span
              className="work-filter__item-button-label selected"
              data-title={'ALL'}
            >
              ALL
            </span>
          </button>
        </li>

        {availableTags.map(tag => {
          const selected = selectedTags.includes(tag)

          return (
            <li
              className={`work-filter__item ${selected ? 'selected' : ''}`}
              key={tag}
            >
              <button
                className={`work-filter__item-button ${
                  selected ? 'selected' : ''
                }`}
                onClick={handleClick.bind(this, tag, selected)}
                key={tag}
              >
                <span
                  className="work-filter__item-button-label"
                  data-title={tag}
                >
                  {tag}
                </span>
              </button>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default WorkFilters
