import React from 'react'

const AwardArrow = ({ ...props }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.82773 13.4871L5.12696 11.5326L8.82429 8.2669H0V5.397H8.82429L5.12696 2.13126L6.82773 0.176758L13.6801 6.78247V6.88143L6.82773 13.4871Z"
        fill="#06081C"
      />
    </svg>
  )
}

export default AwardArrow
