import React from 'react'

const MapPin = ({ style }) => (
  <svg
    width="52"
    height="73"
    viewBox="0 0 52 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M25.55 0C11.4365 0 0 11.4368 0 25.55C0 31.0653 1.78444 37.3925 4.78541 41.61L25.5499 73L46.3143 41.61C49.3153 37.3921 51.0997 31.147 51.0997 25.55C51.0997 11.4365 39.6635 0 25.5497 0H25.55ZM25.55 35.2833C20.1965 35.2833 15.8168 30.9036 15.8168 25.5501C15.8168 20.1967 20.1965 15.817 25.55 15.817C30.9035 15.817 35.2832 20.1967 35.2832 25.5501C35.2832 30.903 30.9035 35.2833 25.55 35.2833Z"
      fill="#06081C"
    />
  </svg>
)

export default MapPin
