import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { Link } from 'gatsby'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import React, { useEffect, useState } from 'react'
import SquareButton from '../../components/buttons/square-button'
import { linkResolver } from '../../utils/linkResolver'
import { ImageLayout } from '@superrb/gatsby-addons/types'

const Article = ({ article }) => {
  const isMobile = useIsMobile()
  const [tags, setTags] = useState([])

  useEffect(() => {
    if (article && article.tags.length >= 3) {
      setTags(article.tags.slice(0, 3))
    } else if (article) {
      setTags(article.tags)
    }
  }, [article])

  const colors = {
    dark_blue: 'white',
    pastel_pink: 'navy',
    off_white: 'navy',
    hot_pink: 'navy',
    purple: 'white',
    navy: 'white',
    white: 'navy',
  }

  if (!article) return null

  return (
    <article
      className={`blog-article article ${
        article?.data?.featured ? ' featured' : ''
      }`}
    >
      <Link to={linkResolver(article)} className="blog-article__link">
        <div className="article__inner blog-article__inner">
          {article?.data?.featured_image && (
            <Image
              image={article?.data?.featured_image}
              className={`article__image blog-article__image ${
                article?.data?.featured ? ' featured' : ''
              }`}
              alt={article?.data?.featured_image?.alt}
              layout={ImageLayout.cover}
            />
          )}
          {article?.data?.description_homepage?.richText?.length > 0 &&
            !isMobile && (
              <div
                className={`article__description blog-article__description ${
                  article?.data?.featured ? ' featured' : ''
                } ${article?.data?.background_color
                  .replace(' ', '_')
                  .toLowerCase()}`}
              >
                <RichText
                  field={article?.data?.description_homepage?.richText}
                />
              </div>
            )}
          <div
            className={`article__details blog-article__details ${
              article?.data?.featured ? ' featured' : ''
            } ${article?.data?.background_color
              .replace(' ', '_')
              .toLowerCase()}`}
          >
            <div className="article-top blog__article-top">
              <div className="article__services blog-article__services">
                {article?.data?.date && <span>{article?.data.date}</span>}
              </div>
              {article?.data?.page_title && <h6>{article.data.page_title}</h6>}
            </div>
            {(!isMobile || article?.data?.featured) && (
              <div
                className={`article__footer blog-article__footer ${
                  article?.data?.featured ? ' featured' : ''
                }`}
              >
                <div className="article__author blog-article__author">
                  {article?.data?.author && (
                    <address rel="author">
                      <p className="blog-article__author-title article__author-title">
                        Author
                      </p>
                      <p className="blog-article__author-author article__author-author">
                        {article?.data?.author}
                      </p>
                    </address>
                  )}
                </div>
                <SquareButton
                  text={'Read More'}
                  theme={
                    colors[
                      article?.data?.background_color
                        .replace(' ', '_')
                        .toLowerCase()
                    ]
                  }
                />
              </div>
            )}
          </div>
        </div>
      </Link>
    </article>
  )
}

export default Article
