import React from 'react'
import LatestNewsContextProvider from '../../context/latest-news-context-provider'
import LatestNewsItem from './latest-news-item'

const LatestNews = ({ title, articles }) => {
  return (
    <LatestNewsContextProvider>
      <section className="latest-news">
        <div className="latest-news__container">
          <div className="latest-news__container-inner">
            <div className="latest-news__content">
              {title && <h2 className="latest-news__title">{title}</h2>}
              <ul className="latest-news__article-list">
                {articles.map((article, index) => {
                  return (
                    <LatestNewsItem
                      index={index}
                      article={article?.news_articles?.document}
                      key={index}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </LatestNewsContextProvider>
  )
}

export default LatestNews
