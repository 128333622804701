import { Link } from 'gatsby'
import React from 'react'
import { linkResolver } from '../../utils/linkResolver'
import BlackArrowTitle from './../black-arrow-title'
import Article from './../blogs-resources-events/article'
import PinkArrowTitle from './pink-arrow-title'

const LatestArticles = ({ articles, linkTitle, showTitle = true }) => {
  return (
    <div className="blog__articles our-work__articles latest-articles">
      {showTitle && (
        <div className="our-work__articles-title">
          <BlackArrowTitle
            text="More Articles"
            level={2}
            alignment="left"
            backgroundColor="black"
          />
        </div>
      )}
      {articles?.map((article, index) => (
        <Article key={`article--${index}`} article={article} />
      ))}
      <Link
        to={linkResolver({ type: 'blogs' })}
        className="blog__articles-link second "
      >
        {linkTitle && (
          <PinkArrowTitle
            text={linkTitle.toUpperCase()}
            level={5}
            alignment="right"
            className="link"
            backgroundColor="black"
          />
        )}
      </Link>
    </div>
  )
}

export default LatestArticles
