import { Image } from '@superrb/gatsby-addons/components'
import React, { useEffect, useState } from 'react'
import { A11y, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ImageLayout } from "@superrb/gatsby-addons/types";

const FeaturedWorkSlider = ({ items, currentIndex, setCurrentIndex }) => {
  const [slider, setSlider] = useState(null)

  useEffect(() => {
    if (slider) {
      slider.slideTo(currentIndex)
    }
  }, [slider, currentIndex])

  return (
    <div className="featured-work__image">
      <Swiper
        modules={[Pagination, A11y]}
        slidesPerView={1}
        onInit={swiper => setSlider(swiper)}
        allowTouchMove={false}
        onSlideChange={swiper => {
          setCurrentIndex(swiper.realIndex)
        }}
        speed={1000}
      >
        {items.map((item, index) => {
          let image

          if (item?.work_image) {
            image = item?.work_image
          } else if (item?.slide_text) {
            image = item?.slide_image
          }

          return (
            <SwiperSlide
              key={`work--${index}`}
              className="image-slider__slide objFit"
            >
              {image && <Image image={image} alt="1" className="objFit" layout={ImageLayout.cover} />}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default FeaturedWorkSlider
