import React from 'react'
import PromoBlockBase from '../components/promo-blocks'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Link } from '@superrb/gatsby-addons/types'

export const PromoBlock = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.causes_we_support_title,
    promo_block_promo_icons: slice.items.map(
      (item: {
        promo_icon_1: Link
        promo_icon_2: Link
        promo_icon_3: Link
      }) => ({
        promo_icon_1: item.promo_icon_1,
        promo_icon_2: item.promo_icon_2,
        promo_icon_3: item.promo_icon_3,
      }),
    ),
    image_1: slice.items[0].promo_image_1,
    image_2: slice.items[0].promo_image_2,
    service_title_1: slice.items[0].promo_title_1,
    service_title_2: slice.items[0].promo_title_2,
    service_description_1: slice.items[0].promo_description_1,
    service_description_2: slice.items[0].promo_description_2,
    service_button_text_1: slice.items[0].promo_button_text_1,
    service_button_link_1: slice.items[0].promo_button_link_1,
    service_button_text_2: slice.items[0].promo_button_text_2,
    service_button_link_2: slice.items[0].promo_button_link_2,
    service_background_color_1: slice.items[0].promo_background_color_1,
    service_background_color_2: slice.items[0].promo_background_color_2,
  }
  return <PromoBlockBase data={data} title={data.title} />
}

export const query = graphql`
  fragment ContentPageDataBodyPromoBlock on PrismicContentPageDataBodyPromoBlock {
    primary {
      causes_we_support_title
    }
    items {
      promo_icon_1 {
        ...Link
      }
      promo_icon_2 {
        ...Link
      }
      promo_icon_3 {
        ...Link
      }
      promo_title_1
      promo_description_1 {
        ...RichText
      }
      promo_title_2
      promo_description_2 {
        ...RichText
      }
      promo_image_1 {
        ...Image
      }
      promo_image_2 {
        ...Image
      }
      promo_button_text_1
      promo_button_link_1 {
        ...Link
      }
      promo_button_text_2
      promo_button_link_2 {
        ...Link
      }
      promo_background_color_1
      promo_background_color_2
    }
  }
`
