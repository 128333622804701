import {
  useIsInViewport,
  useIsMobile,
  useMotionAllowed,
} from '@superrb/gatsby-addons/hooks'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import brandColors from '../utils/colors'
import Waves from './waves'

type BackgroundStyle = 'blue' | 'pink' | 'purple' | 'light-purple'

const animationColors = {
  blue: [brandColors.purple, brandColors.hotPink],
  pink: [brandColors.hotPink, brandColors.pastelPink],
  purple: [brandColors.hotPink, brandColors.hotPink],
  'light-purple': [brandColors.navy, brandColors.pastelPurple],
}

interface Props {
  backgroundStyle: BackgroundStyle
  colors: string[]
}

const Animation = ({
  backgroundStyle = 'blue',
  colors = animationColors[backgroundStyle],
}: Props) => {
  const element = useRef(null)
  const waves = useRef(null)
  const [style, setStyle] = useState({
    opacity: 1,
  })

  const { isInViewport, setRef } = useIsInViewport(false, '200% 100%')
  const isMotionAllowed = useMotionAllowed()
  const [previousIsInViewport, setPreviousIsInViewport] = useState(
    !isInViewport,
  )

  useLayoutEffect(() => {
    if (!element.current) {
      return
    }

    if (previousIsInViewport !== isInViewport) {
      if (isInViewport && isMotionAllowed) {
        waves.current = new Waves(element.current, {
          colors: colors,
          resize: false,
          speed: [0.008, 0.016],
          waves: 3,
          width: 100,
        })

        waves.current.start()
        setStyle({
          opacity: 1,
        })
      } else {
        if (waves.current) {
          waves.current.destroy()
        }

        setStyle({
          opacity: 0,
        })
      }
    }

    setPreviousIsInViewport(isInViewport)

    return () => {
      if (element.current && waves.current) {
        waves.current.destroy()
      }
    }
  }, [colors, element, isInViewport])

  return (
    <div
      className={`hero__animation hero__animation--${backgroundStyle}`}
      style={{
        ...style,
      }}
      ref={ref => {
        setRef(ref)
        element.current = ref
      }}
    ></div>
  )
}

export default Animation
