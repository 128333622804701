import React from 'react'
import AwardsBase from '../components/awards'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import Award from '../types/award'

export const Awards = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.title,
    awards: slice.items,
  }

  return <AwardsBase data={data} />
}

export const query = graphql`
  fragment ContentPageDataBodyAwards on PrismicContentPageDataBodyAwards {
    primary {
      title
    }
    items {
      award {
        document {
          ... on PrismicAward {
            ...Award
          }
        }
      }
    }
  }
`
