import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { RichText } from '../components/rich-text'
import React from 'react'
import { A11y, Pagination } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import BlackArrowTitle from './black-arrow-title'

const BenefitsSlider = ({ data, titleAlignment }) => {
  const isMobile = useIsMobile()

  return (
    <section className="benefits-slider">
      {data?.title && (
        <BlackArrowTitle
          level={2}
          text={data?.title}
          alignment={titleAlignment}
        />
      )}

      {data?.items && (
        <div className="benefits-slider__container container container--flex">
          <Swiper
            modules={[Pagination, A11y]}
            pagination={{ clickable: true, el: '.benefits-slider__pagination' }}
            slidesPerView={isMobile ? 1.15 : 3.15}
            speed={1000}
          >
            {data?.items.map((item, index) => {
              const title = item?.story_year ? item?.story_year : item?.title
              const description = item?.story_description
                ? item?.story_description
                : item?.description
              const image = item?.story_image ? item?.story_image : item?.image
              const background_color = item?.story_background_color
                ? item?.story_background_color
                : item?.background_color

              return (
                <SwiperSlide
                  key={index}
                  className={`benefits-slider__item benefit slide ${
                    background_color
                      ? background_color.replace(' ', '_').toLowerCase()
                      : ''
                  }`}
                >
                  {image && <Image image={image} className="benefit__image" />}

                  <div className="benefit__details">
                    {title && (
                      <h6 className="benefit__details-title">{title}</h6>
                    )}
                    {description && (
                      <RichText
                        field={description.richText}
                        className="benefit__details-description"
                      />
                    )}
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <div className="benefits-slider__pagination"></div>
        </div>
      )}
    </section>
  )
}

export default BenefitsSlider
