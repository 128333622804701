import { MenuToggle } from '@superrb/gatsby-addons/components'
import { NavContext } from '@superrb/gatsby-addons/context'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { SiteConfig } from '@superrb/gatsby-addons/types'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { HeaderVariantContext } from '../context/header-variant-context-provider'
import { NewsletterContext } from '../context/newsletter-context-provider'
import MainHeader from '../types/main-header'
import { linkResolver } from '../utils/linkResolver'
import RectangleButton from './buttons/rectangle-button'
import HeaderFooter from './header/header-footer'
import HeaderSocials from './header/header-socials'
import ButtonArrow from './icons/button-arrow'
import Menu from './icons/menu'
import WhiteArrow from './icons/white-arrow'
import Logo from './logo'

const Header = () => {
  const staticData = useStaticQuery(graphql`
    query MainHeaderQuery {
      header: prismicMainHeader {
        ...MainHeader
      }
      config: prismicSiteConfig {
        ...SiteConfig
      }
    }
  `)

  const data = useMergePrismicPreviewData(staticData)
  const [stuckPosition, setStuckPosition] = useState(50)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (document.body.classList.contains('page--home')) {
      if (window.innerWidth > 1600) {
        setStuckPosition(isMobile ? window.innerHeight / 9 : 200)
      } else {
        setStuckPosition(isMobile ? window.innerHeight / 5.75 : 200)
      }
    } else {
      setStuckPosition(50)
    }
  }, [setStuckPosition, isMobile])

  const { navOpen, closeNav } = useContext(NavContext)
  const [stuck, setStuck] = useState(false)
  const [visible, setVisible] = useState(true)
  const [allowTransitions, setAllowTransitions] = useState(false)
  const headerElement = useRef(null)
  let lastScroll = 0
  const { newsletterPopupOpen, closeNewsletterPopup, toggleNewsletterPopup } =
    useContext(NewsletterContext)

  const handleClick = useCallback(
    event => {
      event.target.blur()
      closeNav()

      return true
    },
    [closeNav],
  )

  const handleScroll = useCallback(() => {
    requestAnimationFrame(() => {
      if (window.scrollY > stuckPosition) {
        setVisible(
          (!isMobile && window.scrollY > stuckPosition + 50) ||
            (isMobile && window.scrollY < lastScroll),
        )
        setStuck(true)
        document.body.classList.add('header-stuck')

        setTimeout(() => {
          setAllowTransitions(true)
        }, 10)
      } else {
        document.body.classList.remove('header-stuck')
        setStuck(false)
        setAllowTransitions(false)
        setVisible(true)
      }

      lastScroll = window.scrollY
    })
  }, [isMobile, headerElement])

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll, { passive: false })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, data])

  const { variant } = useContext(HeaderVariantContext)

  /** @type {MainHeader} header */
  const header = data?.header
  if (!header) {
    return null
  }
  /** @type {SiteConfig} config */
  const config = data?.config
  if (!config) {
    return null
  }

  return (
    <header
      className={`main-header main-header--${variant} ${
        navOpen ? 'nav-open' : ''
      } ${stuck ? 'header-stuck' : ''} ${
        visible ? 'main-header--visible' : ''
      } ${allowTransitions ? 'main-header--allow-transitions' : ''}`}
      ref={headerElement}
    >
      <div className="main-header__container">
        <Link to="/" className="main-header__logo">
          <Logo />
        </Link>
        <div className="main-header__buttons">
          {header.data.left_button_link &&
            header.data.left_button_text &&
            !isMobile && (
              <span
                className="main-header__buttons-link"
                onClick={toggleNewsletterPopup}
              >
                <span className="dot"></span>
                {header.data.left_button_text}
              </span>
            )}
          {header.data.middle_button_link &&
            header.data.middle_button_text &&
            !isMobile && (
              <Link
                to={linkResolver(header.data.middle_button_link)}
                className="main-header__buttons-link"
              >
                <span className="dot"></span>
                {header.data.middle_button_text}
              </Link>
            )}
          {header.data.right_button_link &&
            header.data.right_button_text &&
            !isMobile && (
              <>
                <RectangleButton
                  text={header.data.right_button_text}
                  link={linkResolver(header.data.right_button_link)}
                  style={`transparent-${
                    variant === 'light' ? 'white' : 'black'
                  }-faded`}
                />
              </>
            )}
          <MenuToggle
            className="main-header__toggle header-toggle"
            renderIcon={navOpen => {
              return <>{navOpen ? '×' : <Menu />}</>
            }}
          />
        </div>
      </div>

      <nav
        className="main-header__nav nav"
        id="main-nav"
        aria-hidden={!navOpen}
      >
        <MenuToggle className="main-header__toggle" />

        {isMobile && (
          <div className="main-header__nav-padding">
            <Link
              to="/"
              className="main-header__nav-logo"
              onClick={handleClick}
            >
              <Logo />
            </Link>
            <div className="main-header__nav-title animate-first">
              <ButtonArrow className="main-header__nav-title-arrow" />
              <h5>Menu</h5>
            </div>
          </div>
        )}
        <div className="nav__list nav__list-first main-header__nav-padding animate-first">
          {!isMobile && (
            <div className="main-header__nav-padding main-header__top">
              <Link
                to="/"
                className="main-header__nav-logo"
                onClick={handleClick}
              >
                <Logo />
              </Link>
            </div>
          )}
          <ul className="main-header-nav__links nav__links">
            {header.data.navigation_items.map((link, index) => (
              <li key={index} className="nav__hover">
                <Link
                  to={linkResolver(link.link)}
                  className="nav__link"
                  onClick={handleClick}
                >
                  <span className="nav__item">{link.label}</span>
                  <span className="nav__item-secondary" aria-hidden={true}>
                    {link.label}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
          <div className="main-header__footer-desktop">
            {config && !isMobile && <HeaderSocials config={config} />}
            {header.data.menu_logos && !isMobile && (
              <HeaderFooter logos={header?.data?.menu_logos} />
            )}
          </div>
        </div>

        <ul className="nav__list nav__list-featured ">
          {header.data.featured_page_1 && header.data.featured_page_1_title && (
            <li className="nav__item nav__item-featured first animate-second">
              <Link
                to={linkResolver(header.data.featured_page_1)}
                className="nav__link nav__item-featured-link"
                onClick={handleClick}
              >
                <div className="nav__item-featured-title">
                  <WhiteArrow className="nav__item-featured-arrow" />
                  {header.data.featured_page_1_title}
                </div>
                {header.data.featured_page_1_description && !isMobile && (
                  <div className="nav__item-featured-text">
                    <RichText
                      field={header.data.featured_page_1_description.richText}
                    />
                  </div>
                )}
              </Link>
            </li>
          )}
          {header.data.featured_page_2 && header.data.featured_page_2_title && (
            <li className="nav__item nav__item-featured second animate-third">
              <Link
                to={linkResolver(header.data.featured_page_2)}
                className="nav__link nav__item-featured-link"
                onClick={handleClick}
              >
                <div className="nav__item-featured-title">
                  <WhiteArrow className="nav__item-featured-arrow" />
                  {header.data.featured_page_2_title}
                </div>
                {header.data.featured_page_2_description && !isMobile && (
                  <div className="nav__item-featured-text">
                    <RichText
                      field={header.data.featured_page_2_description.richText}
                    />
                  </div>
                )}
              </Link>
            </li>
          )}
          {header.data.featured_page_3 && header.data.featured_page_3_title && (
            <li className="nav__item nav__item-featured third animate-fourth">
              <Link
                to={linkResolver(header.data.featured_page_3)}
                className="nav__link third nav__item-featured-link"
                onClick={handleClick}
              >
                <div className="nav__item-featured-title">
                  <WhiteArrow className="nav__item-featured-arrow" />
                  {header.data.featured_page_3_title}
                </div>
                {header.data.featured_page_3_description && !isMobile && (
                  <div className="nav__item-featured-text">
                    <RichText
                      field={header.data.featured_page_3_description.richText}
                    />
                  </div>
                )}
              </Link>
            </li>
          )}
        </ul>
        {config && isMobile && <HeaderSocials config={config} />}
        {header.data.menu_logos && isMobile && (
          <HeaderFooter logos={header?.data?.menu_logos} />
        )}
      </nav>
    </header>
  )
}

export default Header
