import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import PinkArrowTitle from './pink-arrow-title'
import BlackArrowTitle from './../black-arrow-title'
import Resource from './resource'
import { linkResolver } from '../../utils/linkResolver'

const LatestResources = ({
  resources,
  linkTitle,
  reverse,
  showTitle = true,
}) => {
  useEffect(() => {
    if (
      resources.length > 1 &&
      !resources?.some(resource => resource?.data?.featured === true)
    ) {
      resources.map((article, index) => {
        if (article.data) {
          article.data.featured = false
          if (index == 2) {
            article.data.featured = true
          }
          return article
        }
      })
    }
  }, [resources])

  return (
    <div className="blog__articles our-work__articles latest-articles latest-resources">
      {showTitle && (
        <div className="our-work__articles-title">
          <BlackArrowTitle
            text="More projects"
            level={2}
            alignment="left"
            backgroundColor="black"
          />
        </div>
      )}

      {reverse == true &&
        resources
          ?.map((resource, index) => (
            <Resource key={`resource--${index}`} resource={resource} />
          ))
          .reverse()}
      {reverse == false &&
        resources?.map((resource, index) => (
          <Resource key={`resource--${index}`} resource={resource} />
        ))}
      {linkTitle && (
        <Link
          to={linkResolver({ type: 'resources' })}
          className="blog__articles-link second "
        >
          <PinkArrowTitle
            text={linkTitle.toUpperCase()}
            level={5}
            alignment="right"
            className="link"
            backgroundColor="purple"
          />
        </Link>
      )}
    </div>
  )
}

export default LatestResources
