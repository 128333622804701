import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { RichText } from 'ProjectRoot/src/components/rich-text'
import React from 'react'
import ArrowTitle from '../../components/arrow-title'
import { linkResolver } from '../../utils/linkResolver'
import RectangleButton from '../buttons/rectangle-button'

const Cta = ({ data, theme }) => {
  const isMobile = useIsMobile()

  return (
    <section className={`cta${theme ? ' cta--' + theme : ''}`}>
      <div className="cta__inner">
        <ArrowTitle
          level={isMobile ? 3 : 1}
          text={data?.cta_title}
          className="cta__title"
        />

        <div className="cta__footer">
          <div className="cta__footer--text">
            <RichText field={data?.cta_description?.richText} />
          </div>

          <div className="cta__footer-buttons">
            <RectangleButton
              text={data?.cta_link_text}
              link={linkResolver(data?.cta_link)}
              styles={[
                theme == 'light-alt'
                  ? 'transparent-black-faded'
                  : 'transparent-white-faded',
                'small',
              ]}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cta
