import React, { createContext, useState } from 'react'

type Variant = 'light' | 'dark' | 'light-dark-split' | 'homepage'

export const HeaderVariantContext = createContext({
  variant: 'light',
  setHeaderVariant: (variant: Variant) => {},
})

export const HeaderVariantContextProvider = ({ children }) => {
  const [variant, setHeaderVariant] = useState<Variant>('light')

  return (
    <HeaderVariantContext.Provider
      value={{
        variant,
        setHeaderVariant,
      }}
    >
      {children}
    </HeaderVariantContext.Provider>
  )
}

export default HeaderVariantContextProvider
